<template>
  <v-container>
    <v-btn
      color="primary"
      variant="outlined"
      :to="{ name: 'AdminUserCreate' }"
      router
    >
      Create User
    </v-btn>

    <v-divider class="my-4"></v-divider>

    <!-- Data Table -->
    <v-data-table
      :items="users"
      :headers="headers"
      item-value="id"
      class="elevation-1"
      @update:options="handleLimitChange"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :to="{ name: 'AdminEditDetail', params: { id: item.id } }"
          router
          size="small"
          color="primary"
        >
          Edit
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getUsers } from '@/controllers';

const users = ref([]);
const count = ref(0);
const limit = ref(10); // Initial limit
const currentPage = ref(1); // Initial page
const search = ref("");

const headers = [
  { title: 'First Name', key: 'first_name' },
  { title: 'Last Name', key: 'last_name' },
  { title: 'Email', key: 'email_address' },
  { title: 'Role', key: 'role.role_name', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false }
];

const fetchUsers = async () => {
  try {
    const userData = await getUsers({ limit: limit.value, page: currentPage.value, search: search.value });
    users.value = userData.data;
    count.value = userData.count;
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

onMounted(() => {
  fetchUsers();
});

const handleLimitChange = (value) => {
  limit.value = value.itemsPerPage;
  currentPage.value = value.page;
  fetchUsers(); // Fetch new data when limit changes
};

</script>

<style scoped>
</style>
