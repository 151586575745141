// src/services/axiosInterceptor.js

import axios from 'axios';
import { refreshToken,  validateToken} from '@/controllers';
import { logout } from '@/controllers';
import { useAuthStore } from '@/store';
const instance = axios.create();

instance.defaults.withCredentials = false;
instance.defaults.baseURL = process.env.VUE_APP_TEAMS_API_URL;


instance.interceptors.request.use(
  async config => {
    const userStore = useAuthStore();
    if(userStore.user != null && await !validateToken()) {

      await refreshToken();
    }
    
    if (userStore && userStore.token) {
      config.headers['Authorization'] = makeHeader(userStore.token);
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {

    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error && error.response && typeof error.response.status !== 'undefined' &&
        error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken();
        const user = useAuthStore();

        axios.defaults.headers.common['Authorization'] =  makeHeader(user.token);
        originalRequest.headers['Authorization'] = makeHeader(user.token);
        return axios(originalRequest);
      } catch (e) {
        
        return Promise.reject(e);
      }
    }
    if (error && error.response && error.response.status != 500) {
      logout();
    }
    return Promise.reject(error);
  }
);

const makeHeader = (token) => {
  return `Bearer ${token}`;
};
export default instance;
