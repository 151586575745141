import { useAuthStore } from '@/store';
import { router } from "@/router";
import { loginAPI, registerAPI, refreshTokenAPI, validateTokenAPI, getCurrentYearAPI } from "@/services";
import { getMe } from '@/controllers';
import jwt from 'jsonwebtoken';
import { adminUserRoutes } from '@/router/admin/adminUserRoutes.js';
import { adminYearRoutes } from '@/router/admin/adminYearRoutes.js';
import { adminTeamRoutes } from '@/router/admin/adminTeamRoutes.js';
import { adminHomeRoutes } from '@/router/admin/adminHomeRoutes';
export async function login(username, password) {

    const loginParams = {
        email: username,
        password: password,
    };

    try {
        const user = await loginAPI(loginParams);
        if (user && user.token) {
            const auth = useAuthStore();
            const decUser = jwt.decode(user.token);
            auth.setUserToken(user.token);
            auth.setRefreshToken(user.refreshToken);
            auth.setUser(true);
            auth.setRole(decUser.role);
            auth.setData(JSON.stringify(user));
            if (auth.isAdmin()) {
                adminUserRoutes.forEach(route => router.addRoute(route));
                adminYearRoutes.forEach(route => router.addRoute(route));
                adminHomeRoutes.forEach(route => router.addRoute(route));
                adminTeamRoutes.forEach(route => router.addRoute(route));
            }
            await getCurrentYear();
            await getMe();
            
            if (auth.isAdmin()) {
                router.push({ name: "Admin" });
            } else {
              router.push({ name: "Home" });
            }
        } else {
            throw ("There was an error logging in. Please verify that you entered in the correct username and password.")
        }
    } catch (error) {
        console.log(error);
        throw ("There was an error logging in. Please verify that you entered in the correct username and password.")
    }
}

export async function register(username, password) {

    const registerParams = {
        email: username,
        password: password
    };

    try {
        await registerAPI(registerParams);
        router.push({ name: "Login" });
    } catch (error) {
        throw (error.message);

    }
}
export async function refreshToken() {

    try {

        await refreshTokenAPI();

    } catch (error) {
        console.log(error);
        throw (error.message);
    }
}
export async function logout() {

    try {
        const auth = useAuthStore();
        auth.logout();
        router.push({ name: "Login" });
    } catch (error) {
        console.log(error);
        throw (error.message);
    }
}

export async function validateToken() {
    let isValid = false;
    try {
       isValid = await validateTokenAPI();
    } catch (error) {
        console.log(error);       
    }
    return isValid;
}

export async function getCurrentYear() {
    try {
        const auth = useAuthStore();
        const year = await getCurrentYearAPI();
        if (year) {
            auth.setCurrentYear(year.id);
            let launch = false
            let turnStart = false;
            
            const today = new Date();
            if (year.launch) {                        
                  const launchDate = new Date(year.launch_date);                  
                  today.setHours(0, 0, 0, 0); // Zero out the time part
                  launchDate.setHours(0, 0, 0, 0);
                  launch = launchDate <= today;
            }

            if (year.turn_start) {
                const turninDate = new Date(year.turn_start);                  
                turnStart = turninDate <=today;
            }

            

            auth.setLaunch(launch);
            auth.setTurnStart(turnStart);
            auth.setDueDate(year.turn_deadline);
        }
    } catch (error) {
        console.log(error);
        throw (error.message);
    }


}