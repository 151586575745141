<script setup>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';

import { login } from '@/controllers';
let apiError = false;
const schema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required')
});



function onSubmit(values, { setErrors }) {

    const { username, password } = values;

    return login(username, password)
        .catch(error => {
            setErrors({ apiError: error });
            apiError = true;
        });
}
</script>
<template>
    <v-container>
        <v-card>
            <v-card-title>
                <h2>Login</h2>
                Your username is email address you used to sign up or paid with PayPal.
            </v-card-title>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting}">
                <v-card-text>
                    <v-alert v-if="apiError" type="error">{{ errors.apiError }}</v-alert>
                  <div class="form-group">
                        <label>Username</label>
                        <Field name="username" type="text" class="form-control"
                            :class="{ 'is-invalid': errors.username }" />
                        <div class="invalid-feedback">{{ errors.username }}</div>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <Field name="password" type="password" class="form-control"
                            :class="{ 'is-invalid': errors.password }" />
                        <div class="invalid-feedback">{{ errors.password }}</div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" outlined :disabled="isSubmitting" type="submit">
                        Login
                    </v-btn>
                    
                </v-card-actions>
            </Form>
        </v-card>

    </v-container>
</template>


<style scoped></style>