import YearListingView from "@/views/admin/years/YearsListingView.vue";
import YearCreateView from "@/views/admin/years/YearCreateView.vue";
import YearEditView from "@/views/admin/years/YearEditView.vue";
import YearTeamDetailView from "@/views/admin/years/YearTeamDetailView.vue";

export const adminYearRoutes = [
    {
      path: "/admin/years",
      name: "AdminYearListing",
      component: YearListingView,
      meta: {guest: false}
    },
    {
      path: "/admin/year/:id/edit",
      name: "AdminYearEdit",
      component: YearEditView,
      meta: {guest: false}
    },
    {
      path: "/admin/year/:yearId/team/:teamId/:tabName?",
      name: "AdminYearTeamDetailView",
      component: YearTeamDetailView,
      meta: {guest: false}
    },
    {
      path: "/admin/years/create",
      name: "AdminYearCreate",
      component: YearCreateView,
      meta: {guest: false}
    },
   
      
     ];