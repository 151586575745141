<template>
    <v-card  border="opacity-50 sm"
      class="mx-auto"
      max-width="360"
      rounded="xl"
      variant="text">
        <v-card-text class="text-center">
            <div :style="{ color: props.numberColor }" class="big-number">{{ props.number }}</div>
            <div v-if="!isMobile" class="caption">{{ props.caption }}</div>
            <div v-else class="caption">{{ props.caption_sm }}</div>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useDisplay }from 'vuetify';
const { smAndDown } = useDisplay();
let isMobile = computed(() => smAndDown.value);
// Define the props
const props = defineProps({
    number: {
        type: [Number, String],
        required: true,
    },
    caption: {
        type: String,
        required: true,
    },
    caption_sm: {
        type: String,
        required: true
    },
    numberColor: {
        type: String,
        default: '#1976D2', // Default color (primary color)
    },
});
</script>

<style scoped>
.big-number {
    font-size: 3rem;
    /* Adjust the size as needed */
    font-weight: bold;
}

.caption {
    font-size: 1rem;
    color: #666;
}
</style>