import { post, remove, webSocketUpload } from '@/services/apiWrapper';

export const uploadTermsAPI = async(teamId, yearId, file) => {
    
    const api = post(`/team/${teamId}/terms/${yearId}/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    return api;
}

export const uploadScreenShotAPI = async(teamId, yearId, submissionId, file) => {
    
  const api = post(`/team/${teamId}/year/${yearId}/submission/${submissionId}/screenshot/upload`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  return api;
}

export const uploadMovieAPI = async (teamId, yearId, submissionId, file, onProgress) => {
  /*const chunkSize = 5 * 1024 * 1024; // 5MB per chunk (adjust as needed)
  const totalChunks = Math.ceil(file.size / chunkSize);
  const timestamp = Math.floor(Date.now() / 1000);
  for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('fileName', file.name); // Include the file name to reassemble on server
      formData.append('chunkIndex', i); // Keep track of the chunk number
      formData.append('totalChunks', totalChunks);
      formData.append('timestamp', timestamp);
      formData.append('fileSize', file.size);
  
      try {
         await post(`/team/${teamId}/year/${yearId}/submission/${submissionId}/movie/upload`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent) => {
                const chunkProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                const totalProgress = Math.round(((i + chunkProgress / 100) / totalChunks) * 100);
                if (onProgress) {
                    onProgress(totalProgress);  // Emit progress to the provided callback
                }
              }
          });
      } catch (error) {
          throw new Error("Error Uploading: "+ error);
      }
  }
  const movie = await get(`/team/${teamId}/year/${yearId}/submission/${submissionId}/movie`);
  return movie; */

  await webSocketUpload(`team/${teamId}/year/${yearId}/submission/${submissionId}/movie/upload`,teamId, yearId, file, onProgress);
};

export const uploadScreeningCopyAPI = async (teamId, yearId, submissionId, file, onProgress) => {
  await webSocketUpload(`team/${teamId}/year/${yearId}/submission/${submissionId}/screeningCopy/upload`,teamId, yearId, file, onProgress);
};


export const uploadTeamVideoAPI = async (teamId, yearId, submissionId, file, onProgress) => {
  await webSocketUpload(`team/${teamId}/year/${yearId}/submission/${submissionId}/teamvideo/upload`,teamId, yearId, file, onProgress);
};

export const uploadTeamLogoAPI = async(teamId, file) => {
    
  const api = post(`/team/${teamId}/avatar/upload`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  return api;
}

export const saveAvatarAPI = async( file) => {
    
  const api = post(`/users/avatar/upload`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  return api;
}

/*export const uploadContestMovieAPI = async(teamId, yearId, file, onProgress) => {
  const chunkSize = 5 * 1024 * 1024; // 5MB per chunk (adjust as needed)
  const totalChunks = Math.ceil(file.size / chunkSize);
  const timestamp = Math.floor(Date.now() / 1000);
  for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('fileName', file.name); // Include the file name to reassemble on server
      formData.append('chunkIndex', i); // Keep track of the chunk number
      formData.append('totalChunks', totalChunks);
      formData.append('timestamp', timestamp);
      formData.append('fileSize', file.size);
  
      try {
         await post(`/team/${teamId}/year/${yearId}/contest/upload`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent) => {
                const chunkProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                const totalProgress = Math.round(((i + chunkProgress / 100) / totalChunks) * 100);
                if (onProgress) {
                    onProgress(totalProgress);  // Emit progress to the provided callback
                }
              }
          });
      } catch (error) {
          console.error('Error uploading chunk', i, error);
          throw error; // Handle retry logic if necessary
      }
  }
};*/
export const uploadContestMovieAPI = async (teamId, yearId, file, onProgress) => {
 
  const upload = await webSocketUpload(`team/${teamId}/year/${yearId}/contest/upload`,teamId, yearId, file, onProgress);
  return upload;

};



export const deleteContestAPI = async(contestId) => {
    
    const api = remove(`/team/contest/${contestId}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    return api;
};

export const uploadContestReleaseAPI = async(contestId, file) => {
    
  const api = post(`/contest/${contestId}/release/upload`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  return api;
}

export const uploadSubmissionReleaseAPI = async(submissionId, file) => {
    
  const api = post(`/teams/${submissionId}/release/upload`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  return api;
}

export const deleteReleaseFromContestAPI = async(contestId,releaseId) => {
  const api = remove(`/team/contest/${contestId}/release/${releaseId}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
return api;
}

export const deleteReleaseFromSubmissionAPI = async(submissionId,releaseId) => {
  const api = remove(`/team/submission/${submissionId}/release/${releaseId}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
return api;
}