import axios from 'axios';


export async function sendMessageToDiscord(criteria, teamName) {
  const messageContent = "# OBSTRUCTION SELECTED";
  const fullMessage = `${messageContent}\nTeam: **${teamName}**\nCriteria: **${criteria.join(", ")}**`; //build string with team and selected crits

  const url = "https://discord.com/api/v9/channels/1288653913137872916/messages"; //This is the url for the criteria-2024 channel
  const headers = {
    "Authorization": "NzQ4NjgwOTEzMzAyNDU0NDIy.G49_Uq.7TXUf-w8-TdnViy1sjoyc7nyIfyvURY-wQDfSw", //token
    "Content-Type": "application/json"
  };

  const payload = {
    content: fullMessage
  };

  try {
    await axios.post(url, payload, { headers }); //post to disc
  } catch (error) {
    console.error("Error sending message to Discord:", error);
    throw new Error("Failed to send message to Discord");
  }
}

export async function sendCheckinToDiscord(teamName) { //same as above but only team name
  const messageContent = "# TEAM CHECK-IN"; //diff message
  const fullMessage = `${messageContent}\nTeam: **${teamName}**`;  //only build string with team name

  const url = "https://discord.com/api/v9/channels/1288652103711199252/messages"; //this is the URL for the test channel
  const headers = {
      "Authorization": "NzQ4NjgwOTEzMzAyNDU0NDIy.G49_Uq.7TXUf-w8-TdnViy1sjoyc7nyIfyvURY-wQDfSw",  
      "Content-Type": "application/json"
  };

  const payload = {
      content: fullMessage
  };

  try {
      await axios.post(url, payload, { headers }); 
  } catch (error) {
      console.error("Error sending message to Discord:", error);
      throw new Error("Failed to send message to Discord");
  }
}