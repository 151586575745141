<template>
    <v-card>
        <v-card-title>Reset Password</v-card-title>
        <v-card-text>
            <v-form ref="resetPasswordForm">

                <v-text-field v-model="resetPasswordFormData.password" label="New Password" type="password"
                    :rules="[rules.required, rules.password]" outlined></v-text-field>

                <v-text-field v-model="resetPasswordFormData.confirmPassword" label="Confirm New Password"
                    type="password" :rules="[rules.required, matchPassword]" outlined></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn color="orange" @click="resetPassword">Save New Password</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script setup>
import { ref } from 'vue';
import { resetMyPassword, getMe } from '@/controllers';
import { router } from "@/router";

const resetPasswordForm = ref({
    password: '',
    confirmPassword: ''
});
const resetPasswordFormData = ref({});

// Validation rules
const rules = {
    required: value => !!value || 'Required.',
    email: value => /.+@.+\..+/.test(value) || 'Invalid email.',
    password: value => value.length >= 6 || 'Password must be at least 6 characters.',
};

const matchPassword = () => {
    return resetPasswordForm.value.password === resetPasswordForm.value.confirmPassword || 'Passwords must match';
};

const resetPassword = async () => {
    if (await resetPasswordForm.value.validate()) {
        try {
            await resetMyPassword(resetPasswordFormData.value.password);

            await getMe();

            router.push({ name: "Home" });
        } catch (error) {
            console.error('Error updating password:', error);
        }
    }
};
</script>