<template>
    <v-container fluid>
        <v-stepper v-model="currentStep">
            <template v-slot:default="{ prev, next }">
                <v-stepper-header>
                    <template v-for="header in stepperHeaders" :key="`${header.id}-step`">
                        <v-stepper-item :complete="header.isCompleted" :step="header.id" :value="header.id"
                            :editable="submissionData.title != null" :color="header.isCompleted ? 'green' : ''">{{
                                header.title }}</v-stepper-item>

                        <v-divider v-if="header.id !== stepperHeaders.length"></v-divider>
                    </template>
                </v-stepper-header>
                <v-stepper-window>
                    <v-stepper-window-item :value="1">
                        <v-card class="info_card" title="Late Movie - Due by Tue 10.01 9pm EST">
                            <template v-slot:append>
                                <v-icon v-if="submissionData.movie && submissionData.movie.id"
                                    @click="toggleMovieUpload" icon="mdi-pencil"></v-icon>
                            </template>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-card-title
                                            v-if="submissionData.movie && submissionData.movie.id && !isMovieEdit"
                                            class="text-h5 font-weight-bold px-0">
                                            {{ submissionData.movie.caption }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            v-if="submissionData.movie && submissionData.movie.id && !isMovieEdit"
                                            class="px-0">
                                            Uploaded On: {{ submissionData.movie.created_on }}
                                        </v-card-subtitle>
                                        <div class="pt-5 pb-4" v-if="!pastDeadline && isMovieEdit">
                                            <p>Late Submissions for Screening at the Weinberg Center may be turned in by Tuesday 10.1, 9 pm EST. Only the original films received on time will be viewed by the judges. 
                                            </p>
                                            <p>NOTE: Late Submissions are ineligible for Audience Choice. </p>
                                            <p>FILMS MUST BE IN AN ACCEPTABLE FORMAT. Add 1 frame of black before and
                                                after your film.
                                                Accepted Format for screening is H.264 or H.265 (1080p Files preferred,
                                                4K accepted).
                                                Bitrate of 45Mbs max. Sound: Stereo AAC</p>

                                            <p>Once the film is uploaded, it will appear on this page and will be
                                                streamable once it
                                                processes. If the film is on this page, we have your entry!</p>
                                        </div>
                                        <v-form v-if="(!submissionData.movie || isMovieEdit)">
                                            <v-file-input label="Choose a file" v-model="file"
                                                accept="video/*"></v-file-input>
                                            <v-btn variant="elevated" color="primary" @click="uploadMovieFile"
                                                :disabled="!file">Upload</v-btn>
                                            <v-progress-linear v-if="uploadMovieProgress > 0"
                                                v-model="uploadMovieProgress" height="10"
                                                color="blue"></v-progress-linear>
                                            <v-alert v-if="uploadMovieStatus" :type="uploadMovieStatus.type"
                                                dismissible>
                                                {{ uploadMovieStatus.message }}
                                            </v-alert>
                                        </v-form>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        v-if="submissionData.movie && submissionData.movie.id && submissionData.movie.is_processing && !isMovieEdit"
                                        cols="auto" class="d-flex flex-column align-center justify-center">
                                        <v-progress-circular indeterminate></v-progress-circular>
                                        Don't worry we have your file. We are currently processing it and it will be
                                        available shortly.
                                    </v-col>
                                    <v-col
                                        v-if="submissionData.movie && submissionData.movie.id && !submissionData.movie.is_processing && !isMovieEdit"
                                        cols="12" class="video_player d-flex flex-column align-center justify-center">
                                        <vue3-video-player :src="submissionData.movie.url"></vue3-video-player>
                                        <a :href="submissionData.movie.url" download>
                                            <v-btn variant="elevated" color="primary" text class="mt-2">
                                                Download
                                            </v-btn>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                    </v-stepper-window-item>
                    <v-stepper-window-item :value="2">
                        <v-card class="info_card" title="Late Entry Form - Due by Tue 10.01 9pm EST" flat>
                            <div class="py-5"><h1>We must have this form filled out completely at Turn-in.</h1></div>
                            <v-form class="px-4" ref="formInfo" v-slot="{ errors }" @submit.prevent="onSubmitInfo">
                                <div class="py-5">
                                    <h2>Submission Overview</h2>
                                </div>
                                <v-text-field v-model="submissionData.title" label="Film Title"
                                    :rules="[rules.required]" :error-messages="errors.title"></v-text-field>
                                <v-text-field v-model="submissionData.director" label="Director"
                                    :rules="[rules.required]" :error-messages="errors.director"></v-text-field>
                                <v-text-field v-model="submissionData.logline" label="Log Line"
                                    :rules="[rules.required]" :error-messages="errors.logline"></v-text-field>
                                <v-text-field v-model="submissionData.running_time" label="Running Time (MM:SS)"
                                    :rules="[rules.timeValidation]" placeholder="00:00" maxlength="5"
                                    :error-messages="errors.running_time" @input="formatTime"></v-text-field>
                                <v-divider></v-divider>
                                <div class="py-5">
                                    <h2>Submission Genre and Content Rating</h2>
                                </div>
                                <v-select v-model="submissionData.genre"
                                    :items="['Action', 'Animated', 'Comedy', 'Drama', 'Experimential', 'Fantasy', 'Horror', 'Musical', 'Noir', 'Romance', 'Science Fiction', 'Thriller', 'Western',]"
                                    label="Genre" :rules="[rules.required]" :error-messages="errors.genre"></v-select>
                                <v-select v-model="submissionData.rating" :items="['G', 'PG', 'PG-13', 'R']"
                                    label="Rating" :rules="[rules.required]" :error-messages="errors.rating"></v-select>
                                <div class="py-5">
                                    <h2>Contains:</h2>
                                </div>
                                <v-row>
                                    <v-col v-for="theme in themes" :key="theme">
                                        <v-checkbox :label="theme" :value="theme" v-model="selectedThemes"></v-checkbox>
                                    </v-col>
                                </v-row>

                                <v-divider></v-divider>
                                <div class="py-5">
                                    <h2>Criteria:</h2>
                                </div>
                                <v-card title="Mandatory Criteria">
                                    <v-card-text>
                                        <v-list lines="two">

                                            <template v-for="man in manditoryCriteria" :key="man.id">
                                                <v-list-item>
                                                    <template v-slot:prepend>
                                                        <v-avatar size rounded="0" style="border-width:0">
                                                            <v-img width="125px" contain
                                                                :src="man.criteria.thubmnail.url"></v-img>
                                                        </v-avatar>
                                                    </template>

                                                    <v-list-item-title class="text-h6 pb-2 font-weight-bold">{{
                                                        man.criteria.name
                                                        }}</v-list-item-title>
                                                    <v-list-item-subtitle class="pb-2">{{ man.criteria.description
                                                        }}</v-list-item-subtitle>
                                                    <v-text-field v-model="man.use_of_criteria"
                                                        label="How was the criteria used in the film?"
                                                        :rules="[rules.required]"
                                                        :error-messages="errors.use_of_criteria"></v-text-field>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </template>
                                        </v-list>

                                    </v-card-text>
                                </v-card>
                                <v-card class="mt-4" title="Optional Criteria"
                                    subtitle="To add Optional Criteria please use the +">

                                    <template v-slot:append>
                                        <v-icon icon="mdi-plus" @click="openModal('addOptional')"></v-icon><v-tooltip
                                            activator="parent" location="end">Add
                                            Optional Criteria</v-tooltip>
                                    </template>
                                    <v-card-text>
                                        <v-list lines="two" v-if="optionalCriteria.length > 0">

                                            <template v-for="man in optionalCriteria" :key="man.id">
                                                <v-list-item>
                                                    <template v-slot:prepend>
                                                        <v-avatar size rounded="0" style="border-width:0">
                                                            <v-img width="125px" contain
                                                                :src="man.criteria.thubmnail.url"></v-img>
                                                        </v-avatar>
                                                        <v-divider></v-divider>
                                                    </template>

                                                    <v-list-item-title class="text-h6 pb-2 font-weight-bold">{{
                                                        man.criteria.name
                                                        }}</v-list-item-title>
                                                    <v-list-item-subtitle class="pb-2">{{ man.criteria.description
                                                        }}</v-list-item-subtitle>
                                                    <v-text-field v-model="man.use_of_criteria"
                                                        label="How was the criteria used in the film?"
                                                        :rules="[rules.required]"
                                                        :error-messages="errors.use_of_criteria"></v-text-field>
                                                    <v-divider></v-divider>
                                                    <template v-slot:append>
                                                        <v-icon icon="mdi-trash-can-outline"
                                                            @click="removeCrit(man)"></v-icon>
                                                        <v-tooltip activator="parent" location="end">Remove
                                                            Optional Criteria</v-tooltip>
                                                    </template>

                                                </v-list-item>


                                            </template>
                                        </v-list>
                                        <div v-else>Click the <v-icon icon="mdi-plus"> </v-icon> if you would like to
                                            add
                                            additional criteria that you
                                            did.</div>
                                    </v-card-text>
                                </v-card>
                                <v-divider></v-divider>
                                <v-checkbox v-model="submissionData.createdMusic"
                                    label="Did you have music created in 72 Hours?"></v-checkbox>
                                <v-checkbox v-model="submissionData.request_notes"
                                    label="Would you like to see your notes from the judges?"></v-checkbox>

                            </v-form>
                            <v-card-actions>
                                <v-btn variant="elevated" color="primary" @click="onSubmitInfo"
                                    :loading="isSubmittingInfo">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-dialog v-model="addOptionalCriteria" persistent>
                            <v-card class="p-3" title="Select Optional Criteria">
                                <v-card-text>
                                    <v-list lines="two" v-model:opened="open">
                                        <v-list-group v-for="(group, groupIndex) in criterias" :key="groupIndex">
                                            <template v-slot:activator="{ props }">
                                                <v-list-item v-bind="props">
                                                    <v-list-item-title class="text-h6 font-weight-bold">{{
                                                        group.group_name }}</v-list-item-title>
                                                </v-list-item>
                                                <v-divider class="my-0"></v-divider>
                                            </template>

                                            <v-list-item v-for="(criteria, criteriaIndex) in group.criteria"
                                                :key="criteriaIndex">
                                                <template v-slot:prepend>
                                                    <v-avatar size rounded="0" style="border-width:0">
                                                        <v-img width="125px" contain :src="criteria.thubmnail.url"
                                                            alt="Thumbnail" />
                                                    </v-avatar>
                                                </template>
                                                <v-list-item-title class="text-h6 pb-2 px-3 font-weight-bold">{{
                                                    criteria.name
                                                    }}</v-list-item-title>
                                                <v-list-item-subtitle class="pb-2 px-3">{{ criteria.description
                                                    }}</v-list-item-subtitle>
                                                <v-checkbox-btn :model-value="isCriteriaSelected(criteria.id)"
                                                    @click="toggleSelection(criteria)" label="Add as Optional Criteria">

                                                </v-checkbox-btn>

                                            </v-list-item>
                                        </v-list-group>
                                    </v-list>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn @click="addOptionalCriteria = false">Cancel</v-btn>
                                    <v-btn variant="elevated" color="primary" @click="saveOptCriteria">Add
                                        Criteria</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-stepper-window-item>
                    <v-stepper-window-item :value="3">
                        <v-card variant="text" class="info_card" title="Still & Team Video - Due by Tue Oct 1st 9pm EST">
                            <template v-slot:append>
                                <v-icon v-if="submissionData.teamVideo && submissionData.teamVideo.id"
                                    @click="toggleTeamVideoUpload" icon="mdi-pencil"></v-icon>
                            </template>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-card-title
                                            v-if="submissionData.teamVideo && submissionData.teamVideo.id && !isTeamVideoEdit"
                                            class="text-h5 font-weight-bold px-0">
                                            {{ submissionData.teamVideo.caption }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            v-if="submissionData.teamVideo && submissionData.teamVideo.id && !isTeamVideoEdit"
                                            class="px-0">
                                            Uploaded On: {{ submissionData.teamVideo.created_on }}
                                        </v-card-subtitle>
                                        <div class="pt-4 pb-4" v-if=" (!submissionData.teamVideo || isTeamVideoEdit)">
                                            <p>10 SECOND TEAM VIDEO/TEMPLATE. Team videos are a great way to show off your team. Do not just do a 10 second logo. It must be no more than 10 seconds (shorter is fine)! ADD 1 Frame of Black before and after your film.  FILMS MUST BE IN AN ACCEPTABLE FORMAT. Accepted Format for screening is H.264 or H.265 (1080p Files preferred, 4K accepted). Bitrate of 45Mbs max. Sound: Stereo AAC</p>
                                            <p>Once the film is uploaded, it will appear on this page and will be
                                                streamable once it
                                                processes. If the film is on this page, we have your entry!</p>
                                        </div>
                                        <v-form v-if="(!submissionData.teamVideo || isTeamVideoEdit)">
                                            <v-file-input label="Choose a file" v-model="fileTeam"
                                                accept="video/*"></v-file-input>
                                            <v-btn variant="elevated" color="primary" @click="uploadTeamVideoFile"
                                                :disabled="!fileTeam">Upload</v-btn>
                                            <v-progress-linear v-if="uploadTeamVideoProgress > 0"
                                                v-model="uploadTeamVideoProgress" height="10"
                                                color="blue"></v-progress-linear>
                                            <v-alert v-if="uploadTeamVideoStatus" :type="uploadTeamVideoStatus.type"
                                                dismissible>
                                                {{ uploadTeamVideoStatus.message }}
                                            </v-alert>
                                        </v-form>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        v-if="submissionData.teamVideo && submissionData.teamVideo.id && submissionData.teamVideo.is_processing && !isTeamVideoEdit"
                                        cols="auto" class="d-flex flex-column align-center justify-center">
                                        <v-progress-circular indeterminate></v-progress-circular>
                                        Don't worry we have your file. We are currently processing it and it will be
                                        available shortly.
                                    </v-col>
                                    <v-col
                                        v-if="submissionData.teamVideo && submissionData.teamVideo.id && !submissionData.teamVideo.is_processing && !isTeamVideoEdit"
                                        cols="12" class="video_player d-flex flex-column align-center justify-center">
                                        <vue3-video-player :src="submissionData.teamVideo.url"></vue3-video-player>
                                        <a :href="submissionData.teamVideo.url" download>
                                            <v-btn variant="elevated" color="primary" text class="mt-2">
                                                Download
                                            </v-btn>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card variant="text" class="fill-height info_card" title="Movie Still">
                            <template v-slot:append>
                                <v-icon v-if="submissionData.screenshot && submissionData.screenshot.id"
                                    @click="toggleScreenShotUpload" icon="mdi-pencil"></v-icon>
                            </template>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col>
                                            <v-card-title
                                                v-if="submissionData.screenshot && submissionData.screenshot.id && !isScreenShotEdit"
                                                class="text-h5 font-weight-bold px-0">
                                                {{ submissionData.screenshot.caption }}
                                            </v-card-title>
                                            <v-card-subtitle
                                                v-if="submissionData.screenshot && submissionData.screenshot.id && !isScreenShotEdit" 
                                                class="px-0">
                                                Uploaded On: {{ submissionData.screenshot.created_on }}
                                            </v-card-subtitle>

                                            <v-form v-if="!submissionData.screenshot || isScreenShotEdit">
                                                <p>The Movie Still will be used in our program.</p>
                                                <v-file-input label="Choose a file"
                                                    v-model="fileScreenShot"></v-file-input>
                                                <v-btn variant="elevated" color="primary" @click="uploadScreenShotFile"
                                                    :disabled="!uploadScreenShotFile">Upload</v-btn>
                                                <v-alert v-if="uploadScreenShotStatus"
                                                    :type="uploadScreenShotStatus.type" dismissible>
                                                    {{ uploadScreenShotStatus.message }}
                                                </v-alert>
                                            </v-form>
                                        </v-col>
                                        <!-- Right Icon Section -->
                                        <v-col
                                            v-if="submissionData.screenshot && submissionData.screenshot.id && !isScreenShotEdit"
                                            cols="auto" class="d-flex flex-column align-center justify-center">
                                            <v-avatar size="125" rounded="0">
                                                <v-img :src="submissionData.screenshot.url"></v-img>
                                            </v-avatar>
                                            <a :href="submissionData.screenshot.url" download>
                                                <v-btn variant="elevated" color="primary" text class="mt-2">
                                                    Download
                                                </v-btn>
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-stepper-window-item>
                    <v-stepper-window-item :value="4">
                        <v-card class="info_card" title="Release Forms - Due by Tue Oct 1st 9pm EST" flat>

                            <v-expansion-panels class="px-5 py-5" variant="accordion">
                                <v-row>
                                    <v-col title="Actions" cols="auto">
                                        <div class="pb-6">
                                            <v-btn variant="elevated" color="primary" @click="showRelease()"
                                                class="mt-2">Add
                                                Release</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-expansion-panel>
                                    <v-expansion-panel-title> Talent Releases
                                        <v-badge :color="talentReleases.length > 0 ? 'green' : 'error'"
                                            :content="talentReleases.length" inline></v-badge>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <div v-if="talentReleases.length == 0">You have not uploaded any talent
                                            release.</div>
                                        To add a new release, please click ADD RELEASE above.
                                        <v-list>
                                            <v-list-item v-for="(tRelease) in talentReleases" :key="tRelease.id"
                                                :value="tRelease" color="primary">
                                                <a :href="tRelease.release.url">{{ tRelease.release.filename }}</a>
                                                <template v-slot:append>
                                                    <v-icon icon="mdi-close"
                                                        @click="toggleDeleteRelease(tRelease.id)"></v-icon>
                                                </template>
                                            </v-list-item>

                                        </v-list>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-title> Location Releases
                                        <v-badge :color="locationReleases.length > 0 ? 'green' : 'error'"
                                            :content="locationReleases.length" inline></v-badge>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <div v-if="locationReleases.length == 0">You have not uploaded any location
                                            release.</div>
                                        To add a new release, please click ADD RELEASE above.
                                        <v-list>
                                            <v-list-item v-for="(tRelease) in locationReleases" :key="tRelease.id"
                                                :value="tRelease" color="primary">
                                                <a :href="tRelease.release.url">{{ tRelease.release.filename }}</a>
                                                <template v-slot:append>
                                                    <v-icon icon="mdi-close"
                                                        @click="toggleDeleteRelease(tRelease.id)"></v-icon>
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-title> Music Release
                                        <v-badge :color="musicReleases.length > 0 ? 'green' : 'error'"
                                            :content="musicReleases.length" inline></v-badge>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <div v-if="musicReleases.length == 0">You have not uploaded any music release.
                                        </div>
                                        To add a new release, please click ADD RELEASE above.
                                        <v-list>
                                            <v-list-item v-for="(tRelease) in musicReleases" :key="tRelease.id"
                                                :value="tRelease" color="primary">
                                                <a :href="tRelease.release.url">{{ tRelease.release.filename }}</a>
                                                <template v-slot:append>
                                                    <v-icon icon="mdi-close"
                                                        @click="toggleDeleteRelease(tRelease.id)"></v-icon>
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-card-actions>
                                <v-checkbox v-model="releasesDone" @click="toggleReleasesDone"
                                    label="I have uploaded all of my releases">

                                </v-checkbox>

                            </v-card-actions>
                        </v-card>
                        <v-dialog v-model="openReleaseModel">
                            <v-form v-model="isFormValid">
                                <v-card class="p-3">
                                    <v-card-title>Add Release</v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <a target="_blank"
                                                    href="https://72teams-prod.s3.amazonaws.com/Releases/TalentRelease.pdf"
                                                    download="TalentRelease.pdf">
                                                    <v-btn text class="mt-2">
                                                        Download Talent Release Form
                                                    </v-btn>
                                                </a>
                                            </v-col><v-col>
                                                <a target="_blank"
                                                    href="https://72teams-prod.s3.amazonaws.com/Releases/LocationRelease.pdf"
                                                    download="LocationRelease.pdf">
                                                    <v-btn text class="mt-2">
                                                        Download Location Release Form
                                                    </v-btn>
                                                </a>
                                            </v-col><v-col>
                                                <a target="_blank"
                                                    href="https://72teams-prod.s3.amazonaws.com/Releases/MusicRelease.pdf"
                                                    download="MusicRelease.pdf">
                                                    <v-btn text class="mt-2">
                                                        Download Music Release Form
                                                    </v-btn>
                                                </a>
                                            </v-col>
                                            <v-divider></v-divider>
                                        </v-row>

                                        <v-row>
                                            <v-col>
                                                <v-select label="Release Type" v-model="form.type" :items="releaseTypes"
                                                    item-value="id" item-title="release_name" :rules="[
                                                        v => !!v || 'Release Type is required'
                                                    ]" outlined></v-select>
                                            </v-col>
                                            <v-col>
                                                <v-file-input label="Choose a file"
                                                    v-model="releaseFile"></v-file-input>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn variant="elevated" color="primary" @click="uploadReleaseFile"
                                            :disabled="!isFormValid || !releaseFile">Upload</v-btn>
                                        <v-progress-linear v-if="uploadReleaseStatus > 0"
                                            v-model="uploadReleaseProgress" height="10"
                                            color="blue"></v-progress-linear>
                                        <v-alert v-if="uploadReleaseStatus" :type="uploadReleaseStatus.type"
                                            dismissible>
                                            {{ uploadReleaseStatus.message }}
                                        </v-alert>
                                    </v-card-actions>


                                </v-card>
                            </v-form>
                        </v-dialog>
                        <v-dialog v-model="deleteReleaseModal">
                            <v-card>
                                <v-card-title class="headline">Delete Confirmation</v-card-title>
                                <v-card-text>Are you sure you want to delete this release?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="grey" @click="toggleDeleteRelease">No</v-btn>
                                    <v-btn color="red" dark @click="deleteRelease">Yes, Delete</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-stepper-window-item>
                    <v-stepper-window-item :value="5">
                        <v-card class="info_card" title="Cast and Crew - Optional" flat>
                            
                            <v-card-text class="py-5">
                                <p>Feel free to add your Cast and Crew if you were not able to include full credits.</p>
                                <Editor api-key="i618116szgwd3naajmjzdrzzaqg7m762t1kz7j39wm6mbejr" :init="{
                                    plugins: 'lists link image table code help wordcount'
                                }" :initial-value="submissionData.castAndCrew" v-model="contentCastAndCrew" />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn variant="elevated" color="primary" @click="onSubmitCast"
                                    :loading="isSubmittingCast">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-stepper-window-item>
                </v-stepper-window>
                <v-divider></v-divider>
                <v-stepper-actions variant="elevated" color="primary" @click:next="next"
                    @click:prev="prev"></v-stepper-actions>
            </template>
        </v-stepper>
    </v-container>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import { uploadMovie, uploadTeamVideo } from '@/controllers';
import {
    getMySubmission, getMyTeam, updateMySubmission, removeOptionalCriteria, getCriteriaForYear,
    getCriteriaGroupsForYear, saveOptionalCriteria, getReleaseTypes, uploadSubmissionRelease,
    getSubmissionReleases, updateReleaseDones, saveCastAndCrew,
    uploadScreenShot, deleteReleaseFromSubmission
} from '@/controllers';
import { useAuthStore } from '@/store';
import Editor from '@tinymce/tinymce-vue'
const team = ref({});
const currentStep = ref(1);
const releasesDone = ref(null);
const authStore = useAuthStore();
const formInfo = ref(null);
const contentCastAndCrew = ref(null)
const isSubmittingInfo = ref(false);
const isSubmittingCast = ref(false);
const selectedThemes = ref([])
const manditoryCriteria = ref([]);
const optionalCriteria = ref([]);
const addOptionalCriteria = ref(false);
const loadingCriteria = ref(false);
const loadingCriteriaGroup = ref(false);
const talentReleases = ref([]);
const musicReleases = ref([]);
const locationReleases = ref([]);
const criteriasGroups = ref([]);
const releaseFile = ref(null);
const releaseTypes = ref(null);
const isFormValid = ref(false);
const deleteReleaseModal = ref(false);
const currentRelease = ref(null);
const form = ref({
    type: '',
})
const uploadReleaseStatus = ref(null);
const uploadReleaseProgress = ref(0);
const uploadReleaseCompleted = ref(false);
const openReleaseModel = ref(false);
const criterias = ref([]);
const open = ref([]);
const themes = ref(['Adult Themes', 'Language', 'Violence', 'Drugs', 'Sex or Nudity.']);

// Upload Movie', 'Entry Form', 'Forms', 'Extra'
const stepperHeaders = ref([{ title: "Upload Movie", isCompleted: false, id: 1, disabled: false },
{ title: "Entry Form", isCompleted: false, id: 2, disabled: false },
{ title: "Still & Team Video", isCompleted: false, id: 3, disabled: false },
{ title: "Release Forms", isCompleted: false, id: 4, disabled: false },
{ title: "Cast and Crew", isCompleted: false, id: 5, disabled: false },
    //{ title: "Extra", isCompleted: false, id: 6, disabled: false },
])

const submissionData = ref({
    id: null,
    director: null,
    genre: null,
    createdMusic: null,
    logline: null,
    rating: null,
    request_notes: false,
    running_time: null,
    title: null,
    use_of_criteria: null,
    containsAdult: null,
    movie: {
        caption: '',
        filename: '',
        id: null,
        url: null,
        created_on: null,
        is_processing: null,
    },
    trailer: {
        caption: '',
        filename: '',
        id: null,
        url: null,
        created_on: null,
        is_processing: null,
    },
    screenshot: {
        caption: '',
        filename: '',
        id: null,
        url: null,
        created_on: null,
        is_processing: null,
    }
})

const openModal = async (type = {}) => {

    if (type === 'addOptional') {
        selectedCriteria.value = [];
        await fetchCriterias();
        addOptionalCriteria.value = true;

    }
};

const showRelease = async () => {
    await toggleReleaseUpload();
}

const toggleReleaseUpload = async () => {
    releaseFile.value = null;
    uploadReleaseStatus.value = null;
    uploadReleaseProgress.value = 0;
    uploadReleaseCompleted.value = false;
    openReleaseModel.value = !openReleaseModel.value;
}

const toggleDeleteRelease = async (item) => {
    deleteReleaseModal.value = !deleteReleaseModal.value;
    if (deleteReleaseModal.value) {
        currentRelease.value = item;
    } else {
        currentRelease.value = null;
    }
}

const deleteRelease = async () => {
    await deleteReleaseFromSubmission(submissionData.value.id, currentRelease.value);
    await toggleDeleteRelease();

    const results = await getSubmissionReleases(submissionData.value.id);
    talentReleases.value = results.filter(release => release.releaseType.release_name === 'Talent');
    musicReleases.value = results.filter(release => release.releaseType.release_name === 'Music');
    locationReleases.value = results.filter(release => release.releaseType.release_name === 'Location');

}

const rules = {
    required: value => !!value || 'Required.',
    email: value => /.+@.+\..+/.test(value) || 'Invalid email.',
    phone: value => /^\d{10}$/.test(value) || 'Invalid phone number.',
    password: value => value.length >= 6 || 'Password must be at least 6 characters.',
    timeValidation: value => /^([0-5]?\d):([0-5]?\d)$/.test(value) || 'Enter time in MM:SS format (00:00 to 59:59)'
};

const time = ref('')
// Function to format the time input dynamically
const formatTime = () => {
    const formatted = time.value.replace(/[^\d]/g, '') // Remove all non-digit characters
    if (formatted.length > 2) {
        time.value = `${formatted.slice(0, 2)}:${formatted.slice(2, 4)}`
    } else {
        time.value = formatted
    }
}

const isMovieEdit = ref(false);
const file = ref(null);
const uploadMovieStatus = ref(null);
const uploadMovieProgress = ref(0);
const uploadMovieCompleted = ref(false);

const uploadMovieFile = async () => {
    if (!file.value) {
        uploadMovieStatus.value = {
            type: 'error',
            message: 'No file selected',
        };
        return;
    }
    uploadMovieStatus.value = null;
    uploadMovieCompleted.value = false;
    uploadMovieProgress.value = 0;


    try {

        await uploadMovie(team.value.id, authStore.year, submissionData.value.id, file.value, (progress) => {

            if (progress >= 100) {
                uploadMovieProgress.value = null;
                uploadMovieCompleted.value = true;
            } else {
                uploadMovieProgress.value = progress;
            }
        });
        fetchTeam();

        uploadMovieStatus.value = {
            type: 'success',
            message: 'Upload successful!',
        };
        setTimeout(() => {
            isMovieEdit.value = false;
        }, 1000);

    } catch (error) {
        uploadMovieStatus.value = {
            type: 'error',
            message: 'Upload failed: You will need to refresh (possibly log out and back in to clear your cache) and try again.  A BackUp Google Drive method is available in the Discord.',
        };
        console.error(error);
    }
};

const isScreenShotEdit = ref(false);
const fileScreenShot = ref(null);
const uploadScreenShotStatus = ref(null);
const uploadScreenShotProgress = ref(0);
const uploadScreenShotCompleted = ref(false);

const uploadScreenShotFile = async () => {
    if (!fileScreenShot.value) {
        uploadScreenShotStatus.value = {
            type: 'error',
            message: 'No file selected',
        };
        return;
    }
    uploadScreenShotStatus.value = null;
    uploadScreenShotCompleted.value = false;
    uploadScreenShotProgress.value = 0;


    try {

        await uploadScreenShot(team.value.id, authStore.year, submissionData.value.id, fileScreenShot.value, (progress) => {

            if (progress >= 100) {
                uploadScreenShotProgress.value = null;
                uploadScreenShotCompleted.value = true;
            } else {
                uploadScreenShotProgress.value = progress;
            }
        });
        fetchTeam();

        uploadScreenShotStatus.value = {
            type: 'success',
            message: 'Upload successful!',
        };
        setTimeout(() => {
            isScreenShotEdit.value = false;
        }, 1000);

    } catch (error) {
        uploadScreenShotStatus.value = {
            type: 'error',
            message: 'Upload failed: ' + error.message,
        };
        console.error(error);
    }
};

const isTeamVideoEdit = ref(false);
const fileTeam = ref(null);
const uploadTeamVideoStatus = ref(null);
const uploadTeamVideoProgress = ref(0);
const uploadTeamVideoCompleted = ref(false);

const uploadTeamVideoFile = async () => {
    if (!fileTeam.value) {
        uploadTeamVideoStatus.value = {
            type: 'error',
            message: 'No file selected',
        };
        return;
    }
    uploadTeamVideoStatus.value = null;
    uploadTeamVideoCompleted.value = false;
    uploadTeamVideoProgress.value = 0;


    try {

        await uploadTeamVideo(team.value.id, authStore.year, submissionData.value.id, fileTeam.value, (progress) => {

            if (progress >= 100) {
                uploadTeamVideoProgress.value = null;
                uploadTeamVideoCompleted.value = true;
            } else {
                uploadTeamVideoProgress.value = progress;
            }
        });
        fetchTeam();

        uploadTeamVideoStatus.value = {
            type: 'success',
            message: 'Upload successful!',
        };
        setTimeout(() => {
            isTeamVideoEdit.value = false;
        }, 1000);

    } catch (error) {
        uploadTeamVideoStatus.value = {
            type: 'error',
            message: 'Upload failed: You will need to refresh (possibly log out and back in to clear your cache) and try again.  A BackUp Google Drive method is available in the Discord.',
        };
        console.error(error);
    }
};


const selectedCriteria = ref([]);
const isCriteriaSelected = (criteriaId) => {
    return selectedCriteria.value.some(selected => selected.criteria.id === criteriaId);
};
const toggleSelection = (criteria) => {
    const index = selectedCriteria.value.findIndex(selected => selected.criteria.id === criteria.id);
    if (index > -1) {
        selectedCriteria.value.splice(index, 1);
    } else {

        selectedCriteria.value.push({ criteria: criteria, mandatory: true });
    }
};

const toggleReleasesDone = async () => {
    const currentReleaseDone = !releasesDone.value;
    await updateReleaseDones(submissionData.value.id, currentReleaseDone);
    stepperHeaders.value[3].isCompleted = currentReleaseDone;
}


const toggleMovieUpload = async () => {
    file.value = null;
    uploadMovieStatus.value = null;
    uploadMovieProgress.value = 0;
    uploadMovieCompleted.value = false;
    isMovieEdit.value = !isMovieEdit.value;
}

const toggleTeamVideoUpload = async () => {
    file.value = null;
    uploadTeamVideoStatus.value = null;
    uploadTeamVideoProgress.value = 0;
    uploadTeamVideoCompleted.value = false;
    isTeamVideoEdit.value = !isTeamVideoEdit.value;

}

const toggleScreenShotUpload = async () => {
    file.value = null;
    uploadScreenShotStatus.value = null;
    uploadScreenShotProgress.value = 0;
    uploadScreenShotCompleted.value = false;
    isScreenShotEdit.value = !isScreenShotEdit.value;

}

const onSubmitInfo = async () => {
    const valid = await formInfo.value.validate();
    const containsAdult = selectedThemes.value.join(';');

    submissionData.value.containsAdult = containsAdult;
    if (valid.valid) {
        try {
            isSubmittingInfo.value = true;
            const result = await updateMySubmission(authStore.year, submissionData.value, manditoryCriteria.value, optionalCriteria.value);
            if (result) {
                await fetchTeam();
            }
        } catch (error) {
            console.error('Error updating user:', error);
        } finally {
            isSubmittingInfo.value = false;
        }
    }
}


const fetchTeam = async () => {
    try {
        const teamData = await getMyTeam(authStore.year);
        team.value = teamData;
        const submissionObj = await getMySubmission(authStore.year);
        submissionData.value = submissionObj;
        submissionData.value.request_notes = submissionObj.request_notes ? true : false;
        selectedThemes.value = submissionObj.containsAdult ? submissionObj.containsAdult.split(';') : [];
        if (submissionObj.teamCriteria.criteriaRelations) {
            manditoryCriteria.value = submissionObj.teamCriteria.criteriaRelations.filter(critiera => critiera.mandatory == true);
            optionalCriteria.value = submissionObj.teamCriteria.criteriaRelations.filter(critiera => critiera.mandatory == false);
        } else {
            manditoryCriteria.value = [];
            optionalCriteria.value = [];
        }
        if (submissionData.value.movie && submissionData.value.movie.id) {
            stepperHeaders.value[0].isCompleted = true;
        } else {
            stepperHeaders.value[0].isCompleted = false;
        }

        if (submissionData.value.title != null) {
            stepperHeaders.value[1].isCompleted = true;
        } else {
            stepperHeaders.value[1].isCompleted = false;
        }

        talentReleases.value = submissionObj.releases.filter(release => release.releaseType.release_name === 'Talent');
        musicReleases.value = submissionObj.releases.filter(release => release.releaseType.release_name === 'Music');
        locationReleases.value = submissionObj.releases.filter(release => release.releaseType.release_name === 'Location');

        if (submissionObj.releasesDone === true) {
            stepperHeaders.value[3].isCompleted = true;
            releasesDone.value = true;
        } else {
            stepperHeaders.value[3].isCompleted = false;
            releasesDone.value = false;
        }

        contentCastAndCrew.value = submissionObj.castAndCrew

        if (submissionObj.castAndCrew) {
            stepperHeaders.value[4].isCompleted = true;
        } else {
            stepperHeaders.value[4].isCompleted = false;
        }

        if (submissionObj.teamVideo && submissionObj.screenshot) {
            stepperHeaders.value[2].isCompleted = true;
        } else {
            stepperHeaders.value[2].isCompleted = false;
        }

    } catch (error) {
        console.error('Error fetching team:', error);
    }
};

const removeCrit = async (item) => {
    try {
        await removeOptionalCriteria(item.id);
        optionalCriteria.value = optionalCriteria.value.filter(crit => crit.id !== item.id);

    } catch (error) {
        console.error('Could not delete optional Criteria');
    }
}

const saveOptCriteria = async () => {
    try {

        const results = await saveOptionalCriteria(authStore.year, selectedCriteria.value);
        let current = optionalCriteria.value;
        current.push(...results);

        optionalCriteria.value = current

        addOptionalCriteria.value = false;
    } catch (error) {
        console.error('Could not add optional Criteria');
    }
}

const fetchCriterias = async () => {
    try {
        loadingCriteria.value = true;
        let response = await getCriteriaForYear(authStore.year);
        response = response.filter(item => !manditoryCriteria.value.some(mandatory => mandatory.criteria.id === item.id));
        response = response.filter(item => !optionalCriteria.value.some(optional => optional.criteria.id === item.id));
        criterias.value = criteriasGroups.value.map(group => {
            return {
                group_name: group.group_name,
                color: group.color.toLowerCase(),
                criteria: response.filter(criteria => criteria.group.group_name === group.group_name)
            };
        });

    } catch (error) {
        console.error('Error fetching criterias:', error);
    } finally {
        loadingCriteria.value = false;
    }
};

const fetchCriteriaGroups = async () => {
    try {
        loadingCriteriaGroup.value = true;
        const response = await getCriteriaGroupsForYear(authStore.year);
        criteriasGroups.value = response;
    } catch (error) {
        console.error('Error fetching criterias groups:', error);
    } finally {
        loadingCriteriaGroup.value = false;
    }
};

const getTypes = async () => {
    try {
        const types = await getReleaseTypes();
        releaseTypes.value = types.data;

    } catch (error) {
        console.error('Error fetching release types:', error);
    }
};

const uploadReleaseFile = async () => {
    if (!releaseFile.value) {
        uploadReleaseStatus.value = {
            type: 'error',
            message: 'No file selected',
        };
        return;
    }

    try {
        await uploadSubmissionRelease(submissionData.value.id, form.value.type, releaseFile.value);
        const results = await getSubmissionReleases(submissionData.value.id);
        talentReleases.value = results.filter(release => release.releaseType.release_name === 'Talent');
        musicReleases.value = results.filter(release => release.releaseType.release_name === 'Music');
        locationReleases.value = results.filter(release => release.releaseType.release_name === 'Location');

        uploadReleaseStatus.value = {
            type: 'success',
            message: 'Upload successful!',
        };
        setTimeout(() => {
            openReleaseModel.value = false;
        }, 1000);

    } catch (error) {
        uploadReleaseStatus.value = {
            type: 'error',
            message: 'Upload failed: ' + error.message,
        };
        console.error(error);
    }
}

const onSubmitCast = async () => {
    isSubmittingCast.value = true;
    await saveCastAndCrew(submissionData.value.id, contentCastAndCrew.value);
    submissionData.value.castAndCrew = contentCastAndCrew.value;

    if (submissionData.value.castAndCrew) {
        stepperHeaders.value[4].isCompleted = true;
    } else {
        stepperHeaders.value[4].isCompleted = false;
    }

    isSubmittingCast.value = false;
}




onMounted(() => {
    fetchTeam();
    fetchCriteriaGroups();
    getTypes();
});
</script>

<!-- TODOS
* Add lockdown based on times
* Add URL for them to pas
-->