import InfoTab from '@/views/admin/home/InfoTab.vue';
import TeamsTab from '@/views/admin/home/TeamTab.vue';
import CriteriaTab from '@/views/admin/home/CriteriaTab.vue';
import ContestTab from '@/views/admin/home/ContestTab.vue';
import AssignCriteria from '@/views/admin/home/AssignCriteria.vue';
import TermsTurnIn from '@/views/admin/home/TermsTurnIn.vue';
import CheckInTeams from '@/views/admin/home/CheckInTeams.vue';
import TeamOrder from '@/views/admin/home/TeamOrder.vue';
import TeamSubmissions from '@/views/admin/home/TeamSubmissions.vue';
import TeamScreeningOrderFriday from '@/views/admin/home/TeamScreeningOrderFriday.vue';
import TeamScreeningOrderSaturday from '@/views/admin/home/TeamScreeningOrderSaturday.vue';
export const adminHomeRoutes = [
   
      {
        path: "/admin/home/info",
        name: "Admin",
        component: InfoTab,
        meta: { guest: false }
      },
      {
        path: "/admin/home/teams",
        name: "AdminHomeTeams",
        component: TeamsTab,
        meta: { guest: false }
      },
      {
        path: "/admin/home/criteria",
        name: "AdminHomeCriteria",
        component: CriteriaTab,
        meta: { guest: false }
      },
      {
        path: "/admin/home/contest",
        name: "AdminHomeContest",
        component: ContestTab,
        meta: { guest: false }
      },
      {
        path: "/admin/home/criteria/assign",
        name: "AdminAssignCriteria",
        component: AssignCriteria,
        meta: { guest: false }
      },
      {
        path: "/admin/home/terms",
        name: "AdminTermsTurnIn",
        component: TermsTurnIn,
        meta: { guest: false }
      },
      {
        path: "/admin/home/checkIn",
        name: "AdminTermsCheckIn",
        component: CheckInTeams,
        meta: { guest: false }
      },
      {
        path: "/admin/home/order",
        name: "AdminTeamOrder",
        component: TeamOrder,
        meta: { guest: false }
      },
      {
        path: "/admin/home/submissions",
        name: "AdminTeamSubmissions",
        component: TeamSubmissions,
        meta: { guest: false }
      },
      {
        path: "/admin/home/friday",
        name: "AdminFridayScreeningOrder",
        component: TeamScreeningOrderFriday,
        meta: { guest: false }
      },
      {
        path: "/admin/home/saturday",
        name: "AdminSaturdayScreeningOrder",
        component: TeamScreeningOrderSaturday,
        meta: { guest: false }
      },
      

      
    ];