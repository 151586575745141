import { createApp } from "vue";
import { createPinia } from 'pinia';
import App from "./App.vue";
import { router } from "@/router";
import { adminUserRoutes } from '@/router/admin/adminUserRoutes';
import { adminYearRoutes } from '@/router/admin/adminYearRoutes';
import { adminHomeRoutes } from '@/router/admin/adminHomeRoutes';
import { adminTeamRoutes } from '@/router/admin/adminTeamRoutes';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuthStore } from '@/store/modules/auth';
import { createPersistedStatePlugin } from '@/store/modules/persistStore';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'


const app = createApp(App);
const pinia = createPinia();
pinia.use(createPersistedStatePlugin('authStore'));
app.use(pinia);
const vuetify = createVuetify({
    components,
    directives,
  })
  
app.use(vuetify);
app.use(Vue3VideoPlayer);
// Function to add admin routes
const addAdminRoutes = async () => {
    if (useAuthStore().isAdmin()) {
        adminUserRoutes.forEach(route => router.addRoute(route));
        adminYearRoutes.forEach(route => router.addRoute(route));
        adminTeamRoutes.forEach(route => router.addRoute(route));
        adminHomeRoutes.forEach(route => router.addRoute(route));
    }
};

// Await route addition before mounting the app
(async () => {
    await addAdminRoutes();
    app.use(router);
    app.mount('#app');
})();
