<template>
    
        <v-container fluid>
            <v-row>
                <v-col class="mb-6" :cols="cols[0]">
                    <v-card class="fill-height info_card" title="Team Lead" subtitle="This info is private and used for 72 admin purposes only.">
                        <v-card-text>
                            <v-row>
                                <v-col :cols="cols[1]">
                                    <v-list v-if="teamLeader && teamLeader.user">
                                        <v-list-item :prepend-avatar="avatar">
                                            <v-list-item-title>{{
                                                teamLeader.user.first_name }}
                                                {{ teamLeader.user.last_name }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ teamLeader.user.email_address
                                                }}</v-list-item-subtitle>
                                        </v-list-item>
                                        <v-list-item><strong>Phone:</strong> {{ teamLeader.user.phone_number }}</v-list-item>
                                        <v-list-item><strong>Address:</strong> <v-row><v-col>{{ teamLeader.user.street_address }} <br />
                                                    {{
                                                        teamLeader.user.city
                                                    }}, {{ teamLeader.user.state }} {{ teamLeader.user.postal_code }}
                                                    <br /> {{
                                                        teamLeader.user.country }}</v-col></v-row> </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col>
                                    <v-col cols="auto" class="d-flex flex-column align-center justify-center">
                                        <v-avatar v-if="teamLeader && teamLeader.user && teamLeader.user.is_under_18"
                                            size="125">
                                            <v-icon icon="mdi-account-child-circle" size="80" color="red"></v-icon>
                                        </v-avatar>

                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col class="mb-6" :cols="cols[1]">
                    <v-card class="fill-height info_card" title="Team Logo" >
                        <template v-slot:append>
                            <v-icon v-if="team.avatar && team.avatar.id" @click="isTeamLogoEdit = !isTeamLogoEdit" icon="mdi-pencil"></v-icon>
                        </template>
                        <v-card-text>
                            <v-container>
                            <v-row>
                                <v-col>
                                    <v-card-title v-if="team.avatar && team.avatar.id && !isTeamLogoEdit" class="text-h5">
                                        {{ team.avatar.caption }}
                                    </v-card-title>
                                    <v-card-subtitle v-if="team.avatar && team.avatar.id && !isTeamLogoEdit">
                                        Uploaded On: {{ team.avatar.created_on }}
                                    </v-card-subtitle>
                    
                                    <v-form v-if="!team.avatar || isTeamLogoEdit ">
                                        <v-file-input label="Choose a file" v-model="file"></v-file-input>
                                        <v-btn variant="elevated" color="primary" @click="uploadFile" :disabled="!file">Upload</v-btn>
                                        <v-alert v-if="uploadStatus" :type="uploadStatus.type" dismissible>
                                            {{ uploadStatus.message }}
                                        </v-alert>
                                    </v-form>
                                </v-col>
                                <!-- Right Icon Section -->
                                <v-col  v-if="team.avatar && team.avatar.id && !isTeamLogoEdit" cols="auto" class="d-flex flex-column align-center justify-center">
                                    <v-avatar  size="125" rounded="0">
                                        <v-img :src="team.avatar.url" ></v-img>
                                    </v-avatar>
                                    <a :href="team.avatar.url" download>
                                        <v-btn text class="mt-2">
                                            Download
                                        </v-btn>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-card title="Team Info" class="info_card" subtitle="This info (website/socials/bio etc) may appear on 72Fest.com">
                        <template v-slot:append>
                            <v-icon @click="isTeamEdit = !isTeamEdit" icon="mdi-pencil">
                                <v-tooltip activator="parent" location="end">Edit Team</v-tooltip>
                            </v-icon>
                        </template>
                        <v-card-text v-if="!isTeamEdit">
                            <v-table>
                                <tbody>

                                    <tr v-if="team.bio">
                                        <td><strong>Bio:</strong></td>
                                        <td>{{ team.bio }}</td>
                                    </tr>
                                    <tr v-if="team.website">
                                        <td><strong>Website:</strong></td>
                                        <td><a :href='`{{ team.website }}`'>{{ team.website }}</a></td>
                                    </tr>
                                    <tr v-if="team.facebook">
                                        <td><strong>Facebook:</strong></td>
                                        <td><a :href='`https://www.facebook.com/${team.facebook}`'>@{{ team.facebook
                                                }}</a></td>
                                    </tr>
                                    <tr v-if="team.twitter">
                                        <td><strong>X:</strong></td>
                                        <td><a :href='`https://www.x.com/${team.twitter}`'>@{{ team.twitter }}</a></td>
                                    </tr>
                                    <tr v-if="team.instagram">
                                        <td><strong>Instagram</strong></td>
                                        <td><a :href='`https://www.instagram.com/${team.instagram}`'>@{{ team.instagram
                                                }}</a></td>
                                    </tr>
                                    <tr v-if="team.youtube">
                                        <td><strong>YouTube</strong></td>
                                        <td><a :href='`https://www.youtube.com/@${team.youtube}`'>@{{ team.youtube
                                                }}</a></td>
                                    </tr>
                                    <tr v-if="team.vimeo">
                                        <td><strong>Vimeo</strong></td>
                                        <td><a :href='`https://www.vimeo.com/${team.vimeo}`'>@{{ team.vimeo }}</a></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Team Level:</strong></td>
                                        <td>
                                            <div style="display: flex; align-items: center;">
                                                <v-row>
                                                    <v-col cols="auto">
                                                        <v-list-item>{{ selectedLevel.team_level
                                                            }}</v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </v-table>

                        </v-card-text>
                        <v-form ref="form" v-if="isTeamEdit">
                            <v-card-text>
                                <v-textarea variant="underlined" v-model="team.bio" label="Bio"></v-textarea>
                                <v-text-field variant="underlined" v-model="team.website"
                                    label="Website"></v-text-field>
                                <v-text-field variant="underlined" v-model="team.instagram"
                                    label="Instagram"></v-text-field>
                                <v-text-field variant="underlined" v-model="team.facebook"
                                    label="Facebook"></v-text-field>
                                <v-text-field variant="underlined" v-model="team.twitter"
                                    label="Twitter"></v-text-field>
                                <v-text-field variant="underlined" v-model="team.vimeo" label="Vimeo"></v-text-field>
                                <v-text-field variant="underlined" v-model="team.youtube"
                                    label="YouTube"></v-text-field>

                            </v-card-text>
                            <v-card-actions>
                                <v-btn :loading="isSubmitting" color="primary" @click="onSubmit">
                                    Save
                                </v-btn>
                                <v-alert v-if="errors.apiError" type="error" class="mt-3 mb-0">
                                    {{ errors.apiError }}
                                </v-alert>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>


        </v-container>
</template>
<script setup>
//import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { getMyTeam, getTeamLead, updateMyTeam,uploadTeamLogo } from '@/controllers';
import { useAuthStore } from '@/store';
const team = ref({});
const form = ref({});
const teamLeader = ref({});
const avatar = ref({});
const isTeamEdit = ref(false);
const isTeamLogoEdit = ref(false);
const isSubmitting = ref(false);
const file = ref(null);
const uploadStatus = ref(null);

const errors = ref({ apiError: null })
const selectedLevel = ref({
    team_level: null
});


const authStore = useAuthStore();
const fetchTeamLead = async () => {
    try {
        const user = await getTeamLead(team.value.id, authStore.year);
        teamLeader.value = user.data[0];
        avatar.value = teamLeader.value.user.avatar ? teamLeader.value.user.avatar.url :"/images/blankProfile.webp";
    } catch (error) {
        console.error('Error fetching team leader:', error);
    }
};

const fetchTeam = async () => {
    try {
        const teamData = await getMyTeam(authStore.year);
        team.value = teamData;
        selectedLevel.value.team_level = team.value.teamCriteria[0].teamLevel.team_level;

        fetchTeamLead();
    } catch (error) {
        console.error('Error fetching team:', error);
    }
};

const onSubmit = async () => {
    const valid = await form.value.validate();
    if (valid.valid) {
    try {
      isSubmitting.value = true;
      const result = await updateMyTeam(authStore.year, team.value);
      if (result) {
        await fetchTeam();
      }
      isTeamEdit.value = false;
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
        isSubmitting.value = false;
    }
  }
}


const uploadFile = async () => {
    if (!file.value) {
        uploadStatus.value = {
            type: 'error',
            message: 'No file selected',
        };
        return;
    }

    try {
        
        await uploadTeamLogo(team.value.id, file.value);
        await fetchTeam();
        uploadStatus.value = {
            type: 'success',
            message: 'Upload successful!',
        };
        setTimeout(() => {
            isTeamLogoEdit.value = false;
        }, 1000);

    } catch (error) {
        uploadStatus.value = {
            type: 'error',
            message: 'Upload failed: ' + error.message,
        };
        console.error(error);
    }
};


onMounted(() => {
    fetchTeam();

});
</script>
<!--- Responsive -->
<script>
  export default {
    computed: {
      cols () {
        const { lg, md, sm } = this.$vuetify.display
        return lg ? [6, 6] 
        : md? [6, 6]
          : sm ? [6, 6]
            : [12, 12]
      },
    },
  }
</script>
<!--- Responsive -->

<!-- 
TODOS
* Add Edit 
-->