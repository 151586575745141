import { get, post, remove, generatePagination } from '@/services/apiWrapper';

export const getAllTeams = async (body) => {
    const encodedSearchTerm = body.search ? encodeURIComponent(body.search.trim()): "";
    const encodedExclude = body.exclude ? encodeURIComponent(JSON.stringify(body.exclude)) : "";   

    const teams = await get(`/admin/teams?page=${body.page}&limit=${body.limit}&q=${encodedSearchTerm}&exclude=${encodedExclude}`)
    return teams;
}

export const getTeamById = async (id, yearId) => {
    let param = ''
    if (yearId) {
        param = `?year=${yearId}`;
    }
    const team = await get(`/admin/teams/${id}${param}`);
    return team;
}

export const getSubmissionAPI = async (id, yearId) => {
    let param = ''
    if (yearId) {
        param = `?year=${yearId}`;
    }
    const team = await get(`/admin/teams/${id}/submission${param}`);
    return team;
}


export const getTeamLeadId = async (teamId, yearId) => {
    const user = await get(`/teams/${teamId}/leader/${yearId}`);
    return user;
}

export const updateTeamLeadAPI = async (teamId, body) => {
    const lead = await post(`/admin/teams/${teamId}/lead`, body);
    return lead;
}

export const saveTeam = async (id, body) => {
    const team = await post(`/admin/teams/${id}/save`, body);
    return team;
}

export const createTeamAPI = async (body) => {
    const team = await post(`/admin/teams/createTeam`, body);
    return team;
}

export const deleteTeamAPI = async (id) => {
    const team = await remove(`/admin/teams/${id}`);
    return team;
}

export const getTeamMembersAPI = async (body) => {
    const pageOptions = await generatePagination(body.pageOptions);
    const teamMembers = await get(`/admin/teams/${body.teamId}/members/${body.yearId}${pageOptions}`);
    return teamMembers;    
}

export const getPossibleUsersAPI = async(body) => {
    const pageOptions = await generatePagination(body.pageOptions);
    const possibleUser = await get(`/admin/users${pageOptions}`)
    return possibleUser;
}

export const getTeamLevelsAPI = async () => {
    const user = await get(`/admin/teamslevels`);
    return user;
}

export const changeTeamLevelAPI = async(yearId, teamId, value) => {
    const level = post(`/admin/teams/${teamId}/year/${yearId}/level`, {value: value});
    return level
}

export const teamCheckInAPI = async(teamId, yearId) => {
    await post(`/admin/teams/${teamId}/year/${yearId}/checkIn`)
}
export const setTeamRemoteStatusAPI = async(teamId, yearId, isPerson) => {
    await post(`/admin/teams/${teamId}/year/${yearId}/inperson`, {isPerson: isPerson})
}

export const updateTeamRegistrationAPI = async (teamId, yearId, registration) => {
    await post(`/admin/teams/${teamId}/year/${yearId}/updateReg`, registration);
}
export const removeTeamMemberFromYearAPI = async(body) => {
    await remove(`/admin/teams/${body.teamId}/year/${body.yearId}/member/${body.removeId}`)
}