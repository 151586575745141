export function createPersistedStatePlugin(storeKey) {
    return (context) => {
      const fromStorage = localStorage.getItem(storeKey);
      if (fromStorage) {
        context.store.$patch(JSON.parse(fromStorage));
      }
  
      context.store.$subscribe((mutation, state) => {
        localStorage.setItem(storeKey, JSON.stringify(state));
      });
    };
  }
  