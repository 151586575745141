<template>
  <v-container>
    <v-btn
      color="primary"
      outlined
      :to="{ name: 'AdminTeamCreate' }"
      router
    >
      Create Team
    </v-btn>
    
    <v-divider class="my-4"></v-divider>

    <!-- Data Table -->
    <v-data-table-server
      :items="teams"
      v-model:items-per-page="limit"
      :headers="headers"
      :items-length="count"
      :loading="loading"
      :search="search"
      item-key="id"
      @update:options="handleLimitChange"
    >
    <template v-slot:[`item.action`]="{ item }"> 
      
        <v-btn
          :to="{ name: 'AdminTeamEdit', params: { id: item.id } }"
          router
          small
          color="primary"
        >
          Edit
        </v-btn>
      </template>
      <template v-slot:tfoot>
          <v-text-field
            v-model="search"
            class="ma-2"
            density="compact"
            placeholder="Search team..."
            hide-details
          ></v-text-field>
        </template>
    </v-data-table-server>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getTeams } from '@/controllers';

const teams = ref([]);
const count = ref(0);
const currentPage = ref(1); // Initial page
const search = ref("");
const limit = ref(10);
const loading = ref(true);

const headers = [
  { title: 'Name', key: 'name', align: 'start', sortable: false },
  { title: 'Actions', key: 'action', sortable: false } 
];

const fetchTeams = async () => {
  try {
    loading.value = true;
    const teamData = await getTeams({ limit: limit.value, page: currentPage.value, search: search.value });
    teams.value = teamData.data;
    count.value = teamData.count;
  } catch (error) {
    console.error('Error fetching teams:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchTeams();
});

const handleLimitChange = (value) => {
  limit.value = value.itemsPerPage;
  currentPage.value = value.page;
  search.value = value.search;
  fetchTeams(); // Fetch new data when limit changes
};

</script>

<style scoped>

</style>

<!--
TODOs
* change Edit to Icon
* add Delete icon
* add color sorting

-->