<script setup>
import { ref, onMounted, watch } from 'vue';
import { useAuthStore } from '@/store';
import { getYears } from '@/controllers';

const years = ref([]);
const selectedYear = ref(null);
const authStore = useAuthStore();
selectedYear.value = authStore.year;

const fetchYears = async () => {
  try {
    const yearData = await getYears({limit: -1, page:1, search: null});
    years.value = yearData.data;

  } catch (error) {
    console.error('Error fetching years:', error);
  }
};

onMounted(() => {
  fetchYears();
});

watch(selectedYear, (newValue) => {
  authStore.year = newValue;
});
</script>
<script>
export default {
  name: "NavBarAdmin"
};
</script>

<template>
  <v-list-item type="subheader">Year</v-list-item>
  <v-list-item prepend-icon="mdi-home"><router-link class="nav-item nav-link"
    :to="{ name: 'Admin' }">Home</router-link></v-list-item>
    
  <v-select label="Year" v-model="selectedYear" :items="years" item-value="id" item-title="year" outlined></v-select>

  <v-list-item type="subheader">Admin</v-list-item>

  <v-list-item prepend-icon="mdi-account"><router-link class="nav-item nav-link"
      :to="{ name: 'AdminUserListing' }">Users</router-link></v-list-item>
  <v-list-item prepend-icon="mdi-calendar-blank"><router-link class="nav-item nav-link"
      :to="{ name: 'AdminYearListing' }">Years</router-link></v-list-item>
  <v-list-item prepend-icon="mdi-account-group"> <router-link class="nav-item nav-link"
      :to="{ name: 'AdminTeamListing' }">Teams</router-link></v-list-item>

</template>


<style></style>