import {getMySelf, updateMyProfileAPI, resetMyPasswordAPI} from '@/services';
import { useAuthStore } from '@/store';

export async function getMe() {
    try {
        const user = await getMySelf();
        const auth = useAuthStore();
        auth.setFirstName(user.first_name);
        auth.setLastName(user.last_name);
        auth.setEmail(user.email_address);
        auth.setForceReset(user.force_reset);
        if (user.avatar && user.avatar.url) {
            auth.setAvatarUrl(user.avatar.url)
        }
        

        return user;
    } catch (error) {
        console.log(error);
        throw ("Cant't get user")
    }
}

export async function updateMyProfile(user) {
    try {
        await updateMyProfileAPI(user);
        return getMe();
    } catch (error) {
        console.log(error);
        throw ("Cant't update profile")
    }
}


export async function resetMyPassword(password) {
    try {
      
        await resetMyPasswordAPI(password)
        return getMe();
    } catch (error) {
        console.log(error);
        throw ("Cant't reset password")
    }
}