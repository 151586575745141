import { createRouter, createWebHistory } from 'vue-router';
import { HomeView, RegisterView, LoginView, MyProfile, ResetPassword } from "@/views/";
import { useAuthStore } from '@/store/modules/auth';

const routes = [
  {
    path: "/:tabName?",
    name: "Home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
    meta: { guest: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { guest: true },
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: MyProfile,
    meta: { requiresAuth: true },
  }
  ,
  {
    path: "/reset-password",
    name: "ForceReset",
    component: ResetPassword,
    meta: { requiresAuth: true },
  }
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  linkActiveClass: 'active'
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path.toLowerCase());
  const auth = useAuthStore();

 if (to.query.testing) {
    if (to.query.testing == "on"){
      auth.setTesting(true);
    } else {
      auth.setTesting(false);
    }
    
  }

  // Redirect to login page if not logged in and trying to access a restricted page
  if (authRequired && !auth.user) {
    auth.returnUrl = to.fullPath;
    return next({name:"Login", query: to.query});
  }
  if (to.path !== '/reset-password' && authRequired && auth.user && auth.force) {
    return next({name:"ForceReset", query: to.query});
  }

  if (to.path == '/submission' && !auth.getTurnStart()) {
    return next({name:"Home", query: to.query})
  }

  if (auth.isAdmin() && (to.path == "/" || to.path == "/admin" )) {
    return next({name:"Admin", query: to.query})
  } 

  next();
});
