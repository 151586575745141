<template>
  <v-card variant="flat">
    <v-card-item>
      <v-card-title>
        <v-row>
          <v-col>
        {{ team?.name || 'Loading...' }}
      </v-col>
        </v-row>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-row>
        <v-col cols="2" v-if="!isMobile">
          <v-sheet rounded="lg">
            <v-list-item
              v-for="(item, index) in listItems"
              :key="index"
              @click="selectTab(item.name)"
              :class="{ 'active': currentTab === item.name, 'disabled-item': !item.isEnabled }"
              :disabled="!item.isEnabled"
            >
              {{ item.label }}
              <v-badge
                v-if="item.name == 'notes' && teamNoteCount > 0"
                color="error"
                :content="teamNoteCount"
                inline
              ></v-badge>
            </v-list-item>
          </v-sheet>
        </v-col>
        <v-col>
          <v-sheet min-height="70vh" rounded="lg">
            <component :is="currentComponent"></component>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-bottom-navigation v-if="isMobile" v-model="currentTab" absolute>
    <v-btn
      v-for="(item, index) in listItems"
      :key="index"
      @click="selectTab(item.name)"
      :class="{ 'active': currentTab === item.name, 'disabled-item': !item.isEnabled }"
      :disabled="!item.isEnabled"
      text
    >
    
      {{ item.label }}
      <v-badge
        v-if="item.name == 'notes' && teamNoteCount > 0"
        color="error"
        :content="teamNoteCount"
        inline
      ></v-badge>
    </v-btn>
  </v-bottom-navigation>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import InfoComponent from '@/components/teams/TeamInfoComponent.vue';
import RegistrationComponent from '@/components/teams/TeamRegistrationCheckInComponent.vue';
import TeamSubmissionComponent from '@/components/teams/TeamSubmissionComponent.vue';
////import TeamBeforeComponent from '@/components/teams/TeamBeforeComponent.vue';
import { useAuthStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { getMyTeam, getMySubmission } from '@/controllers';
import { useDisplay } from 'vuetify';
import TeamScreeningCopyComponent from '@/components/teams/TeamScreeningCopyComponent.vue';
import TeamLateSubmissionComponent from '@/components/teams/TeamLateSubmissionComponent.vue';


defineEmits(['updateTab', 'updatedProfile']);
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const team = ref(null);
const teamNoteCount = ref(0);
const turnStart = authStore.getTurnStart();
const pastDeadline = ref(false);
const turnedIn = ref(false);


const { smAndDown } = useDisplay();
let isMobile = computed(() => smAndDown.value);

const listItems = ref([
  { name: 'info', label: 'Info', component: InfoComponent, isEnabled: true },
  ////{ name: 'beforethe72', label: 'Before The 72', component: TeamBeforeComponent, isEnabled: true },
  { name: 'registration', label: 'Registration / Check-In', component: RegistrationComponent, isEnabled: true },
]);

const currentTab = ref(route.params.tabName || 'info');

let currentComponent = computed(() => {
  const item = listItems.value.find(i => i.name === currentTab.value);
  return item ? item.component : null;
});

function selectTab(tabName) {
  currentTab.value = tabName;
}

const fetchTeam = async () => {
  try {
    const teamData = await getMyTeam(authStore.year);
    const submissionObj = await getMySubmission(authStore.year);
    team.value = teamData;
    console.log(submissionObj.movie.created_on)
    console.log(authStore.getTurnin())
    if (submissionObj != null && submissionObj.movie !=null && new Date(submissionObj.movie.created_on) <= authStore.getTurnin() && submissionObj.title != null){
      turnedIn.value = true;
    } else {
      turnedIn.value = false;
    }
  } catch (error) {
    console.error('Error fetching team:', error);
  }
};

watch(
  currentTab,
  (newTab) => {
    router.push({ name: 'Home', params: { tabName: newTab } });
  },
  { immediate: true }
);
const checkDeadline = async() => {
    pastDeadline.value = authStore.isPastTurnin();
    const hasSubmission = listItems.value.filter(item => item.name == 'submission').length > 0;
    const hasScreeningCopy = listItems.value.filter(item => item.name == 'screeningCopy').length > 0;
    const hasLateCopy = listItems.value.filter(item => item.name == 'latesubmission').length > 0;
    

    if ((!pastDeadline.value || turnedIn.value )&& !hasSubmission) {
      listItems.value.push({ name: 'submission', label: 'Submission', component: TeamSubmissionComponent, isEnabled: turnStart });
    } 
    if (!turnedIn.value && pastDeadline.value) {
        listItems.value = listItems.value.filter(item => item.name != 'submission');
    }

    if (!turnedIn.value && !hasLateCopy && pastDeadline.value) {
      listItems.value.push({ name: 'latesubmission', label: 'Late Submission', component: TeamLateSubmissionComponent, isEnabled: turnStart });
    }

    if (!hasScreeningCopy && pastDeadline.value) {
        listItems.value.push({ name: 'screeningCopy', label: 'Screening Copy', component: TeamScreeningCopyComponent, isEnabled: turnStart });
    }
    
}
let intervalPastTurnIn;


onUnmounted(() => {
       clearInterval(intervalPastTurnIn);
});


onMounted(async () => {
  await fetchTeam();
  await checkDeadline();
  intervalPastTurnIn = setInterval(checkDeadline, 10000); 
});
</script>

<style scoped>
.disabled-item {
  color: gray !important;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}
</style>
<!-- TODO
* lock down based on dates
-->
