import { get, post, remove } from '@/services/apiWrapper';
export const getAllUsers = async (body) => {
    const encodedSearchTerm = encodeURIComponent(body.search.trim());
    const users = await get(`/admin/users?page=${body.page}&limit=${body.limit}&q=${encodedSearchTerm}`);
    return users;

}

export const getAllRoles = async () => {
    const users = await get(`/admin/roles`);
    return users;
}

export const getUserById = async (id) => {

    const users = await get(`/admin/users/${id}`);
    return users;
}

export const checkEmail = async (body) => {
    const users = await post(`/admin/users/checkemail`, body);
    return users;
}

export const saveUser = async (id, body) => {
    const user = await post(`/admin/users/${id}/save`, body);
    return user;
}

export const createUserAPI = async (body) => {
    const user = await post(`/admin/users/createUser`, body);
    return user;
}

export const deleteUserAPI = async (id) => {
    const user = await remove(`/admin/users/${id}`);
    return user;    
}

export const resetUserPassword = async (id, password) => {
    const body = { password: password };
    const user = await post(`/admin/users/${id}/password`, body);
    return user;
}
