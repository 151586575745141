import TeamListingView from '@/views/admin/teams/TeamsListingView.vue';
import TeamEditView from '@/views/admin/teams/TeamEditView.vue';
import TeamCreateView from '@/views/admin/teams/TeamCreateView.vue';

export const adminTeamRoutes = [
{
  path: "/admin/teams",
  name: "AdminTeamListing",
  component: TeamListingView,
  meta: {guest: false}
},
{
  path: "/admin/teams/:id/edit",
  name: "AdminTeamEdit",
  component: TeamEditView,
  meta: {guest: false}
},
{
  path: "/admin/teams/create",
  name: "AdminTeamCreate",
  component: TeamCreateView,
  meta: {guest: false}
}]

