<template>
    <v-card title="Notes">
        <template v-slot:append>
            <v-row>
                <v-col cols="auto">
            <v-switch v-model="showHidden" label="Show Hidden"></v-switch>
            </v-col>
            <v-col cols="auto">
            <v-icon icon="mdi-note-plus" @click="openModal('createNote')"></v-icon>
        </v-col>
        </v-row>
        </template>
        <v-container fluid>


            <v-card-text>
                <v-row>
                    <v-col v-for="note in notes" :key="note" cols="12">
                        <v-card v-if="(!note.hide || note.hide == showHidden)" :title="note.title">
                            <template v-slot:append>
                                <v-icon color="primary" @click="openModal('editNote', note)">mdi-pencil</v-icon>
                                <v-icon color="red" @click="openModal('removeNote', note)">mdi-delete</v-icon>
                            </template>
                            <v-card-subtitle>Created By: {{ note.created_by.first_name }} {{ note.created_by.last_name
                                }}
                                <br /> {{ formatDateTime(note.created_on) }}
                            </v-card-subtitle>
                            <v-card-text>
                                {{ note.content }}
                            </v-card-text>

                        </v-card>
                    </v-col></v-row>
                <v-list v-if="notes.length == 0">
                    There are no notes for this team.
                </v-list>

            </v-card-text>
            <v-dialog v-model="addNoteModal">
                <v-card title="New Note">

                    <v-card-text>
                        <v-form @submit="addNote" v-model="isFormValid" dense>
                            <v-text-field label="Title" v-model="newNote.title" :rules="[
                                v => !!v || 'Note is required']" required></v-text-field>
                            <v-textarea label="Note" v-model="newNote.content" :rules="[
                                v => !!v || 'Note is required'
                            ]" required row-height="15" rows="3" variant="outlined" auto-grow></v-textarea>

                            <v-checkbox v-model="newNote.private" label="Private" checked> <v-tooltip activator="parent"
                                    location="end">Will not show to teams</v-tooltip></v-checkbox>
                            <v-checkbox v-model="newNote.hide" label="Hidden"> <v-tooltip activator="parent"
                                    location="end">Defaults to not showing on the notes page</v-tooltip></v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="secondary" @click="addNoteModal = false">Cancel</v-btn>
                        <v-btn color="primary" @click="addNote" :loading="isSubmitting"
                            :disabled="!isFormValid">Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="editNoteModal">
                <v-card title="Edit Note">
                    <v-card-text>
                        <v-form @submit="saveNote" v-model="isEditFormValid" dense>
                            <v-text-field label="Title" v-model="currentNote.title" :rules="[
                                v => !!v || 'Note is required']" required></v-text-field>
                            <v-textarea label="Note" v-model="currentNote.content" :rules="[
                                v => !!v || 'Note is required'
                            ]" required row-height="15" rows="3" variant="outlined" auto-grow></v-textarea>

                            <v-checkbox v-model="currentNote.private" label="Private" checked> <v-tooltip
                                    activator="parent" location="end">Will not show to teams</v-tooltip></v-checkbox>
                            <v-checkbox v-model="currentNote.hide" label="Hidden"> <v-tooltip activator="parent"
                                    location="end">Defaults to not showing on the notes page</v-tooltip></v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="secondary" @click="editNoteModal = false">Cancel</v-btn>
                        <v-btn color="primary" @click="saveNote" :loading="isEditSubmitting"
                            :disabled="!isEditFormValid">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="deleteNoteModal" max-width="500px">
                <v-card>
                    <v-card-title>Delete Note</v-card-title>
                    <v-card-text>
                        <p>Are you sure you want to remove this note?</p>
                        <p><strong>{{ currentNote.title }}</strong></p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="secondary" @click="deleteNoteModal = false">Cancel</v-btn>
                        <v-btn color="error" @click="removeNote">Remove</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-card>

</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { getTeam, getNotesForTeamYear, createNote, updateNote, deleteNote } from '@/controllers';
import { formatDateTime } from '@/helpers/dateTools'
const route = useRoute();
const teamId = route.params.teamId;
const yearId = route.params.yearId;
const isSubmitting = ref(false);
const isEditSubmitting = ref(false);
const isFormValid = ref(false);
const isEditFormValid = ref(false);
const showHidden = ref(true);
const team = ref({})
const teamCriteriaId = ref('');
const notes = ref([]);
const currentNote = ref({});
const addNoteModal = ref(false);
const editNoteModal = ref(false);
const deleteNoteModal = ref(false);
const newNote = ref({
    title: null,
    content: null,
    private: true,
    hide: false,
})

const fetchTeam = async () => {
    try {
        const teamData = await getTeam(teamId, yearId);
        team.value = teamData;
        if (teamData && teamData.teamCriteria && teamData.teamCriteria.length > 0) {
            teamCriteriaId.value = teamData.teamCriteria[0].id;
        }
        await fetchNotes();
    } catch (error) {
        console.error('Error fetching team:', error);
    }
};

const fetchNotes = async () => {
    try {
        const response = await getNotesForTeamYear(teamCriteriaId.value);
        notes.value = response.data;
    } catch (error) {
        console.error('Error fetching team:', error);
    }
};

const openModal = (type, item = {}) => {
    if (type === 'createNote') {
        newNote.value.title = null;
        newNote.value.content = null;
        newNote.value.private = true;
        newNote.value.hide = false;
        addNoteModal.value = true;
    } else if (type === 'editNote') {
        currentNote.value = item;
        editNoteModal.value = true;
    } else if (type === 'removeNote') {
        currentNote.value = item;
        deleteNoteModal.value = true;
    }
};


const addNote = async () => {
    if (isSubmitting.value) return;
    isSubmitting.value = true;
    try {
        await createNote(teamCriteriaId.value, newNote.value);
        await fetchNotes();
        addNoteModal.value = false;
    } catch (error) {
        console.log(error);
    } finally {
        isSubmitting.value = false;
    }
}


const saveNote = async () => {
    if (isEditSubmitting.value) return;
    isEditSubmitting.value = true;
    try {
        await updateNote(currentNote.value);
        await fetchNotes();
        editNoteModal.value = false;
    } catch (error) {
        console.log(error);
    } finally {
        isEditSubmitting.value = false;
    }
}

const removeNote = async () => {

    try {
        await deleteNote(currentNote.value.id);
        await fetchNotes();
        deleteNoteModal.value = false;
    } catch (error) {
        console.log(error);
    }
}



onMounted(() => {
    fetchTeam();


});
</script>

<!-- 
TODOS
* Add WYSIWYG Editor
* Add Error handling
* Add Avatar

-->