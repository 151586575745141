<template>
    <v-card>
        <v-card-text>
            <v-data-table-server :items="teams" v-model:items-per-page="pageOptions.limit" :headers="headersTeam"
                :items-length="teamCount" :loading="loadingTeam" :search="pageOptions.search" item-key="id"
                @update:options="handleTeamLimitChange">
                <template v-slot:[`item.all`]="{ item }">
                    <div v-if="item.all.length > 0">
                        <div v-for="crit in item.all" :key="crit.id">
                            {{ crit.criteria.group.group_name }}: {{ crit.criteria.name }}
                        </div>


                    </div>
                    <div v-else>&nbsp;</div>
                </template>
                <template v-slot:[`item.man`]="{ item }">
                    <div v-if="item.mandatory.length > 0">
                        <div v-for="crit in item.mandatory" :key="crit.id">
                            <b>{{ crit.criteria.group.group_name }}: {{ crit.criteria.name }}</b>
                            {{ crit.use_of_criteria }}
                            <v-divider></v-divider>
                        </div>


                    </div>
                    <div v-else>&nbsp;</div>
                </template>
                <template v-slot:[`item.opt`]="{ item }">
                    <div v-if="item.optional.length > 0">
                        <div v-for="crit in item.optional" :key="crit.id">
                            <b>{{ crit.criteria.group.group_name }}: {{ crit.criteria.name }}</b>
                            {{ crit.use_of_criteria }}
                            <v-divider></v-divider>
                        </div>


                    </div>
                    <div v-else>&nbsp;</div>
                </template>
                <template v-slot:[`item.download`]="{ item }">
                    <div
                        v-if="item.teamCriteria && item.teamCriteria[0].submissions[0].movie && item.teamCriteria[0].submissions[0].movie">

                        <v-btn variant="elevated" :color="getColor(item)" text class="mt-2" @click="downloadFile(item)"
                            :loading="isLoading(item)"
                            :disabled="item.teamCriteria[0].submissions[0].movie.is_processing == 0 ? false : true">
                            Download
                        </v-btn>

                    </div>
                    <div v-else>&nbsp;</div>
                </template>


                <template v-slot:top>
                    <v-text-field v-model="pageOptions.search" class="ma-2" density="compact"
                        placeholder="Search team..." hide-details></v-text-field>
                    <v-btn @click="exportToCSV" color="primary">Export to CSV</v-btn>
                </template>
            </v-data-table-server>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getTeamsForYearWithSubmissions } from '@/controllers';
import { useAuthStore } from '@/store';
const emit = defineEmits(['updateTab']);
const authStore = useAuthStore();
const yearId = authStore.year;
const teams = ref([]);
const loadingTeam = ref(true);
const teamCount = ref(0);
const pageOptions = ref({
    sortBy: null,
    page: 1,
    limit: 10,
    search: '',
    exclude: null,
});
const pastDeadline = ref(null);
const headersTeam = [
    { title: 'Name', key: 'name', align: 'start', sortable: true, width: '150px' },
    { title: 'Download Link', key: 'download', align: 'start', sortable: false, width: '150px' },
    { title: "All Criteria", key: 'all', align: 'start', sortable: false, width: '150px' },
    { title: "Mandatory", key: 'man', align: 'start', sortable: false, width: '150px' },
    { title: "Optional", key: 'opt', align: 'start', sortable: false, width: '150px' },
    { title: "Team Level", key: 'teamCriteria[0].teamLevel.team_level', align: 'start', sortable: false, width: '150px' },
    { title: "Title", key: 'teamCriteria[0].submissions[0].title', align: 'start', sortable: false, width: '150px' },
    { title: "Screenshot", key: 'teamCriteria[0].submissions[0].screenshot.url', align: 'start', sortable: false, width: '150px' },
    { title: "Genre", key: 'teamCriteria[0].submissions[0].genre', align: 'start', sortable: false, width: '150px' },
    { title: "Rating", key: 'teamCriteria[0].submissions[0].rating', align: 'start', sortable: false, width: '150px' },
    { title: "Running Time", key: 'teamCriteria[0].submissions[0].running_time', align: 'start', sortable: false, width: '150px' },
    { title: "Logline", key: 'teamCriteria[0].submissions[0].logline', align: 'start', sortable: false, width: '150px' },
    { title: "Contains Adult", key: 'teamCriteria[0].submissions[0].containsAdult', align: 'start', sortable: false, width: '150px' },
    { title: "Created Music", key: 'teamCriteria[0].submissions[0].createdMusic', align: 'start', sortable: false, width: '150px' },
];
const fetchTeams = async () => {
    try {
        loadingTeam.value = true;
        const teamsData = await getTeamsForYearWithSubmissions({ yearId: yearId, pageOptions: pageOptions.value });

        teamsData.data.forEach(team => {
            team.mandatory = team.teamCriteria[0].criteriaRelations.filter(criteria => criteria.mandatory == 1);
            team.optional = team.teamCriteria[0].criteriaRelations.filter(criteria => criteria.mandatory == 0);
            team.all = team.teamCriteria[0].criteriaRelations;
        });
        teams.value = teamsData.data;
        teamCount.value = teamsData.count;

    } catch (error) {
        console.error('Error fetching teams:', error);
    } finally {
        loadingTeam.value = false;
    }
};
const loads = ref([]);
const handleTeamLimitChange = (value) => {
    pageOptions.value.limit = value.itemsPerPage;
    pageOptions.value.page = value.page;
    pageOptions.value.sortBy = value.sortBy;
    pageOptions.value.search = value.search;
    fetchTeams(); // Fetch new data when limit changes
};
const isLoading = (item) => {
    const found = loads.value.includes(item.id);

    return found;
};
const getColor = (item) => {
    if (checkDeadline(item) === true) {
        return "primary";
    } else {
        return "danger";
    }
};
const getFileExtension = async (filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex !== -1 ? filename.slice(lastDotIndex + 1) : ''; // Return the extension or an empty string
};
const downloadFile = async (item) => {
    try {
        loads.value.push(item.id);
        // Fetch the file from the provided URL
        const extension = await getFileExtension(item.teamCriteria[0].submissions[0].movie.url);
        const response = await fetch(item.teamCriteria[0].submissions[0].movie.url);

        // Check if the response is okay (status 200)
        if (!response.ok) {
            throw new Error('Failed to download the file');
        }

        // Convert the response to a Blob (binary data)
        const blob = await response.blob();

        // Create a download link programmatically
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;

        // Set the filename for download (you can customize this)
        a.download = `${item.name}.${extension}`;

        // Trigger the download
        document.body.appendChild(a); // Append the element to the body
        a.click(); // Simulate a click to download the file
        document.body.removeChild(a); // Remove the element from the document

        // Revoke the object URL after the download
        window.URL.revokeObjectURL(url);
        loads.value = loads.value.filter(f => f !== item.id);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};
const checkDeadline = (item) => {
    if (item.teamCriteria[0].submissions[0].movie && item.teamCriteria[0].submissions[0].movie.created_on) {
        const movieDate = new Date(item.teamCriteria[0].submissions[0].movie.created_on);
        if (movieDate > pastDeadline.value) {
            return false;

        }
    }
    return true;
};
const exportToCSV = () => {
    const csvContent = generateCSV();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
const generateCSV = () => {
    const rows = [];
    const headers = headersTeam.map(header => header.title).join(',');
    rows.push(headers);

    teams.value.forEach(item => {
        const row = headersTeam.map(header => {
            // Handling different keys based on the column
            if (header.key === 'all') {
                return `"${item.all.map(crit => {
                    let useOf = ''
                    if (crit.use_of_criteria != null) {
                        useOf = ` (${crit.use_of_criteria.replaceAll('"', '""')})`;
                    }
                    return `${crit.criteria.group.group_name}: ${crit.criteria.name}${useOf}`
                }).join('; ')}"`;
            } else if (header.key === 'man') {
                return `"${item.mandatory.map(crit => {
                    let useOf = ''
                    if (crit.use_of_criteria != null) {
                        useOf = ` (${crit.use_of_criteria.replaceAll('"', '""')})`;
                    }
                    return `${crit.criteria.group.group_name}: ${crit.criteria.name}${useOf}`
                }).join('; ')}"`;
            } else if (header.key === 'opt') {
                return `"${item.optional.map(crit => {
                    let useOf = ''
                    if (crit.use_of_criteria != null) {
                        useOf = ` (${crit.use_of_criteria.replaceAll('"', '""')})`;
                    }
                    return `${crit.criteria.group.group_name}: ${crit.criteria.name}${useOf}`
                }).join('; ')}"`;
            } else if (header.key === 'teamCriteria[0].submissions[0].running_time') {
                return `00:${item.teamCriteria[0].submissions[0].running_time}`;
            

            } else {
                return `"${String(getValueByPath(item, header.key) ?? "").replaceAll('"', '""')}"`;
            }
        }).join(',');
        rows.push(row);
    });

    return rows.join('\n');
};

function getValueByPath(obj, path) {
    return path
        .replace(/\[(\d+)\]/g, '.$1') // Convert [index] to .index for easier splitting
        .split('.')                   // Split by the dot notation
        .reduce((acc, key) => acc && acc[key], obj); // Traverse the object
}


onMounted(() => {
    emit('updateTab', 'teams');
    pastDeadline.value = authStore.getTurnin();
});

</script>