import { getMyTeamAPI, getMySubmissionAPI, updateMySubmissionAPI, updateMyTeamAPI, getMyContestsAPI, getReleaseTypesAPI, 
    removeOptionalCriteriaAPI, saveOptionalCriteriaAPI, getSubmissionReleasesAPI, updateReleaseDonesAPI, saveCastAndCrewAPI} from "@/services";

export async function getMyTeam(yearId) {
    try {
        const teams = await getMyTeamAPI(yearId);
        return teams;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team")
    }
}
export async function removeOptionalCriteria(critId) {
    try {
        const crit = await removeOptionalCriteriaAPI(critId);
        return crit;
    }catch (error) {
        console.log(error);
        throw ("Can't find Team")
    }
}

export async function saveOptionalCriteria(yearId, critIds) {
    try {
        const crit = await saveOptionalCriteriaAPI(yearId, critIds);
        return crit;
    }catch (error) {
        console.log(error);
        throw ("Can't find Team")
    }
}

export async function getMySubmission(yearId) {
    try {
        const teams = await getMySubmissionAPI(yearId);
        return teams;
    } catch (error) {
        console.log(error);
        throw ("Can't find Submission")
    }
}

export async function updateMySubmission(yearId, submission, manditory, optional) {
    try {
        const teams = await updateMySubmissionAPI(yearId,submission,  manditory, optional);
        return teams;
    } catch (error) {
        console.log(error);
        throw ("Can't save submission")
    }    
}

export async function updateMyTeam(yearId, team) {
    try {
        const teams = await updateMyTeamAPI(yearId, team);
        return teams;
    } catch (error) {
        console.log(error);
        throw ("Can't save team")
    }    
}

export async function getMyContests(yearId) {
    try {
        const contests = await getMyContestsAPI(yearId);
        return contests;
    } catch (error) {
        console.log(error);
        throw ("Can't save team")
    }    
}

export async function getReleaseTypes() {
    try {
        const types = await getReleaseTypesAPI();
        return types;
    } catch (error) {
        console.log(error);
        throw ("Can't get types")
    }   
}

export async function getSubmissionReleases(submissionId) {
    try {
        const releases = await getSubmissionReleasesAPI(submissionId);
        return releases;
    } catch (error) {
        console.log(error);
        throw ("Can't get releases")
    }   
}

export async function updateReleaseDones(submissionId, value) {
    try {
        const releases = await updateReleaseDonesAPI(submissionId, value);
        return releases;
    } catch (error) {
        console.log(error);
        throw ("Can't get releases")
    }   
}

export async function saveCastAndCrew(submissionId, value) {
    try {
        const releases = await saveCastAndCrewAPI(submissionId, value);
        return releases;
    } catch (error) {
        console.log(error);
        throw ("Can't get releases")
    }   
}