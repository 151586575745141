import {getNotesForTeamYearAPI, getNotesForTeamYearCountAPI,createNoteAPI,updateNoteAPI,deleteNoteAPI } from "@/services";

export async function getNotesForTeamYear(teamCriteriaId) {
    try {
        const notes = await getNotesForTeamYearAPI(teamCriteriaId);
        return notes;
    } catch (error) {
        console.log(error);
        throw ("Cant't get notes")
    }
}

export async function getNotesForTeamYearCount(teamId, yearId) {
    try {
        const notes = await getNotesForTeamYearCountAPI(teamId, yearId);
        return notes;
    } catch (error) {
        console.log(error);
        throw ("Cant't get note count")
    }
}

export async function createNote(teamCriteriaId, body) {
    try {
        const notes = await createNoteAPI(teamCriteriaId, body);
        return notes;
    } catch (error) {
        console.log(error);
        throw ("Cant't save notes")
    }
}

export async function updateNote(body) {
    try {
        const notes = await updateNoteAPI(body);
        return notes;
    } catch (error) {
        console.log(error);
        throw ("Cant't save notes")
    }
}


export async function deleteNote(noteId) {
    try {
        const notes = await deleteNoteAPI(noteId);
        return notes;
    } catch (error) {
        console.log(error);
        throw ("Cant't save notes")
    }
}