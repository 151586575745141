import {
    getAllYears, getYearById, saveYear, createYearAPI, deleteYearAPI, getTeamsForYearAPI,
    getCriteriaForYearAPI, createCriteriaAPI, updateCriteriaAPI, deleteCriteriaAPI,
    removeTeamFromYearAPI, addTeamsToYearAPI, getTeamYearAPI, getYearInfoAPI, createCriteriaGroupAPI,
     getCriteriaGroupsForYearAPI, getNextCriteriaNumberAPI, getTeamsCriteriaAPI, assignTeamsCriteriaAPI,
     getTeamsTermsForYearAPI, getTeamsForYearWithCheckinAPI, getTeamsForYearWithSubmissionsAPI
} from "@/services";

export async function getYears(limit, page, search) {
    try {
        const years = await getAllYears(limit, page, search);
        return years;
    } catch (error) {
        console.log(error);
        throw ("Cant't get years")
    }
}

export async function getYear(id) {
    try {
        const years = await getYearById(id);
        return years;
    } catch (error) {
        console.log(error);
        throw ("Can't find Year")
    }
}

export async function getYearInfo(id) {
    try {
        const years = await getYearInfoAPI(id);
        return years;
    } catch (error) {
        console.log(error);
        throw ("Can't find Year")
    }
}

export async function updateYear(id, data) {
    try {
        const response = await saveYear(id, data);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function createYear(id, data) {
    try {
        const response = await createYearAPI(id, data);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function deleteYear(yearid) {
    try {
        const response = await deleteYearAPI(yearid);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}


export async function getCriteriaForYear(yearid) {
    try {
        const response = await getCriteriaForYearAPI(yearid);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}


export async function getCriteriaGroupsForYear(yearid) {
    try {
        const response = await getCriteriaGroupsForYearAPI(yearid);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function getTeamsCriteria(yearId, teamId) {
    try {
        const response = await getTeamsCriteriaAPI(yearId, teamId);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}
export async function assignTeamsCriteria(yearId, teamId, selectedCriteriaIds ){
    try {
        const response = await assignTeamsCriteriaAPI(yearId, teamId, selectedCriteriaIds);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function getTeamsForYear(body) {
    try {
        const response = await getTeamsForYearAPI(body);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}
export async function getTeamsForYearWithCheckin(body) {
    try {
        const response = await getTeamsForYearWithCheckinAPI(body);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}


export async function getTeamsForYearWithSubmissions(body) {
    try {
        const response = await getTeamsForYearWithSubmissionsAPI(body);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function getTeamsTermsForYear(yearId) {
    try {
        const response = await getTeamsTermsForYearAPI(yearId);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function createCriteria(yearid, data, file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('number', data.number);
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('max_count', data.max_count);
        formData.append('criteriagroupId', data.criteriagroup);
        const response = await createCriteriaAPI(yearid, formData);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function createCriteriaGroup(yearid, data) {
    try {
        const response = await createCriteriaGroupAPI(yearid, data);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function updateCriteria(yearid, data, file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('number', data.number);
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('max_count', data.max_count);
        formData.append('criteriagroupId', data.criteriagroup);
        const response = await updateCriteriaAPI(yearid, data.id, formData);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}
export async function deleteCriteria(yearid, criteriaID) {
    try {
        const response = await deleteCriteriaAPI(yearid, criteriaID);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}


export async function removeTeamFromYear(yearid, teamId) {
    try {
        const response = await removeTeamFromYearAPI(yearid, teamId);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function addTeamsToYear(yearid, teams) {
    try {
        const ids = [];
        teams.forEach(obj => ids.push(obj));
        const response = await addTeamsToYearAPI(yearid, { id: ids });
        return response;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function getTeamYear(yearId, teamId) {
    try {

        const response = await getTeamYearAPI(yearId, teamId);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function getNextCriteriaNumber(yearId) {
    try {

        const response = await getNextCriteriaNumberAPI(yearId);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}