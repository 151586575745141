import { get, post } from '@/services/apiWrapper';
export const getMySelf = async () => {
    const user = get("/users/myself");
    return user;
}

export const updateMyProfileAPI = async (user) => {
    const result = post('/users/myself', user)
    return result;
}

export const resetMyPasswordAPI = async (password) => {
    const body = { password: password };
    const result = post('/users/password', body);
    return result;
}