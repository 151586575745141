<template>
  <v-container>
    <div>
      <!-- Create Criteria Button -->
      <v-row>
        <v-col>
          <v-btn color="primary" @click="openModal('createGroup')">Create Criteria Group</v-btn>
        </v-col><v-col>
          <v-btn color="primary" @click="openModal('create')">Create Criteria</v-btn>
        </v-col>
      </v-row>
      <!-- Criteria Table -->
      <v-table :items="criterias" class="mt-3">
        <thead>
          <tr>
            <th>Criteria #</th>
            <th>Group</th>
            <th>Name</th>
            <th>Description</th>
            <th>Max</th>
            <th>Image</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="criteria in criterias" :key="criteria.id">
            <td>{{ criteria.number }}</td>
            <td>{{ criteria.group.group_name }}</td>
            <td>{{ criteria.name }}</td>
            <td>{{ criteria.description }}</td>
            <td>{{ criteria.max_count }}</td>
            <td><v-img v-if="criteria.thubmnail && criteria.thubmnail.url" :src="criteria.thubmnail.url"></v-img></td>
            <td>
              <v-btn color="primary" @click="openModal('edit', criteria)">Edit</v-btn>
            </td>
            <td>
              <v-btn color="error" @click="openModal('delete', criteria)">Delete</v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>

      <!-- Modals for Create, Edit, Delete Criteria -->
      <v-dialog v-model="isCreateModalOpen" max-width="500px">
        <v-card>
          <v-card-title>Create Criteria</v-card-title>
          <v-card-text>
            <v-form ref="createForm" @submit.prevent="newCriteria">
              <v-text-field label="Criteria #" v-model="newTempCriteria.number" type="number"
                :rules="[rules.required]" />
              <v-text-field label="Name" v-model="newTempCriteria.name" :rules="[rules.required]" />
              <v-textarea label="Description" v-model="newTempCriteria.description" />
              <v-text-field label="Max" v-model="newTempCriteria.max_count" type="number" />
              <v-select label="Criteria Group" v-model="newTempCriteria.criteriagroup" :items="criteriasGroups"
                item-value="id" item-title="group_name" :rules="[
                  v => !!v || 'Release Type is required'
                ]" outlined></v-select>
              <v-file-input label="Choose a file" v-model="criteriaImage"></v-file-input>
              <v-btn type="submit" color="primary">Create</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isEditModalOpen" max-width="500px">
        <v-card>
          <v-card-title>Edit Criteria</v-card-title>
          <v-card-text>
            <v-form ref="editForm" @submit.prevent="updateYearCriteria">
              <v-text-field label="Criteria #" v-model="currentCriteria.number" type="number"
                :rules="[rules.required]" />
              <v-text-field label="Name" v-model="currentCriteria.name" :rules="[rules.required]" />
              <v-textarea label="Description" v-model="currentCriteria.description" />
              <v-text-field label="Max" v-model="currentCriteria.max_count" type="number" />
              <v-select label="Criteria Group" v-model="currentCriteria.group" :items="criteriasGroups" item-value="id"
                item-title="group_name" :rules="[
                  v => !!v || 'Release Type is required'
                ]" outlined></v-select>
              <v-file-input label="Replace image" v-model="criteriaImage"></v-file-input>
              <v-btn type="submit" color="primary">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isDeleteModalOpen" max-width="500px">
        <v-card>
          <v-card-title>Delete Criteria</v-card-title>
          <v-card-text>
            <p>Are you sure you want to delete this criteria?</p>
            <p><strong>{{ currentCriteria.name }}</strong></p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="isDeleteModalOpen = false">Cancel</v-btn>
            <v-btn color="error" @click="deleteYearCriteria">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isCreateGroupModalOpen" max-width="500px">
        <v-card>
          <v-card-title>Create Criteria Group</v-card-title>
          <v-card-text>
            <v-form ref="createGroupForm" @submit.prevent="newCriteriaGroup">
              <v-text-field label="Group Name" v-model="newTempCriteriaGroup.name" :rules="[rules.required]" />
              <v-btn type="submit" color="primary">Create</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {
  getCriteriaForYear, createCriteria, updateCriteria,
  deleteCriteria, createCriteriaGroup, getCriteriaGroupsForYear,
  getNextCriteriaNumber
} from '@/controllers';
import { useAuthStore } from '@/store';

const emit = defineEmits(['updateTab']);

const authStore = useAuthStore();
const yearId = authStore.year;
const criterias = ref([]);
const criteriasGroups = ref([]);
const newTempCriteria = ref({ number: '', name: '', description: '', max_count: '' });
const newTempCriteriaGroup = ref({ name: '' });
const currentCriteria = ref({});
const isCreateModalOpen = ref(false);
const isCreateGroupModalOpen = ref(false);
const isEditModalOpen = ref(false);
const isDeleteModalOpen = ref(false);
const loadingCriteria = ref(true);
const loadingCriteriaGroup = ref(true);
const criteriaImage = ref(null);

const rules = {
  required: v => !!v || 'Required.'
};

const fetchCriterias = async () => {
  try {
    loadingCriteria.value = true;
    const response = await getCriteriaForYear(yearId);
    criterias.value = response;

  } catch (error) {
    console.error('Error fetching criterias:', error);
  } finally {
    loadingCriteria.value = false;
  }
};

const fetchCriteriaGroups = async () => {
  try {
    loadingCriteriaGroup.value = true;
    const response = await getCriteriaGroupsForYear(yearId);
    criteriasGroups.value = response;
  } catch (error) {
    console.error('Error fetching criterias groups:', error);
  } finally {
    loadingCriteriaGroup.value = false;
  }
};

const openModal = async (type, criteria = {}) => {
  currentCriteria.value = criteria;
  if (type === 'create') {
    isCreateModalOpen.value = true;
    const responseNum = await getNextCriteriaNumber(yearId);
    newTempCriteria.value.number = responseNum.number + 1;
  } else if (type === 'edit') {
    isEditModalOpen.value = true;
  } else if (type === 'delete') {
    isDeleteModalOpen.value = true;
  } else if (type === 'createGroup') {
    isCreateGroupModalOpen.value = true;
  }
};

const newCriteria = async () => {
  try {
    await createCriteria(yearId, newTempCriteria.value, criteriaImage.value);
    fetchCriterias();
    newTempCriteria.value = { number: '', name: '', description: '', max_count: '', criteriagroup: '' };
    criteriaImage.value = null;
    isCreateModalOpen.value = false;
  } catch (error) {
    console.error('Error creating criteria:', error);
  }
};

const newCriteriaGroup = async () => {
  try {
    await createCriteriaGroup(yearId, newTempCriteriaGroup.value);
    fetchCriteriaGroups();
    newTempCriteriaGroup.value = { name: '' };
    isCreateGroupModalOpen.value = false;
  } catch (error) {
    console.error('Error creating criteria:', error);
  }
};

const updateYearCriteria = async () => {
  try {
    await updateCriteria(yearId, currentCriteria.value, criteriaImage.value);
    criteriaImage.value = null;
    fetchCriterias();
    isEditModalOpen.value = false;
  } catch (error) {
    console.error('Error updating criteria:', error);
  }
};

const deleteYearCriteria = async () => {
  try {
    await deleteCriteria(yearId, currentCriteria.value.id);
    fetchCriterias();
    isDeleteModalOpen.value = false;
  } catch (error) {
    console.error('Error deleting criteria:', error);
  }
};

onMounted(() => {
  fetchCriterias();
  fetchCriteriaGroups();
  emit('updateTab', 'criteria');
});
</script>

<style scoped></style>