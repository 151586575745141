<template>
    <v-container>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-title>
                    Have Terms
                    <v-badge color="primary" :content="teamsTerms.have.length" class="ml-3"></v-badge>
                </v-expansion-panel-title>

                <v-expansion-panel-text>
                    <v-list>
                        <v-list-item v-for="(terms, idx) in teamsTerms.have" :key="idx">
                            <v-row>
                                <v-col>
                            <v-list-item-title>{{ terms.team.name }}</v-list-item-title>
                        </v-col>
                        <v-col>
                            <v-list-item-action><a :href="terms.terms.url" download>
                                    <v-btn v-if="terms.terms" text class="mt-2">
                                        Download
                                    </v-btn></a></v-list-item-action>
                                </v-col>
                                </v-row>
                        </v-list-item>
                    </v-list>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-title>
                    Need Terms
                    <v-badge color="primary" :content="teamsTerms.need.length" class="ml-3"></v-badge>
                </v-expansion-panel-title>

                <v-expansion-panel-text>
                    <v-list>
                        <v-list-item v-for="(terms, idx) in teamsTerms.need" :key="idx">
                            <v-row>
                                <v-col>
                            <v-list-item-title>{{ terms.team.name }}</v-list-item-title>
                        </v-col>
                        <v-col>{{ terms.team.team_members[0].user.email_address }}</v-col>
                        </v-row>
                        </v-list-item>
                    </v-list>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

    </v-container>
</template>
<script setup>
import { ref, onMounted, } from 'vue';
import { getTeamsTermsForYear } from '@/controllers';
import { useAuthStore } from '@/store';

const authStore = useAuthStore();
const yearId = authStore.year;
const loadingTerms = ref(true);
const teamsTerms = ref({ have: [], need: [] });

const fetchTeamTerms = async () => {
    try {
        const results = await getTeamsTermsForYear(yearId);
        teamsTerms.value.have = results.filter(team => team.terms != null);
        teamsTerms.value.need = results.filter(team => team.terms == null);
        loadingTerms.value = false;
    } catch (error) {
        console.log(error);
    }

}

onMounted(() => {
    fetchTeamTerms();

});
</script>