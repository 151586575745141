<template>
  <v-container>
    <v-card>
      <v-card-title>Create User</v-card-title>
      <v-form @submit="submitForm" v-model="isFormValid">
        <v-card-text>
          <v-alert v-if="apiError" type="error" class="mt-3">
            {{ apiError }}
          </v-alert>
          <v-row>
            <!-- Existing form fields -->
            <v-col cols="12" md="6">
              <v-text-field
                label="First Name"
                v-model="form.first_name"
                :rules="[
                  v => !!v || 'First Name is required'
                ]"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Last Name"
                v-model="form.last_name"
                :rules="[
                  v => !!v || 'Last Name is required'
                ]"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Email Address"
                v-model="form.email"
                :rules="[
                  v => !!v || 'Email is required',
                  v => /.+@.+\..+/.test(v) || 'Email must be valid'
                ]"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Password"
                v-model="form.password"
                :rules="[
                  v => !!v || 'Password is required'
                ]"
                outlined
                type="password"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Confirm Password"
                v-model="form.confirmpassword"
                :rules="[
                  v => !!v || 'Confirm Password is required',
                  v => v === form.password || 'Passwords must match'
                ]"
                outlined
                type="password"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Phone Number"
                v-model="form.phone_number"
                :rules="[
                  v => !v || /^\d+$/.test(v) || 'Phone number is invalid'
                ]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                label="Under 18"
                v-model="form.is_under_18"
                true-value="1"
                false-value="0"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Role"
                v-model="form.role"
                :items="roles"
                item-value="id"
                item-title="role_name"
                :rules="[
                  v => !!v || 'Role is required'
                ]"
                outlined
              ></v-select>
            </v-col>

           
            <v-col cols="12" md="6">
              <v-text-field
                label="Street Address"
                v-model="form.street_address"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="City"
                v-model="form.city"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="State"
                v-model="form.state"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Postal Code"
                v-model="form.postal_code"
                :rules="[
                
                  v => /^\d{5}(-\d{4})?$/.test(v) || 'Postal Code must be valid'
                ]"
                outlined
                
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Country"
                v-model="form.country"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn 
              color="primary"
              :loading="isSubmitting"
              :disabled="!isFormValid"
            @click="submitForm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getRoles, createUser } from '@/controllers';

const router = useRouter();
const apiError = ref('');
const isFormValid = ref(false);
const roles = ref([]);
const form = ref({
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  password: '',
  confirmpassword: '',
  is_under_18: false,
  role: null, // Default role to null to indicate no selection
  street_address: '', // New address field
  city: '', // New address field
  state: '', // New address field
  postal_code: '', // New address field
  country: '' // New address field
});
const isSubmitting = ref(false);

// Fetch roles and set default selected role
const fetchRoles = async () => {
  try {
    const roleData = await getRoles();   
    roles.value = roleData;

    // Set default role to the first role if available
    if (roles.value.length > 0) {
      form.value.role = roles.value[0].id;
    }
  } catch (error) {
    console.error('Error fetching roles:', error);
  }
};

// Form submission handler
const submitForm = async () => {

  if (isSubmitting.value) return;
  isSubmitting.value = true;

  try {
    const response = await createUser({ ...form.value });
    if (response && response.id) {
      router.push({ name: 'AdminUserListing' });
    }
  } catch (error) {
    apiError.value = error.message;
  } finally {
    isSubmitting.value = false;
  }
};

// Fetch roles on component mount
onMounted(() => {
  fetchRoles();
});
</script>

<style scoped>
</style>
