<template>
  <v-container>
    <v-breadcrumbs>
      <v-breadcrumbs-item :to="{ name: 'AdminTeamListing' }">Back</v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-container>
      <v-card>
        <v-card-title>Create Team</v-card-title>
        <v-form ref="form" @submit.prevent="onSubmit" v-model="isFormValid">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Team Name"
                  v-model="team.name"
                  outlined
                  required
                  :rules="nameRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Website"
                  v-model="team.website"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Instagram"
                  v-model="team.instagram"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Facebook"
                  v-model="team.facebook"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Twitter"
                  v-model="team.twitter"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Bio"
                  v-model="team.bio"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="YouTube"
                  v-model="team.youtube"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="vimeo"
                  v-model="team.vimeo"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :loading="isSubmitting"
              :disabled="!isFormValid"
              @click="onSubmit"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
  </v-container>
</template>

<script setup>
import { ref } from 'vue';
import { createTeam } from '@/controllers';
import { useRouter } from 'vue-router';

const router = useRouter();

const team = ref({
  name: '',
  website: '',
  instagram: '',
  facebook: '',
  twitter: '',
  bio: '',
  vimeo: '',
  youtube: ''
});
const isSubmitting = ref(false);
const form = ref(null);
const isFormValid = ref(false);

const nameRules = [
  v => !!v || 'Team name is required',
];

const onSubmit = async () => {
  const isValid = await form.value.validate();

  if (!isValid) {
    return;
  }

  isSubmitting.value = true;
  try {
    const response = await createTeam(team.value);
    if (response && response.id) {
      router.push({ name: "AdminTeamListing" });
    }
  } catch (error) {
    console.error(error);
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<style scoped>
</style>
<!-- 
TODO's

* Reformat Page so it looks nicer
* change Bio to text field with markdown
* add validation for social pages


-->