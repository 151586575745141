<template>
    <v-card>
        <v-container fluid>
            <v-row dense>
                <v-col>
                    <v-card title="Team Info">
                        <template v-slot:append>
                            <router-link :to="{ name: 'AdminTeamEdit', params: { id: teamId } }"><v-icon
                                    icon="mdi-pencil"></v-icon>
                                <v-tooltip activator="parent" location="end">Edit Team</v-tooltip></router-link>
                        </template>
                        <v-card-text>
                            <v-table>
                                <tbody>

                                    <tr v-if="team.bio">
                                        <td>Bio:</td>
                                        <td>{{ team.bio }}</td>
                                    </tr>
                                    <tr v-if="team.website">
                                        <td>Website:</td>
                                        <td><a :href='`{{ team.website }}`'>{{ team.website }}</a></td>
                                    </tr>
                                    <tr v-if="team.facebook">
                                        <td>Facebook:</td>
                                        <td><a :href='`https://www.facebook.com/${team.facebook}`'>@{{ team.facebook }}</a></td>
                                    </tr>
                                    <tr v-if="team.twitter">
                                        <td>X:</td>
                                        <td><a :href='`https://www.x.com/${team.twitter}`'>@{{ team.twitter }}</a></td>
                                    </tr>
                                    <tr v-if="team.instagram">
                                        <td>Instagram</td>
                                        <td><a :href='`https://www.instagram.com/${team.instagram}`'>@{{ team.instagram }}</a></td>
                                    </tr>
                                    <tr v-if="team.youtube">
                                        <td>YouTube</td>
                                        <td><a :href='`https://www.youtube.com/@${team.youtube}`'>@{{ team.youtube }}</a></td>
                                    </tr>
                                    <tr v-if="team.vimeo">
                                        <td>Vimeo</td>
                                        <td><a :href='`https://www.vimeo.com/${team.vimeo }`'>@{{ team.vimeo }}</a></td>
                                    </tr>
                                    <tr>
                                        <td>Team Level:</td>
                                        <td>
                                            <div style="display: flex; align-items: center;">
                                                <v-row justify="end">
                                                    <v-col cols="auto">
                                                        <v-list-item v-if="!isTeamLevelEdit">{{ selectedLevel.team_level
                                                            }}</v-list-item>

                                                        <v-select v-if="isTeamLevelEdit" :disabled="!isTeamLevelEdit"
                                                            label="Level" v-model="selectedLevel" :items="teamLevels"
                                                            item-value="id" item-title="team_level" outlined
                                                            @update:modelValue="onLevelChange"></v-select>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-icon @click="isTeamLevelEdit = !isTeamLevelEdit"
                                                            icon="mdi-pencil"></v-icon>
                                                        <v-tooltip activator="parent" location="end">Edit Team
                                                            Level</v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </v-table>

                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card title="Team Lead">
                        <template v-slot:append>
                            <v-icon @click="openModal('addTeamLeader')" icon="mdi-account-plus"></v-icon>
                            <v-tooltip activator="parent" location="end">Update Team Leadl</v-tooltip>
                        </template>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-list v-if="teamLeader && teamLeader.user">
                                        <v-list-item :prepend-avatar="avatar">
                                            <v-list-item-title><router-link
                                                    :to="{ name: 'AdminEditDetail', params: { id: teamLeader.user.id } }">{{
                                                        teamLeader.user.first_name }}
                                                    {{ teamLeader.user.last_name }}</router-link></v-list-item-title>
                                            <v-list-item-subtitle>{{ teamLeader.user.email_address
                                                }}</v-list-item-subtitle>
                                        </v-list-item>
                                        <v-list-item>Phone: {{ teamLeader.user.phone_number }}</v-list-item>
                                        <v-list-item>Address: <v-row><v-col>{{ teamLeader.user.street_address }} <br />
                                                    {{
                                                        teamLeader.user.city
                                                    }}, {{ teamLeader.user.state }} {{ teamLeader.user.postal_code }}
                                                    <br /> {{
                                                        teamLeader.user.country }}</v-col></v-row> </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col>
                                    <v-col cols="auto" class="d-flex flex-column align-center justify-center">
                                        <v-avatar v-if="teamLeader && teamLeader.user && teamLeader.user.is_under_18"
                                            size="125">
                                            <v-icon icon="mdi-account-child-circle" size="80" color="red"></v-icon>
                                        </v-avatar>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card title="Team Members">
                        <template v-slot:append>
                            <v-icon @click="openModal('addTeamMember')" icon="mdi-account-plus"></v-icon>
                            <v-tooltip activator="parent" location="end">Add Team Member</v-tooltip>
                        </template>

                        <v-card-text>
                            <v-data-table-server :headers="teamMembers.headers" :items="teamMembers.data"
                                :items-per-page="5" item-key="id" :items-length="teamMembers.count"
                                @update:options="handleTeamMemberChange" v-model="teamMembers.selected"
                                :search="teamMembers.search">
                                <template v-slot:[`item.action`]="{ item }">
                                    <v-row justify="end">

                                        <v-col cols="auto">
                                            <div @click="openModal('removeTeamMember', item)">
                                                <v-icon color="error">mdi-delete </v-icon>
                                                <v-tooltip activator="parent" location="end">Remove Team
                                                    Member</v-tooltip>
                                            </div>
                                        </v-col></v-row>
                                </template>
                                <template v-slot:top>
                                    <v-text-field v-model="teamMembers.search" class="ma-2" density="compact"
                                        placeholder="Search team members..." hide-details></v-text-field>
                                </template>
                            </v-data-table-server>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>
        <v-dialog v-model="isAddTeamModalOpen" max-width="700px">
            <v-card>
                <v-card-title>Add Team Member</v-card-title>
                <v-card-text>
                    <v-data-table-server :headers="addTeamMember.headers" :items="addTeamMember.data"
                        :items-per-page="5" item-key="id" show-select :items-length="addTeamMember.count"
                        @update:options="handleAddTeamMemberChange" v-model="addTeamMember.selected"
                        :search="addTeamMember.search">
                        <template v-slot:top>
                            <v-text-field v-model="addTeamMember.search" class="ma-2" density="compact"
                                placeholder="Search team members..." hide-details></v-text-field>
                        </template>
                    </v-data-table-server>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" @click="isAddTeamModalOpen = false">Cancel</v-btn>
                    <v-btn color="primary" @click="addTeamsMembers">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isTeamLeadModalOpen" max-width="700px">
            <v-card>
                <v-card-title>Update Team Lead</v-card-title>
                <v-card-text>
                    <v-autocomplete v-model="possibleUser.selected" :items="possibleUser.data" item-value="id"
                        label="Users" no-filter hide-selected clearable :search-input="search" @update:search="search">
                        <template v-slot:selection="{ item }">
                            <v-list-item>
                                <v-list-item-title>{{ item.raw.first_name }} {{ item.raw.last_name }}: {{
                                    item.raw.email_address }}</v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :title="`${item.raw.first_name} ${item.raw.last_name}`">
                                <v-list-item-title>{{ item.raw.first_name }} {{ item.raw.last_name
                                    }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.raw.email_address }}</v-list-item-subtitle>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" @click="isTeamLeadModalOpen = false">Cancel</v-btn>
                    <v-btn color="primary" @click="updateTeamLeader">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Remove Team Modal -->
        <v-dialog v-model="isRemoveTeamMemberModalOpen" max-width="500px">
            <v-card>
                <v-card-title>Remove Team Member</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to remove this team member?</p>
                    <p><strong>{{ currentTeamMember.user.first_name }} {{ currentTeamMember.user.last_name }} </strong>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" @click="isRemoveTeamMemberModalOpen = false">Cancel</v-btn>
                    <v-btn color="error" @click="deleteTeamMember">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { getTeam, getTeamMembers, getPossibleUsers, addTeamMembersToYear, getTeamLead, updateTeamLead, getTeamLevels, changeTeamLevel, removeTeamMemberFromYear } from '@/controllers';
const route = useRoute();
const teamId = route.params.teamId;
const yearId = route.params.yearId;
const team = ref({});
const teamLeader = ref({});
const teamLevels = ref([]);
const currentTeamLead = ref({});
const avatar = "/images/blankProfile.webp";

const currentTeamMember = ref({});
const isAddTeamModalOpen = ref(false);
const isTeamLeadModalOpen = ref(false);
const isRemoveTeamMemberModalOpen = ref(false);
const isTeamLevelEdit = ref(false);
const selectedLevel = ref({
    id: null,
    team_level: ''
});
const possibleUser = ref({
    selected: [],
    data: [],
    loading: false
});

const addTeamMember = ref({
    limit: 0,
    page: 1,
    search: '',
    data: [],
    count: 0,
    loading: false,
    exclude: { year: yearId, team: teamId },
    selected: [],
    headers: [
        { title: 'First Name', key: 'first_name', align: 'start', sortable: true },
        { title: 'Last Name', key: 'last_name', align: 'start', sortable: true },
        { title: 'Email', key: 'email_address', sortable: true }
    ]
});

const teamMembers = ref({
    limit: 0,
    page: 1,
    search: '',
    data: [],
    count: 0,
    loading: false,
    selected: [],
    headers: [
        { title: 'First Name', key: 'user.first_name', align: 'start', sortable: true },
        { title: 'Last Name', key: 'user.last_name', align: 'start', sortable: true },
        { title: 'Email', key: 'user.email_address', sortable: true },
        { title: '', key: 'action', sortable: false }
    ]
});


const fetchTeam = async () => {
    try {
        const teamData = await getTeam(teamId, yearId);
        team.value = teamData;
        selectedLevel.value = teamData.teamCriteria[0].teamLevel;
    } catch (error) {
        console.error('Error fetching team:', error);
    }
};
const fetchTeamLead = async () => {
    try {
        const user = await getTeamLead(teamId, yearId);
        teamLeader.value = user.data[0];
        possibleUser.value.selected = user.data[0].user ? user.data[0].user : {} ;
        currentTeamLead.value =  user.data[0].user ? user.data[0].user : {} ;
    } catch (error) {
        console.error('Error fetching team leader:', error);
    }
};
const fetchTeamLevels = async () => {
    try {
        const levels = await getTeamLevels(teamId, yearId);
        teamLevels.value = levels;
    } catch (error) {
        console.error('Error fetching team levels:', error);
    }
};

const handleAddTeamMemberChange = (value) => {
    addTeamMember.value.limit = value.itemsPerPage;
    addTeamMember.value.page = value.page;
    addTeamMember.value.sortBy = value.sortBy;
    addTeamMember.value.search = value.search;

    fetchFilterTeamMembers();
};

const handleTeamMemberChange = (value) => {
    teamMembers.value.limit = value.itemsPerPage;
    teamMembers.value.page = value.page;
    teamMembers.value.sortBy = value.sortBy;
    teamMembers.value.search = value.search;

    fetchTeamMembersForYear();
};
const fetchTeamMembersForYear = async () => {
    try {
        teamMembers.value.loading = true;
        const teamData = await getTeamMembers({ yearId: yearId, teamId: teamId, pageOptions: teamMembers.value });
        teamMembers.value.data = teamData.data;
        teamMembers.value.count = teamData.count;
    } catch (error) {
        console.error('Error fetching team members:', error);
    } finally {
        teamMembers.value.loading = false;
    }
};

const fetchFilterTeamMembers = async () => {
    try {
        addTeamMember.value.loading = true;
        const teamsData = await getPossibleUsers({ pageOptions: addTeamMember.value });
        addTeamMember.value.data = teamsData.data;
        addTeamMember.value.count = teamsData.count;
        addTeamMember.value.selected = [];
    } catch (error) {
        console.error('Error fetching teams:', error);
    } finally {
        addTeamMember.value.loading = false;
    }
};

const fetchPossibleUsers = async () => {
    try {
        possibleUser.value.loading = true;
        const teamsData = await getPossibleUsers({ pageOptions: possibleUser.value });
        possibleUser.value.data = teamsData.data;
    } catch (error) {
        console.error('Error fetching teams:', error);
    } finally {
        possibleUser.value.loading = false;
    }
};

const addTeamsMembers = async () => {
    try {
        await addTeamMembersToYear(teamId, yearId, addTeamMember.value.selected);
        fetchTeamMembersForYear();
        addTeamMember.value.selected.value = [];
        isAddTeamModalOpen.value = false;
    } catch (error) {
        console.error('Error adding teams members:', error);
    }
}

const updateTeamLeader = async () => {
    try {
        await updateTeamLead(teamId, yearId, possibleUser.value.selected);
        fetchTeamLead();
        possibleUser.value.selected = [];
        isTeamLeadModalOpen.value = false;
    } catch (error) {
        console.error('Error adding teams members:', error);
    }
}


const onLevelChange = async (newLevel) => {

    try {
        const results = await changeTeamLevel(yearId, teamId, newLevel);
        selectedLevel.value = results.teamLevel;
        isTeamLevelEdit.value = false;

    } catch (error) {
        console.error('Error adding teams members:', error);
    }

}

const openModal = (type, item = {}) => {
    if (type === 'addTeamMember') {
        addTeamMember.value.search = '';
        addTeamMember.value.selected = [];
        fetchFilterTeamMembers();
        isAddTeamModalOpen.value = true;
    } else if (type === 'removeTeamMember') {
        currentTeamMember.value = item;
        isRemoveTeamMemberModalOpen.value = true;
    } else if (type === 'addTeamLeader') {
        possibleUser.value.selected = currentTeamLead.value;
        fetchPossibleUsers();
        isTeamLeadModalOpen.value = true;
    }
};
const deleteTeamMember = async () => {
    try {
        await removeTeamMemberFromYear({ yearId: yearId, teamId: teamId, removeId: currentTeamMember.value.id });
        await fetchTeamMembersForYear();
        isRemoveTeamMemberModalOpen.value = false;
    } catch (error) {
        console.error('Error removing team:', error);
    }
};

const search = async (value) => {
    possibleUser.value.search = value;
    await fetchPossibleUsers();
}

onMounted(() => {
    fetchTeam();
    fetchTeamLead();
    fetchTeamLevels();
});
</script>