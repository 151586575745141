<template>
  <v-container>
    <v-breadcrumbs>
      <v-breadcrumbs-item @click="router.go(-1);">Back</v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-container>
      <v-card>
        <v-card-title>Edit Year</v-card-title>
        <v-form v-model="isFormValid" @submit.prevent="onSubmit">

          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="year.year" label="Year" type="number" :rules="[
                  v => !!v || 'Year is required'
                ]"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.start_date" label="Start Date" type="date" :rules="[
                  v => !!v || 'Start Date is required'
                ]" required></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.early_bird_reg" label="Early Bird Date" type="date"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.end_date" label="End Date" type="date" :rules="[
                  v => !!v || 'End Date is required'
                ]" required></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.launch_location" label="Launch Location"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.launch_address" label="Launch Address"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.launch_date" label="Launch Date" type="datetime-local"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.turn_start" label="Turn-In Start Date" type="datetime-local"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.turn_deadline" label="Turn-In Deadline" type="datetime-local"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.theme" label="Theme"></v-text-field>

              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.friday_block1" label="Friday Block 1" type="datetime-local"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.friday_block2" label="Friday Block 2" type="datetime-local"></v-text-field>

              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.saturday" label="Saturday" type="datetime-local"></v-text-field>

              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.afterparty_loc" label="Afterparty Location"></v-text-field>

              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.afterparty_address" label="Afterparty Address"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="year.afterparty_time" label="Afterparty Time"
                  type="datetime-local"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="isSubmitting" color="primary" @click="onSubmit">
              Save
            </v-btn>
            <v-btn color="red" dark @click="openDeleteModal">
              Delete
            </v-btn>

          </v-card-actions>
        </v-form>
        <!-- Confirmation Modal -->
        <v-dialog v-model="showDeleteModal" max-width="500">
          <v-card>
            <v-card-title class="headline">Delete Confirmation</v-card-title>
            <v-card-text>Are you sure you want to delete this year?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" @click="closeDeleteModal">No</v-btn>
              <v-btn color="red" dark @click="deleteYearAndCloseModal">Yes, Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getYear, updateYear, deleteYear } from '@/controllers';
import { router } from "@/router";

const isFormValid = ref(false);
const isSubmitting = ref(false);

// Function to format date to YYYY-MM-DD
const formatDate = (dateStr) => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const formattedDate = date.getFullYear() + '-' +
  String(date.getMonth() + 1).padStart(2, '0') + '-' +
  String(date.getDate()).padStart(2, '0');
  return(formattedDate);
};

// Function to format date to YYYY-MM-DDTHH:MM
const formatDateTime = (dateStr) => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const formattedDate = date.getFullYear() + '-' +
  String(date.getMonth() + 1).padStart(2, '0') + '-' +
  String(date.getDate()).padStart(2, '0') + 'T' + 
  String(date.getHours()).padStart(2,'0') + ':' +
  String(date.getMinutes()).padStart(2,'0');
  
  return formattedDate; 
};

const route = useRoute();
const yearId = route.params.id;

const year = ref({});
const fetchYear = async () => {
  try {
    const yearData = await getYear(yearId);
    // Set the year object with properly formatted dates
    year.value = {
      ...yearData,
      start_date: formatDate(yearData.start_date),
      end_date: formatDate(yearData.end_date),
      launch_date: formatDateTime(yearData.launch_date),
      turn_start: formatDateTime(yearData.turn_start),
      turn_deadline: formatDateTime(yearData.turn_deadline),
      friday_block1: formatDateTime(yearData.friday_block1),
      friday_block2: formatDateTime(yearData.friday_block2),
      saturday: formatDateTime(yearData.saturday),
      afterparty_time: formatDateTime(yearData.afterparty_time),
      early_bird_reg: formatDate(yearData.early_bird_reg),
    };
  } catch (error) {
    console.error('Error fetching year:', error);
  }
};

onMounted(() => {
  fetchYear();
});

const showDeleteModal = ref(false);

// Methods to manage modal state
const openDeleteModal = () => {
  showDeleteModal.value = true;
};

const closeDeleteModal = () => {
  showDeleteModal.value = false;
};

const deleteYearAndCloseModal = async () => {
  try {
    await deleteYear(yearId);
    closeDeleteModal();
    router.push({ name: 'AdminYearListing' }); // Redirect to years list after deletion
  } catch (error) {
    console.error('Error deleting year:', error);
  }
};
const isValidDate = (dateStr) => {
  const date = new Date(dateStr);
  return !isNaN(date.getTime()); // Check if the date is valid
};


const onSubmit = async () => {
  const earlyBirdDate = new Date(year.value.early_bird_reg);
  const adjustedEarlyDate = new Date(earlyBirdDate.getTime() + earlyBirdDate.getTimezoneOffset() * 60000)
  .toISOString();
  
  const startDate = new Date(year.value.start_date);
  const adjustedStartDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000)
  .toISOString();

  const endDate = new Date(year.value.end_date);
  const adjustedEndDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000)
  .toISOString();
  try {
    const updatedYear = {
      ...year.value,
      start_date: isValidDate(year.value.start_date) ? adjustedStartDate : null,
      end_date: isValidDate(year.value.end_date) ? adjustedEndDate : null,
      launch_date: isValidDate(year.value.launch_date) ? new Date(year.value.launch_date).toISOString() : null,
      turn_start: isValidDate(year.value.turn_start) ? new Date(year.value.turn_start).toISOString() : null,
      turn_deadline: isValidDate(year.value.turn_deadline) ? new Date(year.value.turn_deadline).toISOString() : null,
      friday_block1: isValidDate(year.value.friday_block1) ? new Date(year.value.friday_block1).toISOString() : null,
      friday_block2: isValidDate(year.value.friday_block2) ? new Date(year.value.friday_block2).toISOString() : null,
      saturday: isValidDate(year.value.saturday) ? new Date(year.value.saturday).toISOString() : null,
      afterparty_time: isValidDate(year.value.afterparty_time) ? new Date(year.value.afterparty_time).toISOString() : null,
      early_bird_reg: isValidDate(year.value.early_bird_reg) ? adjustedEarlyDate : null,
    };

    await updateYear(yearId, updatedYear);
    router.push({ name: 'AdminYearListing' });
  } catch (error) {
    console.error('Error updating year:', error);
  }
};

</script>

<style scoped></style>