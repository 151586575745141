
import { useAuthStore } from '@/store';
import axios from 'axios';
import jwt from 'jsonwebtoken';


export const validateTokenAPI = async() => {
    return new Promise((resolve, reject) => {
        const auth = useAuthStore();
        try {
            const decoded = jwt.decode(auth.token);
            
            // Check if token has expired
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
              reject( false);
            }
        
            // Optionally, verify the token's signature if needed
            // jwt.verify(token, SECRET_KEY);
        
            resolve(true)
          } catch (error) {
            console.error('Invalid token:', error);
            reject( false);
          }

    });

}
export const refreshTokenAPI = async () => {
    const auth =useAuthStore();
    const body = {refreshToken: auth.refresh, token: auth.token};
    return new Promise((resolve, reject) => {
        axios.defaults.baseURL = process.env.VUE_APP_TEAMS_API_URL;
        axios.post("refreshtoken", body).then(response => {
            // Check if response status is 200
            auth.setUserToken(response.data.accessToken);
            auth.setRefreshToken(response.data.refreshToken);
            if (response && response.status === 200) {
                resolve(response.data);
            } else {
                // If it's not 200, handle the error
                reject(response);
            }
        })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    reject(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    reject(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    reject(error);
                }

            });
    });
};
