<template>
    <v-card>
        <v-container fluid>
            <v-row dense>
                <v-col>
                    <v-card title="Registration Info" variant="tonal" >
                        <template v-slot:append>
                            <v-icon @click="isRegistrationEdit = !isRegistrationEdit" icon="mdi-pencil"></v-icon>
                        </template>
                        <v-card-text>
                            <v-row>
                                <v-col v-if="isRegistrationEdit">
                                    <v-form ref="form">
                                        <v-text-field v-model="regData.registered_date" label="Registered Date"
                                            type="date"></v-text-field>
                                        <v-text-field v-model="regData.order_number" label="Order Number"
                                            type="text"></v-text-field>
                                        <v-text-field v-model="regData.sku" label="Sku"
                                            type="text"></v-text-field>
                                        <v-checkbox v-model="regData.is_inperson" label="Is Person Team"></v-checkbox>
                                    </v-form>
                                </v-col>
                                <v-col v-if="!isRegistrationEdit">
                                    <v-card-title class="text-h5">
                                        Registered On:
                                    </v-card-title>
                                    <v-card-subtitle>
                                        {{ regData.registered_display_date }}
                                    </v-card-subtitle>
                                    <v-card-subtitle v-if="regData.order_number">
                                        Order Id: <a
                                        :href="`https://72fest.com/wp-admin/post.php?post=${regData.order_number}&action=edit`">{{
                                                regData.order_number }}</a>
                                    </v-card-subtitle>
                                    <v-card-subtitle v-if="regData.sku">
                                        SKU: 
                                        {{regData.sku }}
                                    </v-card-subtitle>
                                    <v-card-subtitle v-if="!regData.is_inperson">
                                        Remote Team
                                    </v-card-subtitle>
                                    <v-card-subtitle v-if="regData.is_inperson">
                                        In Person Team
                                    </v-card-subtitle>
                                </v-col>

                                <!-- Right Icon Section -->
                                <v-col cols="auto"  v-if="!isRegistrationEdit" class="d-flex flex-column align-center justify-center">
                                    <v-avatar size="125">
                                        
                                        <v-icon icon="mdi-alpha-x-circle" color="red" v-if="!isCheckedIn"
                                            size="80"></v-icon>
                                        <v-icon icon="mdi-check-circle" color="green" v-if="isCheckedIn"
                                            size="80"></v-icon>
                                    </v-avatar>
                                    <v-btn :disabled="isCheckedIn" @click="checkInTeam" text class="mt-2">
                                        Check In
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions v-if="isRegistrationEdit">
                            <v-btn :loading="isSubmitting" color="primary" @click="onSubmit">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card title="Terms and Conditions" variant="tonal" >
                        <template v-slot:append>
                            <v-icon @click="isTermsEdit = !isTermsEdit" icon="mdi-pencil"></v-icon>
                        </template>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-card-title v-if="regData.terms && !isTermsEdit" class="text-h5">
                                        {{ regData.terms.caption }}
                                    </v-card-title>
                                    <v-card-subtitle v-if="regData.terms && !isTermsEdit">
                                        Uploaded On: {{ regData.terms.created_on }}
                                    </v-card-subtitle>
                                    <v-card-text>TODO: Add indicator if under 18</v-card-text>
                                    <v-form v-if="!regData.terms || isTermsEdit">
                                        <v-file-input label="Choose a file" v-model="file"></v-file-input>
                                        <v-btn @click="uploadFile" :disabled="!file">Upload</v-btn>
                                        <v-alert v-if="uploadStatus" :type="uploadStatus.type" dismissible>
                                            {{ uploadStatus.message }}
                                        </v-alert>
                                    </v-form>
                                </v-col>
                                <!-- Right Icon Section -->
                                <v-col cols="auto" class="d-flex flex-column align-center justify-center">
                                    <v-avatar v-if="regData.terms && !isTermsEdit" size="125">
                                        <v-icon :icon="regData.terms.icon" size="80"></v-icon>
                                    </v-avatar>
                                    <a :href="regData.terms.url" download>
                                        <v-btn v-if="regData.terms && !isTermsEdit" text class="mt-2">
                                            Download
                                        </v-btn>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { getTeam, uploadTerms, teamCheckIn,updateTeamRegistration } from '@/controllers';
import { formatDate, formatDateTime} from '@/helpers/dateTools'
const route = useRoute();
const teamId = route.params.teamId;
const yearId = route.params.yearId;
const team = ref({});
const form = ref(null);
const isSubmitting = ref(false);
const regData = ref({
    registered_date: null,
    registered_display_date: null,
    order_number: null,
    sku: null,
    checkedIn: false,
    is_inperson: false,
    terms: {
        caption: null,
        filename: null,
        id: null,
        url: null,
        created_on: null,
        icon: null
    }
})
const file = ref(null);
const uploadStatus = ref(null);
const isTermsEdit = ref(false);
const isRegistrationEdit = ref(false);
const isCheckedIn = ref(false);


const uploadFile = async () => {
    if (!file.value) {
        uploadStatus.value = {
            type: 'error',
            message: 'No file selected',
        };
        return;
    }

    try {
        const results = await uploadTerms(teamId, yearId, file.value);
        await updateAssetModel(results);
        uploadStatus.value = {
            type: 'success',
            message: 'Upload successful!',
        };
        setTimeout(() => {
            isTermsEdit.value = false;
        }, 1000);

    } catch (error) {
        uploadStatus.value = {
            type: 'error',
            message: 'Upload failed: ' + error.message,
        };
        console.error(error);
    }
};

function lookupFileIcon(ext) {
    if (ext == 'doc' || ext == 'docx') {
        return 'word'
    }
    return ext;
}

const updateAssetModel = async (asset) => {
    regData.value.terms.caption = asset.caption;
    regData.value.terms.icon = `mdi mdi-file-${lookupFileIcon(asset.ext)}-box`;
    regData.value.terms.filename = asset.filename;
    regData.value.terms.id = asset.id;
    regData.value.terms.url = asset.url;
    regData.value.terms.created_on = formatDateTime(asset.created_on);
}

const checkInTeam = async () => {
    try {
        await teamCheckIn(teamId, yearId);
        isCheckedIn.value = true;
    } catch (error) {
        console.error('Error fetching team:', error);
    }
}

const fetchTeam = async () => {
    try {
        const teamData = await getTeam(teamId, yearId);
        team.value = teamData;
        if (teamData && teamData.teamCriteria && teamData.teamCriteria.length > 0) {
            regData.value.registered_date = formatDate(teamData.teamCriteria[0].registered_date);
            regData.value.registered_display_date = formatDateTime(teamData.teamCriteria[0].registered_date)
            regData.value.checkedIn = teamData.teamCriteria[0].checked_in;
            regData.value.is_inperson = teamData.teamCriteria[0].is_inperson;
            regData.value.order_number = teamData.teamCriteria[0].order_number;
            regData.value.sku = teamData.teamCriteria[0].sku;
            if (regData.value.checkedIn) {
                isCheckedIn.value = true;
            }
            if (teamData.teamCriteria[0].terms) {
                await updateAssetModel(teamData.teamCriteria[0].terms);
            }
        }
    } catch (error) {
        console.error('Error fetching team:', error);
    }
};
const onSubmit = async () => {
    const isValid = await form.value.validate();
    if (!isValid) {
        return;
    }
    isSubmitting.value = true;
    try {
        
       await updateTeamRegistration(teamId, yearId, regData.value);
       isRegistrationEdit.value = false;
    } catch (error) {
        console.error('Error updating team:', error);
    } finally {
        isSubmitting.value = false;
    }
};



onMounted(() => {
    fetchTeam();
});
</script>