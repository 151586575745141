<template>
  <v-container>
    <v-btn color="primary" outlined :to="{ name: 'AdminYearCreate' }" router>
      Create Year
    </v-btn>
    <v-divider class="my-4"></v-divider>
    <!-- Data Table -->
    <v-data-table-server :items="years" v-model:items-per-page="limit" :headers="headers" :items-length="count"
      :loading="loading" item-key="id" @update:options="handleLimitChange">
   
      <template v-slot:[`item.action`]="{ item }"> 
        <v-btn :to="{ name: 'AdminYearEdit', params: { id: item.id } }" router small color="primary">
          Edit
        </v-btn>
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getYears } from '@/controllers';

const years = ref([]);
const headers = [
  { title: 'Year', key: 'year' },
  { title: 'Theme', key: 'theme' },
  { title: 'Dates', key: 'dates' },
  { title: 'Edit', key: 'action' },
];
const count = ref(0);
const limit = ref(10); // Initial limit
const currentPage = ref(1); // Initial page
const search = ref("");
const loading = ref(true);

const fetchYears = async () => {
  try {
    loading.value = true;
    const yearData = await getYears({ limit: limit.value, page: currentPage.value, search: search.value });
    years.value = yearData.data.map(year => ({
      ...year,
      dates: `${year.start_date} - ${year.end_date}`
    }));
    count.value = yearData.count;
  } catch (error) {
    console.error('Error fetching years:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchYears();
});

const handleLimitChange = (value) => {
  limit.value = value.itemsPerPage;
  currentPage.value = value.page;
  fetchYears(); // Fetch new data when limit changes
};
</script>

<style scoped></style>
