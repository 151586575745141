<template>
    <v-container>
        <v-card title="Check In">
            <v-card-text>
                <v-data-table-server :items="teams" v-model:items-per-page="pageOptions.limit" :headers="headersTeam"
                    :items-length="teamCount" :loading="loadingTeam" :search="pageOptions.search" item-key="id"
                    @update:options="handleTeamLimitChange">
                    <template v-slot:[`item.name`]="{ item }">
                        <router-link :to="{ name: 'AdminYearTeamDetailView', params: { yearId, teamId: item.id } }">{{
                            item.name }}</router-link>
                        <v-badge v-if="item.teamCriteria[0] && item.teamCriteria[0].notes.length > 0" color="green"
                            :content="item.teamCriteria[0] && item.teamCriteria[0].notes.length" inline
                            @click="showNotes(item)"></v-badge>
                    </template>
                    <template v-slot:[`item.terms`]="{ item }">
                        <div v-if="!item.teamCriteria[0].terms">
                            <div v-if="!isMobile">Fill Out Terms</div>
                            <div v-else><v-icon icon="mdi-alert-circle-outline"></v-icon></div>
                        </div>
                    </template>
                    <template v-slot:[`item.inperson`]="{ item }">
                        <v-switch v-model="item.teamCriteria[0].is_inperson" :label="!isMobile?'In Person':''"
                            @change="toggleRemote(item)" class="mt-2" color="primary" />
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn :disabled="item.teamCriteria[0].checked_in == 1" @click="checkInTeam(item)" text
                            >
                            <div v-if="!isMobile">Check In</div>
                            <v-icon v-else icon="mdi-check-bold"></v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:top>
                        <v-text-field v-model="pageOptions.search" class="ma-2" density="compact"
                            placeholder="Search team..." hide-details></v-text-field>
                    </template>
                </v-data-table-server>
            </v-card-text>
        </v-card>
        <v-dialog v-model="showNotesModal">
            <v-list v-for="note in currentNotes" :key="note.id">
                <v-list-item>
                    <v-list-item-title>{{ note.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ note.content }}</v-list-item-subtitle>
                </v-list-item>
            </v-list>
        </v-dialog>
    </v-container>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getTeamsForYearWithCheckin, teamCheckIn, setTeamRemoteStatus } from '@/controllers';
import { useAuthStore } from '@/store';
import { useDisplay } from 'vuetify';
import { sendCheckinToDiscord } from '@/controllers/admin/discordController';
const emit = defineEmits(['updateTab']);
const { smAndDown } = useDisplay();
let isMobile = computed(() => smAndDown.value);
const authStore = useAuthStore();
const yearId = authStore.year;
const teams = ref([]);
const loadingTeam = ref(true);
const teamCount = ref(0);
const pageOptions = ref({
    sortBy: null,
    page: 1,
    limit: 10,
    search: '',
    exclude: null,
});
const showNotesModal = ref(false);
const currentNotes = ref([]);

const headersTeam = [
    { title: 'Name', key: 'name', align: 'start', sortable: true },
    { title: 'Terms', key: 'terms', align: 'start', sortable: false },
    { title: 'In Person', key: 'inperson', align: 'start', sortable: false },
    { title: '', key: 'action', sortable: false }
];

const fetchTeams = async () => {
    try {
        loadingTeam.value = true;
        const teamsData = await getTeamsForYearWithCheckin({ yearId: yearId, pageOptions: pageOptions.value });
        teams.value = teamsData.data;
        teamCount.value = teamsData.count;
    } catch (error) {
        console.error('Error fetching teams:', error);
    } finally {
        loadingTeam.value = false;
    }
};

const handleTeamLimitChange = (value) => {
    pageOptions.value.limit = value.itemsPerPage;
    pageOptions.value.page = value.page;
    pageOptions.value.sortBy = value.sortBy;
    pageOptions.value.search = value.search;
    fetchTeams(); // Fetch new data when limit changes
};

const checkInTeam = async (item) => {
    try {
        await teamCheckIn(item.id, yearId);
        const teamName = item.name; //get team name
        await sendCheckinToDiscord(teamName); //send message
        await fetchTeams();
    } catch (error) {
        console.error('Error checking in team or sending message to Discord:', error);
    }
};

const toggleRemote = async (item) => {

    const isPerson = item.teamCriteria[0].is_inperson; // Toggle value


    try {
        await setTeamRemoteStatus(item.id, yearId, isPerson);
        await fetchTeams();
    } catch (error) {
        console.error('Error fetching team:', error);
    }
};

const showNotes = async (item) => {
    showNotesModal.value = true;
    currentNotes.value = item.teamCriteria[0].notes;
};

onMounted(() => {
    emit('updateTab', 'teams');
});
</script>

<style scoped></style>
