<template>
  <v-container>

    <v-card>
      <v-card-title>My Profile</v-card-title>
      <v-form ref="form" v-slot="{ errors }">
        <v-card-text>
          <v-img v-if="user.avatar && !isAdding" :src="user.avatar.url" width="300"></v-img>
          <v-btn v-if="!user.avatar || !isAdding" @click="toggleAvatarUpload">Change Avatar</v-btn>
          <v-row v-if="!user.avatar || isAdding">
            <v-col>
              <v-card>
                <v-form>
                  <v-file-input label="Choose a file" v-model="file" accept="image/*"></v-file-input>
                  <v-btn @click="uploadAvatar" :disabled="!file">Upload</v-btn>
                  <v-progress-linear v-if="uploadAvatarProgress > 0" v-model="uploadAvatarProgress" height="10"
                    color="blue"></v-progress-linear>
                  <v-alert v-if="uploadAvatarStatus" :type="uploadAvatarStatus.type" dismissible>
                    {{ uploadAvatarStatus.message }}
                  </v-alert>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
          <v-text-field v-model="user.first_name" label="First Name" :rules="[rules.required]"
            :error-messages="errors.first_name" outlined></v-text-field>

          <v-text-field v-model="user.last_name" label="Last Name" :rules="[rules.required]"
            :error-messages="errors.last_name" outlined></v-text-field>

          <v-text-field v-model="user.phone_number" label="Phone Number" :rules="[rules.required]"
            :error-messages="errors.phone_number" outlined></v-text-field>

          <v-text-field label="Street Address" v-model="user.street_address" outlined
            :error-messages="errors.street_address"></v-text-field>

          <v-text-field label="City" v-model="user.city" outlined :error-messages="errors.city"></v-text-field>

          <v-text-field label="State" v-model="user.state" outlined :error-messages="errors.state"></v-text-field>

          <v-text-field label="Postal Code" v-model="user.postal_code" outlined></v-text-field>

          <v-text-field label="Country" v-model="user.country" outlined :error-messages="errors.country"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="onSubmit" :loading="isSubmitting">
            Save
          </v-btn>

          <v-btn color="orange" @click="openResetPasswordModal">Reset Password</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>


    <!-- Reset Password Modal -->
    <v-dialog v-model="showResetPasswordModal">
      <v-card>
        <v-card-title>Reset Password</v-card-title>
        <v-card-text>
          <v-form ref="resetPasswordForm">
            <v-text-field v-model="resetPasswordFormData.password" label="Password" type="password"
              :rules="[rules.required, rules.password]" outlined></v-text-field>

            <v-text-field v-model="resetPasswordFormData.confirmPassword" label="Confirm Password" type="password"
              :rules="[rules.required, matchPassword]" outlined></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="closeResetPasswordModal">Cancel</v-btn>
          <v-btn color="orange" @click="resetPassword">Save New Password</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue';
import { getMe, updateMyProfile, resetMyPassword, saveAvatar } from '@/controllers';

const form = ref(null);
const emits = defineEmits(['updatedProfile']);
// Reactive references
const user = ref({});
const showResetPasswordModal = ref(false);
const isSubmitting = ref(false);
const isAdding = ref(false);
const file = ref(null);
const uploadAvatarStatus = ref(null);
const uploadAvatarProgress = ref(0);
const uploadAvatarCompleted = ref(false);


const toggleAvatarUpload = async () => {
  file.value = null;
  uploadAvatarStatus.value = null;
  uploadAvatarProgress.value = 0;
  uploadAvatarCompleted.value = false;
  isAdding.value = !isAdding.value;

}

const uploadAvatar = async () => {
  if (!file.value) {
    uploadAvatarStatus.value = {
      type: 'error',
      message: 'No file selected',
    };
    return;
  }

  try {

    await saveAvatar(file.value);
    await fetchUser();
    uploadAvatarStatus.value = {
      type: 'success',
      message: 'Upload successful!',
    };
    setTimeout(() => {
      isAdding.value = false;
    }, 1000);

  } catch (error) {
    uploadAvatarStatus.value = {
      type: 'error',
      message: 'Upload failed: ' + error.message,
    };
    console.error(error);
  }
};


const resetPasswordForm = ref({
  password: '',
  confirmPassword: ''
});
const resetPasswordFormData = ref({});

// Validation rules
const rules = {
  required: value => !!value || 'Required.',
  email: value => /.+@.+\..+/.test(value) || 'Invalid email.',
  password: value => value.length >= 6 || 'Password must be at least 6 characters.',
};

const matchPassword = () => {
  return resetPasswordForm.value.password === resetPasswordForm.value.confirmPassword || 'Passwords must match';
};

// Fetch user data and roles
const fetchUser = async () => {
  try {
    const userData = await getMe();
    user.value = userData;
    emits('updatedProfile', true);
  } catch (error) {
    console.error('Error fetching user:', error);
  }
};

// Form submission handler
const onSubmit = async () => {
  if (await form.value.validate()) {
    try {
      isSubmitting.value = true;
      await updateMyProfile(user.value);
      await fetchUser();
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      isSubmitting.value = false;
    }
  }
};

// Modal handlers

const openResetPasswordModal = () => {
  resetPasswordFormData.value.password = '';
  resetPasswordFormData.value.confirmPassword = '';
  showResetPasswordModal.value = true;
};
const closeResetPasswordModal = () => (showResetPasswordModal.value = false);

const resetPassword = async () => {
  if (await resetPasswordForm.value.validate()) {
    try {
      await resetMyPassword(resetPasswordFormData.value.password);
      closeResetPasswordModal();
    } catch (error) {
      console.error('Error updating password:', error);
    }
  }
};

// Fetch user and roles on component mount
onMounted(() => {
  fetchUser();
});
</script>

<style scoped>
/* Scoped styles if needed */
</style>