<template>
    <v-container>
        <v-list v-if="!isMobile">
            <template v-for="(group, index) in groupedTeams" :key="index">
                
                    <v-list-subheader>Group {{ index+1 }} ({{ group.type }})</v-list-subheader>
                    <v-list-item v-for="team in group.data" :key="team.id"> <!-- Removed teamIndex -->

                        <v-list-item-title>{{ team.name }}</v-list-item-title>


                    </v-list-item>
                
                <v-divider v-if="index < groupedTeams.length - 1"></v-divider> <!-- Add a divider between groups -->
            </template>
        </v-list>
        <v-progress-circular v-if="loadingTeam" indeterminate></v-progress-circular> <!-- Loading indicator -->
    </v-container>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import { getTeamsForYearWithCheckin } from '@/controllers';
import { useAuthStore } from '@/store';
import { useDisplay } from 'vuetify';

const pageOptions = ref({
    sortBy: null,
    page: 1,
    limit: -1,
    search: '',
    exclude: null,
});

const { smAndDown } = useDisplay();
let isMobile = computed(() => smAndDown.value);
const authStore = useAuthStore();
const yearId = authStore.year;
const teams = ref([]);
const loadingTeam = ref(true);

const fetchTeams = async () => {
    try {
        loadingTeam.value = true;
        const teamsData = await getTeamsForYearWithCheckin({ yearId: yearId, pageOptions: pageOptions.value });
        teams.value = teamsData.data;
    } catch (error) {
        console.error('Error fetching teams:', error);
    } finally {
        loadingTeam.value = false;
    }
};

const groupedTeams = computed(() => {
    const sortedTeams = [...teams.value].sort((a, b) => new Date(a.checked_in_date) - new Date(b.checked_in_date));

    const groups = [];
    let holdingInPerson = []
    let holdingRemote = [];
    let currentInPersonGroup = [];
    let currentRemoteGroup = [];
    let currentNotCheckedInGroup = [];
    
    for (let i = 0; i < sortedTeams.length; i++) {
        if (currentInPersonGroup.length < 5) {
            
            if (sortedTeams[i].teamCriteria[0].is_inperson === true && sortedTeams[i].teamCriteria[0].checked_in == 1) {
                currentInPersonGroup.push(sortedTeams[i]);
            } 
        }
        if (currentRemoteGroup.length < 5) {
            
            if (sortedTeams[i].teamCriteria[0].is_inperson === false && sortedTeams[i].teamCriteria[0].checked_in == 1 ) {
                currentRemoteGroup.push(sortedTeams[i]);
            } 
        }

        if (sortedTeams[i].teamCriteria[0].is_inperson === false && sortedTeams[i].teamCriteria[0].checked_in == 0 ) {
            currentNotCheckedInGroup.push(sortedTeams[i]);
        } 


        // If the current group has 5 teams or we've reached the end of the array
        if (currentInPersonGroup.length === 5 || i === sortedTeams.length - 1) {
            holdingInPerson.push({type:"In Person", data: currentInPersonGroup});
            currentInPersonGroup = []; // Reset the current group
        } else if (currentRemoteGroup.length === 5 || i === sortedTeams.length - 1) {
            holdingRemote.push({type:"Remote", data: currentRemoteGroup});
            currentRemoteGroup = []; // Reset the current group
        } 
    }
    if (currentInPersonGroup.length > 1) {
        holdingInPerson.push({type:"In Person", data: currentInPersonGroup});
    }
    if (currentRemoteGroup.length > 1) {
        holdingRemote.push({type:"Remote", data: currentRemoteGroup});
    }

    while (holdingInPerson.length > 0 || holdingRemote.length > 0) {
        // Add two in-person groups if available
        if (holdingInPerson.length > 0) {
            groups.push(holdingInPerson.shift());
        }
        if (holdingInPerson.length > 0) {
            groups.push(holdingInPerson.shift());
        }

        // Add one remote group if available
        if (holdingRemote.length > 0) {
            groups.push(holdingRemote.shift());
        }
    }


    groups.push({type:"Not Checked In", data: currentNotCheckedInGroup})
    return groups;
});

onMounted(() => {
    fetchTeams();
});
</script>
