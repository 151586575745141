<template>
  <v-card>
    <!-- <v-breadcrumbs>
      <v-breadcrumbs-item :to="{ name: 'Admin', params: {  tabName: 'teams' } }">Back</v-breadcrumbs-item>
    </v-breadcrumbs>-->
    <v-card-item>
      <v-card-title>
        {{team.name}}
      </v-card-title>
      </v-card-item>
      <v-card-text>
    <v-row>
      <v-col cols="2">
        <v-sheet rounded="lg">
            <v-list-item 
              v-for="(item, index) in listItems" 
              :key="index" 
              @click="selectTab(item.name)"
              :class="{ 'active': currentTab === item.name }"
              >
              {{ item.label }}
              <v-badge v-if="item.name == 'notes' && teamNoteCount > 0"
          color="error"
          :content="teamNoteCount"
          inline
        ></v-badge>
            </v-list-item>
            
        </v-sheet>
      </v-col>

      <v-col>
        <v-sheet min-height="70vh" rounded="lg">
          <component :is="currentComponent"></component>
        </v-sheet>
      </v-col>
    </v-row>
    </v-card-text>

  </v-card>
</template>

<script setup>
import { ref, computed,onMounted , watch} from 'vue';
import InfoComponent from '@/components/admin/teams/InfoComponent.vue';
import RegistrationComponent from '@/components/admin/teams/RegistrationComponent.vue';
import SubmissionComponent from '@/components/admin/teams/SubmissionComponent.vue';
import AwardsComponent from '@/components/admin/teams/AwardsComponent.vue';
import ScreeningCopyComponent from '@/components/admin/teams/ScreeningCopyComponent.vue';
import NotesComponent from '@/components/admin/teams/NotesComponent.vue';
import { useRoute, useRouter } from 'vue-router';
import { getTeam, getNotesForTeamYearCount} from '@/controllers';

const route = useRoute();
const router = useRouter();
const teamId = route.params.teamId;
const yearId = route.params.yearId;
const team = ref({});
const teamNoteCount = ref(0);

const listItems = [
  { name: 'info', label: 'Info', component: InfoComponent },
  { name: 'registration', label: 'Registration', component: RegistrationComponent },
  { name: 'submission', label: 'Submission', component: SubmissionComponent },
  { name: 'screeningCopy', label: 'Screening Copy', component: ScreeningCopyComponent},
  { name: 'awards', label: 'Awards/Prizes', component: AwardsComponent },
  { name: 'notes', label: 'Notes', component: NotesComponent},
];

const currentTab = ref(route.params.tabName ||'info');

// Compute the current component to be displayed
let currentComponent = computed(() => {
  const item = listItems.find(i => i.name === currentTab.value);
  return item ? item.component : null;
});

// Function to change the selected tab
function selectTab(tabName) {
  currentTab.value = tabName;
}

const fetchTeam = async () => {
  try {
    const teamData = await getTeam(teamId);
    team.value = teamData;
    const noteResponse = await getNotesForTeamYearCount(teamId, yearId);
    teamNoteCount.value = noteResponse.count;
  } catch (error) {
    console.error('Error fetching team:', error);
  }
};

watch(
  currentTab, 
  (newTab) => {
    router.push({ name: 'AdminYearTeamDetailView', params: { yearId: yearId, teamId: teamId, tabName: newTab } });
  },
  { immediate: true }
);

onMounted(() => {
  fetchTeam();
});

</script>
