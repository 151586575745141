<template>
  <v-container>
    <v-breadcrumbs>
      <v-breadcrumbs-item @click="router.go(-1);">Back</v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-container>
      <v-card>
        <v-form ref="form">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="team.name"
                  label="Team Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="team.website"
                  label="Website"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="team.instagram"
                  label="Instagram"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="team.facebook"
                  label="Facebook"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="team.twitter"
                  label="Twitter"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="team.bio"
                  label="Bio"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="team.vimeo"
                  label="Vimeo"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="team.youtube"
                  label="YouTube"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="isSubmitting"
              color="primary"
              @click="onSubmit"
            >
              Save
            </v-btn>
            <v-btn color="red" dark @click="openDeleteModal">
              Delete
            </v-btn>
            <v-alert v-if="errors.apiError" type="error" class="mt-3 mb-0">
              {{ errors.apiError }}
            </v-alert>
          </v-card-actions>
        </v-form>

        <!-- Confirmation Modal -->
        <v-dialog v-model="showDeleteModal" max-width="500">
          <v-card>
            <v-card-title class="headline">Delete Confirmation</v-card-title>
            <v-card-text>Are you sure you want to delete this team?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" @click="closeDeleteModal">No</v-btn>
              <v-btn color="red" dark @click="deleteTeamAndCloseModal">Yes, Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getTeam, updateTeam, deleteTeam } from '@/controllers';
import { router } from "@/router";

const route = useRoute();
const teamId = route.params.id;

const team = ref({});
const isSubmitting = ref(false);
const errors = ref({});
const form = ref(null);

const nameRules = [
  v => !!v || 'Team name is required',
];

const fetchTeam = async () => {
  try {
    const teamData = await getTeam(teamId);
    team.value = teamData;
  } catch (error) {
    console.error('Error fetching team:', error);
  }
};

onMounted(() => {
  fetchTeam();
});

const showDeleteModal = ref(false);

const openDeleteModal = () => {
  showDeleteModal.value = true;
};

const closeDeleteModal = () => {
  showDeleteModal.value = false;
};

const deleteTeamAndCloseModal = async () => {
  try {
    await deleteTeam(teamId);
    closeDeleteModal();
    router.go(-1);
  } catch (error) {
    console.error('Error deleting team:', error);
    errors.value.apiError = 'Failed to delete the team. Please try again.';
  }
};

const onSubmit = async () => {
  const isValid = await form.value.validate();
  
  if (!isValid) {
    return;
  }

  isSubmitting.value = true;
  errors.value.apiError = null;
  try {
    await updateTeam(teamId, team.value);
    router.go(-1);
  } catch (error) {
    console.error('Error updating team:', error);
    errors.value.apiError = 'Failed to update the team. Please try again.';
  } finally {
    isSubmitting.value = false;
  }
};
</script>
<!-- 
TODOs

* Reformat Page so it looks nicer
* change Bio to text field with markdown
* add validation for social pages
* add avatar 

-->