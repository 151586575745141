<template>
    <v-container>
        <v-data-table-server :items="contests" v-model:items-per-page="limit" :headers="headers" :items-length="count"
            :loading="loading" :search="search" item-key="id" @update:options="handleLimitChange">

            <template v-slot:[`item.action`]="{ item }">

                <v-btn @click="openModal('viewVideo', item)" :disabled="item.video.is_processing == 1" router small color="primary">
                    Edit
                </v-btn>
            </template>
            <template v-slot:tfoot>
                <v-text-field v-model="search" class="ma-2" density="compact" placeholder="Search team..."
                    hide-details></v-text-field>
            </template>
        </v-data-table-server>
        <v-dialog v-model="currentVideo" transition="dialog-bottom-transition" fullscreen v-if="isViewVideoModalOpen">
            <v-card>
                <v-toolbar>
                    <v-btn icon="mdi-close" @click="isViewVideoModalOpen = false"></v-btn>

                    <v-toolbar-title>{{currentVideo.teamCriteria.team.name}}</v-toolbar-title>

                    <v-spacer></v-spacer>                    
                </v-toolbar>

                <v-list lines="two" subheader>
                    <v-list-subheader>Video</v-list-subheader>

                    <vue3-video-player :src="currentVideo.video.url"></vue3-video-player>
                        <a :href="currentVideo.video.url" :download="currentVideo.video.filename">
                            <v-btn text class="mt-2">
                                Download
                            </v-btn>
                        </a>

                    <v-divider></v-divider>

                    <v-list-subheader>Release</v-list-subheader>

                    <div> ADD IN Releases </div>
                </v-list>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { getAllContests } from '@/controllers';
import { useAuthStore } from '@/store';

const contests = ref([]);
const count = ref(0);
const limit = ref(10); // Initial limit
const currentPage = ref(1); // Initial page
const search = ref("");
const loading = ref(true);
const currentVideo = ref({});
const isViewVideoModalOpen = ref(false);


const headers = [
    { title: 'Name', key: 'teamCriteria.team.name', align: 'start', sortable: false },
    { title: 'File Name', key: 'video.filename', align: 'start', sortable: false },
    { title: 'Actions', key: 'action', sortable: false }
];
const authStore = useAuthStore();
const yearId = authStore.year;

const openModal = (type, item = {}) => {
   if (type === 'viewVideo') {
        currentVideo.value = item;
        isViewVideoModalOpen.value = true;
    }
};


const fetchContests = async () => {
    try {
        loading.value = true;
        const contestData = await getAllContests({ yearId: yearId, limit: limit.value, page: currentPage.value, search: search.value });
        contests.value = contestData.data;
        count.value = contestData.count;
    } catch (error) {
        console.error('Error fetching contests:', error);
    } finally {
        loading.value = false;
    }
};
const handleLimitChange = (value) => {
    limit.value = value.itemsPerPage;
    currentPage.value = value.page;
    search.value = value.search;
    fetchContests(); // Fetch new data when limit changes
};

onMounted(() => {
   
});
</script>