import UserListingView from '@/views/admin/users/UserListingView.vue';
import UserEditView from '@/views/admin/users/UserEditView.vue';
import UserCreateView from '@/views/admin/users/UserCreateView.vue';

export const adminUserRoutes = [
{
  path: "/admin/users",
  name: "AdminUserListing",
  component: UserListingView,
  meta: {guest: false}
},
{
  path: "/admin/user/:id/edit",
  name: "AdminEditDetail",
  component: UserEditView,
  meta: {guest: false}
},
{
  path: "/admin/user/create",
  name: "AdminUserCreate",
  component: UserCreateView,
  meta: {guest: false}
}]

