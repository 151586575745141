<template>
    <v-container>
        <v-form @submit="assignCrit" v-model="isFormValid">
            <v-autocomplete 
                v-model="teamLookup.selected" 
                :items="teamLookup.data" 
                item-value="id" 
                label="Teams"
                no-filter 
                hide-selected 
                clearable 
                item-title="name" 
                :search-input="search"
                @update:search="search"  
                @update:model-value="onTeamSelected">
            </v-autocomplete>

            <v-divider></v-divider>

            <v-expansion-panels v-model="openedPanels">
                <v-expansion-panel v-for="(group, index) in criterias" :key="index">
                    <v-expansion-panel-title :color="group.color">
                        {{ group.group_name }}
                        <v-badge v-if="getSelectedCriteriaCount(group.group_name) > 0" color="primary" :content="getSelectedCriteriaCount(group.group_name)" class="ml-3"></v-badge>
                    </v-expansion-panel-title>

                    <v-expansion-panel-text>
                        <v-list>
                            <v-list-item v-for="(criteria, idx) in group.criteria" :key="idx">
                                <v-row>
                                    <v-col>
                                <v-list-item-action start>
                                    <v-checkbox-btn 
                                        :model-value="isCriteriaSelected(criteria.id)"
                                        @click="toggleSelection(criteria, getIsMandatory(criteria.id))" 
                                    />
                                </v-list-item-action>
                            </v-col><v-col>
                                <v-list-item-media>
                                    <v-avatar size="125" rounded="0" style="border-width:0">
                                        <v-img :src="criteria.thubmnail.url"></v-img>
                                    </v-avatar>
                                </v-list-item-media>
                            </v-col><v-col>
                                <v-list-item-title>{{ criteria.name }}</v-list-item-title>
                            </v-col><v-col>
                                <v-list-item-action end>
                                    <v-checkbox 
                                        label="Mandatory" 
                                        :model-value="getIsMandatory(criteria.id)" 
                                        @click="toggleMandatory(criteria.id)" 
                                        :disabled="!isCriteriaSelected(criteria.id)"
                                    />
                                </v-list-item-action>
                           
                                </v-col>
                            </v-row>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-divider></v-divider>

            <v-btn color="secondary" @click="clearSelections">Clear</v-btn>
            <v-btn color="primary" @click="assignCrit" :loading="isSubmitting" :disabled="!isFormValid">Save</v-btn>
        </v-form>
    </v-container>
</template>

<script setup>
import { ref, onMounted, computed} from 'vue';
import { getTeamsForYear, getCriteriaForYear, getCriteriaGroupsForYear, getTeamsCriteria, assignTeamsCriteria } from '@/controllers';
import { useAuthStore } from '@/store';
import { sendMessageToDiscord } from '@/controllers/admin/discordController';

const authStore = useAuthStore();
const yearId = authStore.year;
const loadingTeam = ref(true);
const loadingCriteria = ref(true);
const loadingCriteriaGroup = ref(true);
const criteriasGroups = ref([]);
const criterias = ref([]);
const isSubmitting = ref(false);
const selectedCriteria = ref([]);
const openedPanels = ref([]); 
let isFormValid = computed(() => {
    return teamLookup.value.selected && teamLookup.value.selected.length > 0;
});

function getSelectedCriteriaCount(groupName) {
    return selectedCriteria.value.filter(selection => selection.criteria.group && selection.criteria.group.group_name === groupName).length;
}
const teamLookup = ref({
    selected: [],
    data: [],
    loading: false,
    limit: 0,
    page: 1,
    search: '',
});

const clearSelections = async () => {
    selectedCriteria.value = []; 
    teamLookup.value.selected = [];
    criterias.value = [];
    openedPanels.value =[];
};



const assignCrit = async () => {
    if (!isFormValid.value) {
        console.log("Form is invalid");
        return;
    }

    const criteriaWithMandatory = selectedCriteria.value.map(selection => ({
        criteriaId: selection.criteria.id,
        mandatory: selection.mandatory
    }));

    try {
        // Find the selected team by matching the selected ID
        await assignTeamsCriteria(yearId, teamLookup.value.selected, criteriaWithMandatory);
        const selectedTeamId = teamLookup.value.selected; //used for team name
        const selectedTeam = teamLookup.value.data.find(team => team.id === selectedTeamId); 

        if (!selectedTeam) {
            console.error('Selected team not found');
            return;
        }

        const teamName = selectedTeam.name; // Extract the team name

        // Call the client-side controller to send the message directly to Discord
        await sendMessageToDiscord(selectedCriteria.value.map(c => c.criteria.name), teamName);

        clearSelections();
    } catch (error) {
        console.error('Error saving criteria:', error);
        console.error('Error saving criteria or sending message:', error);
    }
};

const toggleSelection = (criteria) => {
    const index = selectedCriteria.value.findIndex(selected => selected.criteria.id === criteria.id);
    if (index > -1) {
        selectedCriteria.value.splice(index, 1); 
    } else {
        
        selectedCriteria.value.push({criteria: criteria, mandatory:true});
    }
};

const toggleMandatory = (criteriaId) => {
    const index = selectedCriteria.value.findIndex(selected => selected.criteria.id === criteriaId);
    if (index > -1) {
        selectedCriteria.value[index].mandatory = !selectedCriteria.value[index].mandatory; // Toggle the value
    }
};

const getIsMandatory = (criteriaId) => {
    const criteria = selectedCriteria.value.find(selected => selected.criteria.id === criteriaId);
 
    return criteria && criteria.mandatory != undefined ? criteria.mandatory : true;
};

const isCriteriaSelected = (criteriaId) => {
    return selectedCriteria.value.some(selected => selected.criteria.id === criteriaId);
};

const onTeamSelected = async (selectedValue) =>{
        
    const results = await getTeamsCriteria(yearId, selectedValue);
    selectedCriteria.value = results? results: [];
    fetchCriterias();
};

const fetchTeams = async () => {
    try {
        loadingTeam.value = true;
        const teamsData = await getTeamsForYear({ yearId: yearId, pageOptions: teamLookup.value });
        teamLookup.value.data = teamsData.data || [];
    } catch (error) {
        console.error('Error fetching teams:', error);
    } finally {
        loadingTeam.value = false;
    }
};

const fetchCriterias = async () => {
    try {
        loadingCriteria.value = true;
        const response = await getCriteriaForYear(yearId);
        criterias.value = criteriasGroups.value.map(group => {
            return {
                group_name: group.group_name,
                color: group.color.toLowerCase(),
                criteria: response.filter(criteria => criteria.group.group_name === group.group_name)
            };
        });
    } catch (error) {
        console.error('Error fetching criterias:', error);
    } finally {
        loadingCriteria.value = false;
    }
};

const fetchCriteriaGroups = async () => {
    try {
        loadingCriteriaGroup.value = true;
        const response = await getCriteriaGroupsForYear(yearId);
        criteriasGroups.value = response;
    } catch (error) {
        console.error('Error fetching criterias groups:', error);
    } finally {
        loadingCriteriaGroup.value = false;
    }
};

const search = async (value) => {
    teamLookup.value.search = value;
    await fetchTeams();
};

onMounted(() => {
    fetchTeams();
    fetchCriteriaGroups();
});
</script>
