import { get, post, remove, generatePagination } from '@/services/apiWrapper';

export const getAllYears = async (body) => {
    const encodedSearchTerm =body.search? encodeURIComponent(body.search.trim()): '';
    const years = await get(`/admin/years?page=${body.page}&limit=${body.limit}&q=${encodedSearchTerm}`);
    return years;
}

export const getCurrentYearAPI = async () => {
    const year = await get(`/getCurrentYear`);
    return year; 
}

export const getYearById = async (id) => {
    const year = await get(`/admin/years/${id}`);
    return year;
}

export const getYearInfoAPI = async (id) => {
    const year = await get(`/admin/years/${id}/info`);
    return year;
}

export const saveYear = async (id, body) => {
    const year = await post(`/admin/years/${id}/save`, body);
    return year;
}

export const createYearAPI = async (body) => {
    const year = await post(`/admin/years/createYear`, body);
    return year;
}

export const deleteYearAPI = async (id) => {
    const year = await remove(`/admin/years/${id}`);
    return year;
}

export const getTeamsForYearAPI = async (body) => {
    const pageOptions = await generatePagination(body.pageOptions);
    const teams = await get(`/admin/years/${body.yearId}/teams${pageOptions}`)
    return teams;
}

export const getTeamsForYearWithCheckinAPI = async (body) => {
    const pageOptions = await generatePagination(body.pageOptions);
    const teams = await get(`/admin/years/${body.yearId}/teams/withReg${pageOptions}`)
    return teams;
}

export const getTeamsForYearWithSubmissionsAPI = async (body) => {
    const pageOptions = await generatePagination(body.pageOptions);
    const teams = await get(`/admin/years/${body.yearId}/teams/withSubmissions${pageOptions}`)
    return teams;
}

export const getTeamsTermsForYearAPI  = async (yearId) => {
    const terms = await get(`/admin/years/${yearId}/teams/terms`)
    return terms;
}


export const getCriteriaForYearAPI = async (id) => {
    const critera = await get(`/admin/years/${id}/criteria`);
    return critera;
}

export const getCriteriaGroupsForYearAPI = async (id) => {
    const criteraGroups = await get(`/admin/years/${id}/criteriagroups`);
    return criteraGroups;
}

export const getTeamsCriteriaAPI = async (yearId, teamId) => {
    const critera = await get(`/admin/years/${yearId}/teams/${teamId}/criteria`);    
    return critera;
}

export const assignTeamsCriteriaAPI = async (yearId, teamId, selectedCriteriaIds) => {
    const results = await post(`/admin/years/${yearId}/teams/${teamId}/criteria`,selectedCriteriaIds );
    return results;
}

export const createCriteriaAPI = async (id, formData) => {
    const criteria = await post(`/admin/years/${id}/createCriteria`, formData);
    return criteria;
}

export const createCriteriaGroupAPI = async (id, data) => {
    const criteriaGroup = await post(`/admin/years/${id}/createCriteriaGroup`, data);
    return criteriaGroup;
}

export const updateCriteriaAPI = async (id, criteriaId, data) => {
    const criteria = await post(`/admin/years/${id}/criteria/${criteriaId}/save`, data);
    return criteria;
}


export const deleteCriteriaAPI = async (id, criteriaID) => {
    const criteria = await remove(`/admin/years/${id}/criteria/${criteriaID}`);
    return criteria;
}

export const addTeamsToYearAPI = async (id, data) => {
    const teams = await post(`/admin/years/${id}/addTeams`, data);
    return teams;
}

export const getTeamYearAPI = async (yearId, teamId) => {
    const teams = await get(`/admin/years/${yearId}/team/${teamId}`);
    return teams;
}
export const removeTeamFromYearAPI = async (id, teamId) => {
    const teams = await remove(`/admin/years/${id}/teams/${teamId}`);
    return teams;
}

export const addTeamMembersToYearAPI = async (teamId, yearId, data) => {
    const teams = await post(`/admin/years/${yearId}/team/${teamId}/members`, data);
    return teams;
}
export const getNextCriteriaNumberAPI = async (yearId) => {
    const teams = await get(`admin/years/${yearId}/nextCriteriaNumber`);
    return teams;
}