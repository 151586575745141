<template>
  <v-container>
    <v-breadcrumbs>
      <v-breadcrumbs-item :to="{ name: 'AdminYearListing' }">Back</v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-container>
      <v-card>
        <v-card-title>Create Year</v-card-title>
        <v-form v-model="isFormValid" @submit.prevent="onSubmit">

          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="form.year" label="Year" type="number"
                :rules="[
                  v => !!v || 'Year is required'
                ]"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.start_date" label="Start Date" type="date"
                :rules="[
                  v => !!v || 'Start Date is required'
                ]"
                   required></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.end_date" label="End Date" type="date"
                :rules="[
                  v => !!v || 'End Date is required'
                ]"
                  required></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.early_bird_reg" label="Early Bird Date" type="datetime-local"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.launch_location" label="Launch Location" ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.launch_address" label="Launch Address"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.launch_date" label="Launch Date" type="datetime-local"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.turn_start" label="Turn-In Start Date" type="datetime-local"
                   ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.turn_deadline" label="Turn-In Deadline" type="datetime-local"
                   ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.theme" label="Theme" ></v-text-field>

              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.friday_block1" label="Friday Block 1" type="datetime-local"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.friday_block2" label="Friday Block 2" type="datetime-local"
                  ></v-text-field>

              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.saturday" label="Saturday" type="datetime-local"
                  ></v-text-field>

              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.afterparty_loc" label="Afterparty Location"
                   ></v-text-field>

              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.afterparty_address" label="Afterparty Address"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="form.afterparty_time" label="Afterparty Time" type="datetime-local"
                  ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :loading="isSubmitting"
              :disabled="!isFormValid"
              @click="onSubmit"
            >
              Save
            </v-btn>

            <v-alert v-if="errors.apiError" type="error" class="mt-3">{{ errors.apiError }}</v-alert>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
  </v-container>
</template>
<script setup>
import { ref } from 'vue';
import { createYear } from '@/controllers'; // Replace with your backend controller function
import { useRouter } from 'vue-router';

const router = useRouter();
const isFormValid = ref(false);
const isSubmitting = ref(false);

const form = ref({
  year: '',
  start_date: '',
  end_date: '',
  launch_location: '',
  launch_address: '',
  launch_date: '',
  turn_start: '',
  turn_deadline: '',
  theme: '',
  friday_block1: '',
  friday_block2: '',
  saturday: '',
  afterparty_loc: '',
  afterparty_address: '',
  afterparty_time: '',
  early_bird_reg: '',
});

const errors = ref({
  year: [],
  start_date: [],
  end_date: [],
  launch_location: [],
  launch_address: [],
  launch_date: [],
  turn_start: [],
  turn_deadline: [],
  theme: [],
  friday_block1: [],
  friday_block2: [],
  saturday: [],
  afterparty_loc: [],
  afterparty_address: [],
  afterparty_time: [],
  early_bird_reg: [],
  apiError: null,
});


const onSubmit = async () => {

  isSubmitting.value = true;
    try {
      const response = await createYear(form.value);
      if (response && response.id) {
        router.push({ name: 'AdminYearListing' });
      }
    } catch (error) {
      errors.value.apiError = error.message;
    } finally {
  
  isSubmitting.value = false;
    }
};
</script>
<style scoped></style>
