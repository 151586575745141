import { uploadTermsAPI, uploadMovieAPI, uploadTeamLogoAPI, uploadContestMovieAPI, deleteContestAPI, saveAvatarAPI,
    uploadContestReleaseAPI, deleteReleaseFromContestAPI, uploadSubmissionReleaseAPI, uploadTeamVideoAPI,
    uploadScreenShotAPI, deleteReleaseFromSubmissionAPI, uploadScreeningCopyAPI} from "@/services";

export async function uploadTerms(teamId, yearId, file) {

    try {
        const formData = new FormData();
        formData.append('file', file);
        const terms = await uploadTermsAPI(teamId, yearId,  formData);
        return terms;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function uploadScreenShot(teamId, yearId, submissionId, file) {

    try {
        const formData = new FormData();
        formData.append('file', file);
        const terms = await uploadScreenShotAPI(teamId, yearId, submissionId, formData);
        return terms;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function uploadMovie(teamId, yearId, submissionId, file, onProgress) {

    try {
        const movie = await uploadMovieAPI(teamId, yearId, submissionId,  file, onProgress) ;
        return movie;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function uploadTeamVideo(teamId, yearId, submissionId, file, onProgress) {

    try {
        const movie = await uploadTeamVideoAPI(teamId, yearId, submissionId,  file, onProgress) ;
        return movie;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function uploadScreeningCopy(teamId, yearId, submissionId, file, onProgress) {

    try {
        const movie = await uploadScreeningCopyAPI(teamId, yearId, submissionId,  file, onProgress) ;
        return movie;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function uploadTeamLogo(teamId, file) {

    try {
        const formData = new FormData();
        formData.append('file', file);
        const terms = await uploadTeamLogoAPI(teamId,  formData);
        return terms;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function uploadContestMovie(teamId, yearId, submissionId, file, onProgress) {

    try {
        const movie = await uploadContestMovieAPI(teamId, yearId, submissionId,  file, onProgress) ;
        return movie;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function deleteContest(contestId) {

    try {
        const movie = await deleteContestAPI(contestId) ;
        return movie;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function saveAvatar(file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const terms = await saveAvatarAPI(formData);
        return terms;
    } catch (error) {
        console.log(error);
        throw ("Can't save avatar")
    }
}

export async function  uploadContestRelease(contestId, typeId, file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('typeId', typeId);
        await uploadContestReleaseAPI(contestId, formData);
        
    } catch (error) {
        console.log(error);
        throw ("Can't save release")
    }  
}

export async function  uploadSubmissionRelease(submissionId, typeId, file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('typeId', typeId);
        await uploadSubmissionReleaseAPI(submissionId, formData);
        
    } catch (error) {
        console.log(error);
        throw ("Can't save release")
    }  
}

export async function deleteReleaseFromContest(contestId,releaseId ) {
    try {
        await deleteReleaseFromContestAPI(contestId,releaseId) ;
       
    } catch (error) {
        console.log(error);
        throw ("Can't find delete release")
    }
}

export async function deleteReleaseFromSubmission(submissionId,releaseId ) {
    try {
        await deleteReleaseFromSubmissionAPI(submissionId,releaseId) ;
       
    } catch (error) {
        console.log(error);
        throw ("Can't find delete release")
    }
}