<template>
         <v-card class="info_card" title="Screening Copy -  Due by Tues 10.01 9pm EST ">
                            <template v-slot:append>
                                <v-icon v-if="submissionData.screeningCopy && submissionData.screeningCopy.id"
                                    @click="togglescreeningCopyUpload" icon="mdi-pencil"></v-icon>
                            </template>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-card-title
                                            v-if="submissionData.screeningCopy && submissionData.screeningCopy.id && !isScreeningCopyEdit"
                                            class="text-h5 font-weight-bold px-0">
                                            {{ submissionData.screeningCopy.caption }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            v-if="submissionData.screeningCopy && submissionData.screeningCopy.id && !isScreeningCopyEdit"
                                            class="px-0">
                                            Uploaded On: {{ submissionData.screeningCopy.created_on }}
                                        </v-card-subtitle>
                                        <div class="pt-5 pb-4" v-if="(!submissionData.screeningCopy || !submissionData.screeningCopy.id) || isScreeningCopyEdit">
                                            <p> Only the original films received on time will be viewed by the judges. They must adhere to the maximum time limit rules however feel free to fix sound/color and other changes etc. NAME the tile TEAM-TITLE-SCREENINGCOPY.
                                            </p>
                                            <p>NOTE:  Screening/Late Copies are ineligible for Audience Choice. </p>
                                            <p>FILMS MUST BE IN AN ACCEPTABLE FORMAT. Add 1 frame of black before and
                                                after your film.
                                                Accepted Format for screening is H.264 or H.265 (1080p Files preferred,
                                                4K accepted).
                                                Bitrate of 45Mbs max. Sound: Stereo AAC</p>

                                            <p>Once the film is uploaded, it will appear on this page and will be
                                                streamable once it
                                                processes. If the film is on this page, we have your entry!</p>
                                        </div>
                                        <v-form v-if=" (!submissionData.screeningCopy || isScreeningCopyEdit)">
                                            <v-file-input label="Choose a file" v-model="file"
                                                accept="video/*"></v-file-input>
                                            <v-btn variant="elevated" color="primary" @click="uploadscreeningCopyFile"
                                                :disabled="!file">Upload</v-btn>
                                            <v-progress-linear v-if="uploadscreeningCopyProgress > 0"
                                                v-model="uploadscreeningCopyProgress" height="10"
                                                color="blue"></v-progress-linear>
                                            <v-alert v-if="uploadscreeningCopyStatus" :type="uploadscreeningCopyStatus.type"
                                                dismissible>
                                                {{ uploadscreeningCopyStatus.message }}
                                            </v-alert>
                                        </v-form>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        v-if="submissionData.screeningCopy && submissionData.screeningCopy.id && submissionData.screeningCopy.is_processing && !isScreeningCopyEdit"
                                        cols="auto" class="d-flex flex-column align-center justify-center">
                                        <v-progress-circular indeterminate></v-progress-circular>
                                        Don't worry we have your file. We are currently processing it and it will be
                                        available shortly.
                                    </v-col>
                                    <v-col
                                        v-if="submissionData.screeningCopy && submissionData.screeningCopy.id && !submissionData.screeningCopy.is_processing && !isScreeningCopyEdit"
                                        cols="12" class="video_player d-flex flex-column align-center justify-center">
                                        <vue3-video-player :src="submissionData.screeningCopy.url"></vue3-video-player>
                                        <a :href="submissionData.screeningCopy.url" download>
                                            <v-btn variant="elevated" color="primary" text class="mt-2">
                                                Download
                                            </v-btn>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { uploadScreeningCopy } from '@/controllers';
import {
    getMySubmission, getMyTeam
} from '@/controllers';
import { useAuthStore } from '@/store';
const team = ref({});
const authStore = useAuthStore();

const submissionData = ref({
    id: null,
    director: null,
    genre: null,
    createdMusic: null,
    logline: null,
    rating: null,
    request_notes: false,
    running_time: null,
    title: null,
    use_of_criteria: null,
    containsAdult: null,
    screeningCopy: {
        caption: '',
        filename: '',
        id: null,
        url: null,
        created_on: null,
        is_processing: null,
    },
    trailer: {
        caption: '',
        filename: '',
        id: null,
        url: null,
        created_on: null,
        is_processing: null,
    },
    screenshot: {
        caption: '',
        filename: '',
        id: null,
        url: null,
        created_on: null,
        is_processing: null,
    }
})

const isScreeningCopyEdit = ref(false);
const file = ref(null);
const uploadscreeningCopyStatus = ref(null);
const uploadscreeningCopyProgress = ref(0);
const uploadscreeningCopyCompleted = ref(false);

const uploadscreeningCopyFile = async () => {
    if (!file.value) {
        uploadscreeningCopyStatus.value = {
            type: 'error',
            message: 'No file selected',
        };
        return;
    }
    uploadscreeningCopyStatus.value = null;
    uploadscreeningCopyCompleted.value = false;
    uploadscreeningCopyProgress.value = 0;


    try {

        await uploadScreeningCopy(team.value.id, authStore.year, submissionData.value.id, file.value, (progress) => {

            if (progress >= 100) {
                uploadscreeningCopyProgress.value = null;
                uploadscreeningCopyCompleted.value = true;
            } else {
                uploadscreeningCopyProgress.value = progress;
            }
        });
        fetchTeam();

        uploadscreeningCopyStatus.value = {
            type: 'success',
            message: 'Upload successful!',
        };
        setTimeout(() => {
            isScreeningCopyEdit.value = false;
        }, 1000);

    } catch (error) {
        uploadscreeningCopyStatus.value = {
            type: 'error',
            message: 'Upload failed: You will need to refresh (possibly log out and back in to clear your cache) and try again.  A BackUp Google Drive method is available in the Discord.',
        };
        console.error(error);
    }
};

const togglescreeningCopyUpload = async () => {
    file.value = null;
    uploadscreeningCopyStatus.value = null;
    uploadscreeningCopyProgress.value = 0;
    uploadscreeningCopyCompleted.value = false;
    isScreeningCopyEdit.value = !isScreeningCopyEdit.value;
}

const fetchTeam = async () => {
    try {
        const teamData = await getMyTeam(authStore.year);
        team.value = teamData;
        const submissionObj = await getMySubmission(authStore.year);
        submissionData.value = submissionObj;
        submissionData.value.request_notes = submissionObj.request_notes ? true : false;
     
    } catch (error) {
        console.error('Error fetching team:', error);
    }
};


onMounted(() => {
    fetchTeam();
});
</script>