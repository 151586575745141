import { getAllUsers, getUserById, getAllRoles, saveUser, createUserAPI, checkEmail, deleteUserAPI, resetUserPassword} from "@/services";

export async function getUsers(limit, page, search) {
    try {
        const users = await getAllUsers(limit, page, search);
        return users;
    } catch (error) {
        console.log(error);
        throw ("Cant't get users")
    }
}

export async function getUser(id) {
    try {
        const users = await getUserById(id);
        return users;
    } catch (error) {
        console.log(error);
        throw ("Can't find User")
    }
}

export async function getRoles(id) {
    try {
        const users = await getAllRoles(id);
        return users;
    } catch (error) {
        console.log(error);
        throw ("Can't get Roles")
    }
}

export async function updateUser(id, data){
    try {
        const response = await saveUser(id, data);
        return response;
    } catch (error) {
        console.log(error);
}
}

export async function createUser(id, data){
    try {
        const response = await createUserAPI(id, data);
        return response;
    } catch (error) {
        console.log(error);
}
}

export async function isEmailValid(email, userid){
    try {
        const body = {email: email, userid: userid};
        const response = await checkEmail(body);
        return response;
    } catch (error) {

        console.log(error);
        return false;
     
}
}

export async function resetUsersPassword(userid, password){
    try {
        const response = await resetUserPassword(userid, password);
        return response;
    } catch (error) {

        console.log(error);
        return false;
     
}
}

export async function deleteUser(userid){
    try {
        const response = await deleteUserAPI(userid);
        return response;
    } catch (error) {

        console.log(error);
        return false;
     
}
}
