import { getAllTeams, getTeamById, saveTeam, createTeamAPI, deleteTeamAPI, getTeamMembersAPI, 
    getPossibleUsersAPI, addTeamMembersToYearAPI, getTeamLeadId, updateTeamLeadAPI, 
    getTeamLevelsAPI, changeTeamLevelAPI, teamCheckInAPI, updateTeamRegistrationAPI, removeTeamMemberFromYearAPI, setTeamRemoteStatusAPI, getSubmissionAPI } from "@/services";

export async function getTeams(limit, page, search, exclude) {
    try {
        const teams = await getAllTeams(limit, page, search, exclude);
        return teams;
    } catch (error) {
        console.log(error);
        throw ("Cant't get teams")
    }
}

export async function getTeam(id, yearId) {
    try {
        const teams = await getTeamById(id, yearId);
        return teams;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team")
    }
}

export async function getSubmission(id, yearId) {
    try {
        const teams = await getSubmissionAPI(id, yearId);
        return teams;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team")
    }
}


export async function updateTeam(id, data) {
    try {
        const response = await saveTeam(id, data);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function createTeam(id, data) {
    try {
        const response = await createTeamAPI(id, data);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function deleteTeam(teamid) {
    try {
        const response = await deleteTeamAPI(teamid);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function getTeamMembers(body) {
    try {
        const response = await getTeamMembersAPI(body);
        return response;
    } catch (error) {

        console.log(error);
        return false;

    }
}

export async function getPossibleUsers(body){
    try {
        const response = await getPossibleUsersAPI(body);
        return response;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function addTeamMembersToYear(teamId, yearId, users) {
    try {
        const ids = [];
        users.forEach(obj => ids.push(obj));
        const response = await addTeamMembersToYearAPI(teamId, yearId,  {id: ids});
        return response;
    } catch (error) {
        console.log(error);
        return false;
    }
}
export async function getTeamLead(teamId, yearId) {
    try {
        const leader = await getTeamLeadId(teamId, yearId);
        return leader;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Leader")
    }
}
export async function updateTeamLead(teamId, yearId, userId) {
    try {
        const leader = await updateTeamLeadAPI(teamId, {yearId: yearId, userId: userId});
        return leader;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Leader")
    }
}
export async function getTeamLevels() {

    try {
        const levels = await getTeamLevelsAPI();
        return levels;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}
export async function changeTeamLevel(yearId, teamId, value) {

    try {
        const levels = await changeTeamLevelAPI(yearId, teamId, value);
        return levels;
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function teamCheckIn(teamId, yearId) {
    try {
        await teamCheckInAPI(teamId, yearId);
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function setTeamRemoteStatus(teamId, yearId, isPerson) {
    try {
        await setTeamRemoteStatusAPI(teamId, yearId, isPerson);
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}

export async function updateTeamRegistration(teamId, yearId, data) {
    try {
        const registration = {
            registered_date: data.registered_date,
            order_number: data.order_number,
            is_inperson: data.is_inperson,
            sku: data.sku,
        }
        await updateTeamRegistrationAPI(teamId, yearId, registration);
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}
export async function removeTeamMemberFromYear(body) {
    try {
      
        await removeTeamMemberFromYearAPI(body);
    } catch (error) {
        console.log(error);
        throw ("Can't find Team Level")
    }
}