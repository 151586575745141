<template>
    <v-card>
        <v-card-text>
            <v-data-table-server :items="teams" v-model:items-per-page="pageOptions.limit" :headers="headersTeam"
                :items-length="teamCount" :loading="loadingTeam" :search="pageOptions.search" item-key="id"
                @update:options="handleTeamLimitChange">
                <template v-slot:[`item.all`]="{ item }">
                    <div v-if="item.all.length > 0">
                            <v-img v-for="crit in item.all" :key="crit.id" :src="crit.criteria.thubmnail.url" width="50px"></v-img>
                    </div>
                    <div v-else>&nbsp;</div>
                </template>
                <template v-slot:[`item.man`]="{ item }">
                    <div v-if="item.mandatory.length > 0">
                        <div v-for="crit in item.mandatory" :key="crit.id">
                            <b>{{ crit.criteria.group.group_name }}: {{ crit.criteria.name }}</b>
                            {{ crit.use_of_criteria }}
                            <v-divider></v-divider>
                        </div>


                    </div>
                    <div v-else>&nbsp;</div>
                </template>
                <template v-slot:[`item.opt`]="{ item }">
                    <div v-if="item.optional.length > 0">
                        <div v-for="crit in item.optional" :key="crit.id">
                            <b>{{ crit.criteria.group.group_name }}: {{ crit.criteria.name }}</b>
                            {{ crit.use_of_criteria }}
                            <v-divider></v-divider>
                        </div>


                    </div>
                    <div v-else>&nbsp;</div>
                </template>

                <template v-slot:top>
                    <v-text-field v-model="pageOptions.search" class="ma-2" density="compact"
                        placeholder="Search team..." hide-details></v-text-field>

                </template>
            </v-data-table-server>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getTeamsForYearWithSubmissions } from '@/controllers';
import { useAuthStore } from '@/store';
const emit = defineEmits(['updateTab']);
const authStore = useAuthStore();
const yearId = authStore.year;
const teams = ref([]);
const loadingTeam = ref(true);
const teamCount = ref(0);
const pageOptions = ref({
    sortBy: null,
    page: 1,
    limit: 100,
    search: '',
    exclude: null,
});
const pastDeadline = ref(null);
const headersTeam = [
    { title: 'Block', key: 'teamCriteria[0].friday_block', align: 'start', sortable: false, width: '150px' },
    { title: 'Order', key: 'teamCriteria[0].friday_order', align: 'start', sortable: false, width: '150px' },
    { title: 'Name', key: 'name', align: 'start', sortable: false, width: '150px' },
    { title: "All Criteria", key: 'all', align: 'start', sortable: false, width: '150px' },
    { title: "Team Level", key: 'teamCriteria[0].teamLevel.team_level', align: 'start', sortable: false, width: '150px' },
    { title: "Title", key: 'teamCriteria[0].submissions[0].title', align: 'start', sortable: false, width: '150px' },
    { title: "Rating", key: 'teamCriteria[0].submissions[0].rating', align: 'start', sortable: false, width: '150px' },
];
const fetchTeams = async () => {
    try {
        loadingTeam.value = true;
        const teamsData = await getTeamsForYearWithSubmissions({ yearId: yearId, pageOptions: pageOptions.value });

        teamsData.data.forEach(team => {
            team.mandatory = team.teamCriteria[0].criteriaRelations.filter(criteria => criteria.mandatory == 1);
            team.optional = team.teamCriteria[0].criteriaRelations.filter(criteria => criteria.mandatory == 0);
            team.all = team.teamCriteria[0].criteriaRelations;
        });
        console.log(teamsData.data);

        teams.value = teamsData.data.sort((a, b) => {
            const blockA = a.teamCriteria[0].friday_block ? a.teamCriteria[0].friday_block : 5 ;
            const blockB = b.teamCriteria[0].friday_block ? b.teamCriteria[0].friday_block : 5 ;
            const orderA = a.teamCriteria[0].friday_order ? a.teamCriteria[0].friday_order : 5 ;
            const orderB = b.teamCriteria[0].friday_order ? b.teamCriteria[0].friday_order : 5 ;
            console.log(blockA + ":"+ blockB + ":"+  orderA + ":"+  orderB )
            // First compare friday_block
            if (blockA !== blockB) {
                return blockA - blockB;
            }

            // If friday_block is the same, compare friday_order
            return orderA - orderB;
        });
        teamCount.value = teamsData.count;

    } catch (error) {
        console.error('Error fetching teams:', error);
    } finally {
        loadingTeam.value = false;
    }
};

const handleTeamLimitChange = (value) => {
    pageOptions.value.limit = value.itemsPerPage;
    pageOptions.value.page = value.page;
    pageOptions.value.sortBy = value.sortBy;
    pageOptions.value.search = value.search;
    fetchTeams(); // Fetch new data when limit changes
};


onMounted(() => {
    emit('updateTab', 'teams');
    pastDeadline.value = authStore.getTurnin();
});

</script>