<script setup>
</script>
<script>
export default {
  name: "NavBar"
};
</script>

<template>

  <v-list-item><router-link to="/" class="nav-item nav-link">Home</router-link></v-list-item>
  
</template>


<style></style>