<template>
  <v-container>
    <v-breadcrumbs>
      <v-breadcrumbs-item @click="router.go(-1);">Back</v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-container>
      <v-card>
        <v-card-title>Edit User</v-card-title>
        <v-form ref="form" v-slot="{ errors }">
          <v-card-text>
            <v-text-field v-model="user.first_name" label="First Name" :rules="[rules.required]"
              :error-messages="errors.first_name" outlined></v-text-field>

            <v-text-field v-model="user.last_name" label="Last Name" :rules="[rules.required]"
              :error-messages="errors.last_name" outlined></v-text-field>

            <v-text-field v-model="user.email_address" label="Email Address" :rules="[rules.required, rules.email]"
              :error-messages="errors.email" outlined></v-text-field>

            <v-text-field v-model="user.phone_number" label="Phone Number" :rules="[rules.phone]"
              :error-messages="errors.phone_number" outlined></v-text-field>

            <v-checkbox v-model="user.is_under_18" label="Under 18" :error-messages="errors.is_under_18"></v-checkbox>

            <v-select v-model="selectedRole" :items="roles" item-title="role_name" item-value="id" label="Role"
              :rules="[rules.required]" outlined></v-select>


            <v-text-field label="Street Address" v-model="user.street_address" outlined
              :error-messages="errors.street_address"></v-text-field>

            <v-text-field label="City" v-model="user.city" outlined :error-messages="errors.city"></v-text-field>

            <v-text-field label="State" v-model="user.state" outlined :error-messages="errors.state"></v-text-field>

            <v-text-field label="Postal Code" v-model="user.postal_code" :rules="[
              v => /^\d{5}(-\d{4})?$/.test(v) || 'Postal Code must be valid'
            ]" outlined></v-text-field>

            <v-text-field label="Country" v-model="user.country" outlined
              :error-messages="errors.country"></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="onSubmit" :loading="isSubmitting">
              Save
            </v-btn>

            <v-btn color="red" @click="openDeleteModal">Delete</v-btn>
            <v-btn color="orange" @click="openResetPasswordModal">Reset Password</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <!-- Delete Confirmation Modal -->
      <v-dialog v-model="showDeleteModal">
        <v-card>
          <v-card-title>Delete Confirmation</v-card-title>
          <v-card-text>Are you sure you want to delete this user?</v-card-text>
          <v-card-actions>
            <v-btn text @click="closeDeleteModal">No</v-btn>
            <v-btn color="red" @click="deleteUserAndCloseModal">Yes, Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Reset Password Modal -->
      <v-dialog v-model="showResetPasswordModal">
        <v-card>
          <v-card-title>Reset Password</v-card-title>
          <v-card-text>
            <v-form ref="resetPasswordForm">
              <v-text-field v-model="resetPasswordFormData.password" label="Password" type="password"
                :rules="[rules.required, rules.password]" outlined></v-text-field>

              <v-text-field v-model="resetPasswordFormData.confirmPassword" label="Confirm Password" type="password"
                :rules="[rules.required, matchPassword]" 
                outlined></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="closeResetPasswordModal">Cancel</v-btn>
            <v-btn color="orange" @click="resetPassword">Save New Password</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getUser, getRoles, updateUser, deleteUser, resetUsersPassword } from '@/controllers';
import { router } from "@/router";

// Get userId from route params
const route = useRoute();
const userId = route.params.id;

const form = ref(null);

// Reactive references
const user = ref({});
const roles = ref([]);
const selectedRole = ref(null);
const showDeleteModal = ref(false);
const showResetPasswordModal = ref(false);
const isSubmitting = ref(false);

const resetPasswordForm = ref({
  password: '',
  confirmPassword: ''
});
const resetPasswordFormData = ref({});

// Validation rules
const rules = {
  required: value => !!value || 'Required.',
  email: value => /.+@.+\..+/.test(value) || 'Invalid email.',
  phone: value => /^\d{10}$/.test(value) || 'Invalid phone number.',
  password: value => value.length >= 6 || 'Password must be at least 6 characters.',
};

const matchPassword = () => {
  return resetPasswordForm.value.password === resetPasswordForm.value.confirmPassword || 'Passwords must match';
};

// Fetch user data and roles
const fetchUser = async () => {
  try {
    const userData = await getUser(userId);
    user.value = userData;
    selectedRole.value = user.value.role.id;
  } catch (error) {
    console.error('Error fetching user:', error);
  }
};

const fetchRoles = async () => {
  try {
    const roleData = await getRoles();   
    roles.value = roleData;
  } catch (error) {
    console.error('Error fetching roles:', error);
  }
};

// Form submission handler
const onSubmit = async () => {
  if (await form.value.validate()) {
    try {
      isSubmitting.value = true;
      await updateUser(userId, user.value);
      router.push({ name: "AdminUserListing" });
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      isSubmitting.value = false;
    }
  }
};

// Modal handlers
const openDeleteModal = () => (showDeleteModal.value = true);
const closeDeleteModal = () => (showDeleteModal.value = false);
const deleteUserAndCloseModal = async () => {
  try {
    await deleteUser(userId);
    closeDeleteModal();
    router.push({ name: "AdminUserListing" });
  } catch (error) {
    console.error('Error deleting user:', error);
  }
};

const openResetPasswordModal = () => {
  resetPasswordFormData.value.password = '';
  resetPasswordFormData.value.confirmPassword = '';
  showResetPasswordModal.value = true;
};
const closeResetPasswordModal = () => (showResetPasswordModal.value = false);

const resetPassword = async () => {
  if (await resetPasswordForm.value.validate()) {
    try {
      await resetUsersPassword(userId, resetPasswordFormData.value.password);
      closeResetPasswordModal();
    } catch (error) {
      console.error('Error updating password:', error);
    }
  }
};

// Fetch user and roles on component mount
onMounted(() => {
  fetchUser();
  fetchRoles();
});
</script>

<style scoped>
/* Scoped styles if needed */
</style>
