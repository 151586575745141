<template>
    <v-container>
        
    <v-row>
        <v-col>
            <IndicatorWidget :number="indicators.totalTeamsCount" caption="# Teams Registerd" caption_sm="Registered"></IndicatorWidget>
        </v-col>
        <v-col>
            <IndicatorWidget :number="indicators.checkedInTeamsCount" caption="# Teams Checked In" caption_sm="Here"></IndicatorWidget>
        </v-col>
        <v-col>
            <IndicatorWidget :number="indicators.teamsWithVideoSubmissionCount" caption="# Teams Turned In" caption_sm="Done"></IndicatorWidget>
        </v-col>
    </v-row>    
    <v-row>
        <v-col>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col>
          <router-link :to="{ name: 'AdminTermsCheckIn' }" class="button">

            <v-btn color="primary">Check In Teams</v-btn>
          </router-link>
        </v-col>
                    <v-col>
          <router-link :to="{ name: 'AdminAssignCriteria' }" class="button">

            <v-btn color="primary">Assign Criteria</v-btn>
          </router-link>
        </v-col>
        <v-col>
          <router-link :to="{ name: 'AdminTeamOrder' }" class="button">

            <v-btn color="primary">Criteria Picking Order</v-btn>
          </router-link>
        </v-col>
        <v-col>
          <router-link :to="{ name: 'AdminTeamSubmissions' }" class="button">

            <v-btn color="primary">Submissions</v-btn>
          </router-link>
        </v-col>

    </v-row>
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>
</v-container>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { getYearInfo } from '@/controllers';
import IndicatorWidget from '@/components/IndicatorWidget.vue';
import { useAuthStore } from '@/store';

const emit = defineEmits(['updateTab']);

const authStore = useAuthStore();
const yearId = authStore.year;
const year = ref({});
const indicators= ref({checkedInTeamsCount: 0, teamsWithVideoSubmissionCount: 0, totalTeamsCount:0 });



const fetchYear = async () => {
    try {
        const yearData = await getYearInfo(yearId);
        year.value = yearData.year;
        indicators.value.checkedInTeamsCount = yearData.checkedInTeamsCount;
        indicators.value.teamsWithVideoSubmissionCount = yearData.teamsWithVideoSubmissionCount;
        indicators.value.totalTeamsCount = yearData.totalTeamsCount;
        
    } catch (error) {
        console.error('Error fetching year:', error);
    }
};
onMounted(() => {
    fetchYear();
    emit('updateTab', 'info');

});

</script>
<!--
TODO's

* Get Breakdown of teams types
* Change Timeline to cards
-->