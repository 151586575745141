import axios from '@/services/axiosInterceptor';
import { useAuthStore } from '@/store';
export const get = (url) => {
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then(response => {
                if (response && response.status === 200) {
                    resolve(response.data);
                } else {
                    console.log(response);
                    reject(response);
                }
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error.request);
                } else {
                    reject(error);
                }
            });
    });
};

export const post = (url, body, headers, config) => {
    return new Promise((resolve, reject) => {

        axios.post(url, body, headers, config).then(response => {
            // Check if response status is 200
            if (response && response.status === 200) {
                resolve(response.data);
            } else {
                // If it's not 200, handle the error
                console.log(response);
                reject(false);
            }
        })
            .catch(function (error) {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error.request);
                } else {
                    reject(false);
                }
            });
    });
}

export const remove = (url) => {
    return new Promise((resolve, reject) => {
        axios.delete(url).then(response => {
            if (response && response.status === 200) {
                resolve(response.data);
            } else {
                // If it's not 200, handle the error
                console.log(response);
                reject(response);
            }
        })
            .catch(function (error) {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error.request);
                } else {
                    reject(error);
                }

            });
    });
}

export const generatePagination = (pageOptions) => {
    let urlQueryParms = [];
    if (pageOptions) {
        if (pageOptions.page) {
            urlQueryParms.push(`page=${pageOptions.page}`);
        }
        if (pageOptions.limit) {
            urlQueryParms.push(`limit=${pageOptions.limit}`);
        }
        if (pageOptions.search) {
            const searchString = encodeURIComponent(pageOptions.search.trim())
            urlQueryParms.push(`q=${searchString}`);
        }
        if (pageOptions.exclude) {
            const encodedExclude = encodeURIComponent(JSON.stringify(pageOptions.exclude));
            urlQueryParms.push(`exclude=${encodedExclude}`);
        }
        if (pageOptions.sortBy) {
            const encodedSortBy = encodeURIComponent(JSON.stringify(pageOptions.sortBy));
            urlQueryParms.push(`sortby=${encodedSortBy}`);
        }

        if (urlQueryParms.length > 0) {
            return "?"+urlQueryParms.join("&");
        } else {
            return "";
        }

    }
    else {
        return "";
    }
}

export const webSocketUpload = (endPoint, teamId, yearId, file, onProgress) => {
    const chunkSize = 1 * 1024 * 1024; // 5MB per chunk (adjust as needed)
    const totalChunks = Math.ceil(file.size / chunkSize);
    const timestamp = Math.floor(Date.now() / 1000);
    const user = useAuthStore();
    const url = process.env.VUE_APP_TEAMS_API_WS;
    const ws = new WebSocket(`${url}/${endPoint}?token=${user.token}`);
  
    let chunkTimeout; // Timeout reference for the chunk response


  return new Promise((resolve, reject) => {
    let currentChunk = 0;

    // On WebSocket open, begin sending chunks
    ws.onopen = () => {
      //console.log('open');
      const metadata = JSON.stringify({
        teamId: teamId,
        yearId: yearId,
        fileName: file.name,
        totalChunks: totalChunks,
        timestamp: timestamp,
        fileSize: file.size,
      });

      // First send the metadata
      ws.send(metadata);
      setTimeout(() => {
        sendNextChunk();
      }, 50);
    };

    // Handle WebSocket errors
    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
      clearTimeout(chunkTimeout); // Clear timeout on error
      reject(error);
    };

    // Receive responses from the server
    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      //console.log(response);

      if (response.status === 'chunk_received') {
        // Clear the timeout since we received a response
        clearTimeout(chunkTimeout);

        // Update progress
        const totalProgress = Math.round(((response.chunkIndex + 1) / totalChunks) * 100);
        if (onProgress) {
          //console.log(totalProgress);
          onProgress(totalProgress);  // Emit progress to the provided callback
        }

        // Send the next chunk if available
        currentChunk++;
        if (currentChunk < totalChunks) {
          sendNextChunk();
        } else {
          //console.log('closing');
          ws.close();
          resolve();
        }
      } 
    };

    // Function to send the next chunk
    const sendNextChunk = () => {
      //console.log('chunk');
      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      // Send the file chunk
      ws.send(chunk);

      // Set timeout to resend the chunk if no response in 1 second
      chunkTimeout = setTimeout(() => {
        console.warn('No response');
        reject()
      }, 10000); // 10 second timeout
    };
  });
}