<template>
<v-container>
  <v-card title="Team Info">
    <template v-slot:append>
      <v-icon @click="openModal('addTeam')" icon="mdi-account-multiple-plus">
      </v-icon>
      <v-tooltip activator="parent" location="end">Add Team to Year</v-tooltip>
    </template>

    <v-card-text>
      <v-data-table-server :items="teams" v-model:items-per-page="pageOptions.limit" :headers="headersTeam"
        :items-length="teamCount" :loading="loadingTeam" :search="pageOptions.search" item-key="id" @update:options="handleTeamLimitChange">
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="end"><v-col cols="auto">

              <router-link :to="{ name: 'AdminYearTeamDetailView', params: { yearId, teamId: item.id } }">
                <v-icon>mdi-pencil</v-icon>
                <v-tooltip activator="parent" location="end">Edit Team's Registration</v-tooltip>
              </router-link>
            </v-col><v-col cols="auto">
              <div @click="openModal('removeTeam', item)">
                <v-icon color="error">mdi-delete </v-icon>
                <v-tooltip activator="parent" location="end">Delete Team From Year</v-tooltip>
              </div>
            </v-col></v-row>
        </template>
        <template v-slot:top>
          <v-text-field
            v-model="pageOptions.search"
            class="ma-2"
            density="compact"
            placeholder="Search team..."
            hide-details
          ></v-text-field>
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
  <!-- Add Team Modal -->
  <v-dialog v-model="isAddTeamModalOpen" max-width="700px">
    <v-card>
      <v-card-title>Add Team</v-card-title>
      <v-card-text>
        <v-data-table :headers="tableAddTeamHeaders" :items="filteredTeams" :items-per-page="5" item-key="id"
          show-select :server-items-length="teamFilterCount" @update:options="fetchFilterTeams" v-model="selectedTeams">
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="isAddTeamModalOpen = false">Cancel</v-btn>
        <v-btn color="primary" @click="addTeams">Add Teams</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Remove Team Modal -->
  <v-dialog v-model="isRemoveTeamModalOpen" max-width="500px">
    <v-card>
      <v-card-title>Remove Team</v-card-title>
      <v-card-text>
        <p>Are you sure you want to remove this team?</p>
        <p><strong>{{ currentTeam.name }}</strong></p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="isRemoveTeamModalOpen = false">Cancel</v-btn>
        <v-btn color="error" @click="deleteTeam">Remove</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getTeamsForYear, getTeams, addTeamsToYear, removeTeamFromYear } from '@/controllers';
import { useAuthStore } from '@/store';
const emit = defineEmits(['updateTab']);
const authStore = useAuthStore();
const yearId = authStore.year;
const teams = ref([]);
const currentTeam = ref({});
const selectedTeams = ref([]);
const filteredTeams = ref([]);
const isAddTeamModalOpen = ref(false);
const isRemoveTeamModalOpen = ref(false);
const loadingTeam = ref(true);
const teamCount = ref(0);
const teamFilterCount = ref(0);
const searchAddTeam = ref("");
const limitAddTeam = ref(10);
const pageOptions = ref({
  sortBy: null,
  page: 1,
  limit: 10,
  search: '',
  exclude: null,
  
});

const headersTeam = [
  { title: 'Name', key: 'name', align: 'start', sortable: true },
  { title: '', key: 'action', sortable: false }
];
const tableAddTeamHeaders = [{ title: 'Name', key: 'name', align: 'start', sortable: false }];

const fetchTeams = async () => {
  try {
    loadingTeam.value = true;
    const teamsData = await getTeamsForYear({ yearId: yearId, pageOptions: pageOptions.value });
    teams.value = teamsData.data;
    teamCount.value = teamsData.count;
  } catch (error) {
    console.error('Error fetching teams:', error);
  } finally {
    loadingTeam.value = false;
  }
};

const fetchFilterTeams = async () => {
  try {
    loadingTeam.value = true;
    const teamsData = await getTeams({ limit: limitAddTeam.value, page: 1, search: searchAddTeam.value, exclude: { year: yearId } });
    filteredTeams.value = teamsData.data;
    teamFilterCount.value = teamsData.count;
  } catch (error) {
    console.error('Error fetching teams:', error);
  } finally {
    loadingTeam.value = false;
  }
};

const openModal = (type, item = {}) => {
  if (type === 'addTeam') {
    fetchFilterTeams();
    isAddTeamModalOpen.value = true;
  } else if (type === 'removeTeam') {
    currentTeam.value = item;
    isRemoveTeamModalOpen.value = true;
  }
};

const addTeams = async () => {
  try {
    await addTeamsToYear(yearId, selectedTeams.value);
    fetchTeams();
    selectedTeams.value = [];
    isAddTeamModalOpen.value = false;
  } catch (error) {
    console.error('Error adding teams:', error);
  }
};

const deleteTeam = async () => {
  try {
    await removeTeamFromYear(yearId, currentTeam.value.id);
    fetchTeams();
    isRemoveTeamModalOpen.value = false;
  } catch (error) {
    console.error('Error removing team:', error);
  }
};

const handleTeamLimitChange = (value) => {
  pageOptions.value.limit = value.itemsPerPage;
  pageOptions.value.page = value.page;
  pageOptions.value.sortBy = value.sortBy;
  
  pageOptions.value.search = value.search;
  
  fetchTeams(); // Fetch new data when limit changes
};


onMounted(() => {
    emit('updateTab', 'teams');
  });

</script>

<style scoped></style>