import { get, post, remove } from '@/services/apiWrapper';

export const getNotesForTeamYearAPI = async (teamCriteriaId) => {  
    const notes = await get(`/admin/notes/${teamCriteriaId}`);
    return notes;
}

export const getNotesForTeamYearCountAPI = async (teamId, yearId) => {  
    const notes = await get(`/admin/notes/team/${teamId}/year/${yearId}/count`);
    return notes;
}

export const createNoteAPI = async (teamCriteriaId, body) => {  
    const notes = await post(`/admin/notes/${teamCriteriaId}`, body);
    return notes;
}

export const updateNoteAPI = async (body) => {  
    const result = await post(`/admin/note/${body.id}`, body);
    return result;
}

export const deleteNoteAPI = async (noteId) => {  
    const result = await remove(`/admin/note/${noteId}`);
    return result;
}