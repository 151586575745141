import { defineStore } from 'pinia';
import { router } from "@/router";
import { createPersistedStatePlugin } from './persistStore';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: null, 
    data: null, 
    token: null, 
    refresh: null, 
    firstName: '',
    lastName: '',
    email: '',
    avatar: null,
    returnUrl: null,
    force: false,
    year: null,
    launch: false,
    turnstart: false,
    dueDate: false,
    testing: false,
    role: null // Changed to null
  }),

  actions: {
    setUserToken(newValue){
      this.token = newValue;
    },
    setRefreshToken(newValue){
      this.refresh = newValue;
    },
    setUser(newValue){
      this.user = newValue;
    },
    setData(newValue){
      this.data = newValue;
    },
    setFirstName(newValue){
      this.firstName = newValue;
    },
    setLastName(newValue){
      this.lastName = newValue;
    },
    setEmail(newValue){
      this.email = newValue;
    },
    setRole(role) {
      this.role = role;
    },
    getDisplayName(){
      return this.firstName + ' ' + this.lastName;
    },
    isAdmin() {
      return this.role === 'Admin';
    },
    setCurrentYear(year) {
      this.year = year;
    },
    setAvatarUrl(newValue){
      this.avatar = newValue;
    },
    setForceReset(value){
      this.force = value;
    },
    setLaunch(value){
      this.launch = value;
    },
    getLaunch(){

      return this.launch || this.testing
    },
    setTurnStart(value){
      this.turnstart = value;
    },
    getTurnStart() {
      return this.turnstart || this.testing
    },
    setDueDate(value){
      this.dueDate = value;
    },
    isPastTurnin() {
      const now = new Date();
      const dueDateAdjusted = new Date(this.dueDate);   
      dueDateAdjusted.setMinutes(dueDateAdjusted.getMinutes() + 30);
      return dueDateAdjusted < now;
    },
    getTurnin() {
      const dueDateAdjusted = new Date(this.dueDate);   
      dueDateAdjusted.setMinutes(dueDateAdjusted.getMinutes() + 30);
      return dueDateAdjusted;
    },
    setTesting(value){
      this.testing = value
    },
    async logout() {
      this.user = null;
      this.token = null;
      this.data = null;
      this.firstName = '';
      this.lastName = '';
      this.role = null;
      this.year = null;
      this.email = null;
      this.token = null;
      this.refresh = null;
      this.avatar = null;
      this.force = false;
      this.launch = null;
      this.turnstart = null;
      this.testing = false;
      this.dueDate = null;
      router.push({ name: "Login" });
    },
  },
  plugins: [createPersistedStatePlugin('authStore')],
});
