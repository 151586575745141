import { get, post } from '@/services/apiWrapper';


export const getMyTeamAPI = async (yearId) => {
    let param = ''
    if (yearId) {
        param = `?year=${yearId}`;
    }
    const team = await get(`/teams/getMyTeam${param}`);
    return team;
}

export const removeOptionalCriteriaAPI = async (critId) => {
    const crit =await post(`/teams/removeOptionalCritieria`, {critId: critId});
    return crit;
}

export const saveOptionalCriteriaAPI = async (yearId, critIds) => {
    let param = ''
    if (yearId) {
        param = `?year=${yearId}`;
    }
    const crit =await post(`/teams/saveOptionalCritieria${param}`, {critIds: critIds});
    return crit;
}

export const getMySubmissionAPI = async (yearId) => {
    let param = ''
    if (yearId) {
        param = `?year=${yearId}`;
    }
    const team =await get(`/teams/getMySubmission${param}`);
    return team;
}

export const updateMySubmissionAPI = async (yearId,submission, manditory, optional)  => {
    let param = ''
    if (yearId) {
        param = `?year=${yearId}`;
    }
    let data = {submission: submission, manditory: manditory, optional: optional};
    const team = await post(`/teams/updateMySubmission${param}`, data);
    return team;
}

export const updateMyTeamAPI  = async(yearId, team)=> {
    let param = ''
    if (yearId) {
        param = `?year=${yearId}`;
    }
    const updated = await post(`/teams/updateMyTeam${param}`, team);
    return updated;
}

export const getMyContestsAPI  = async(yearId)=> {
    let param = ''
    if (yearId) {
        param = `?year=${yearId}`;
    }
    const updated = await get(`/teams/contests${param}`);
    return updated;
}

export const getReleaseTypesAPI = async()=> {
    const types = await get(`/release/types`);
    return types;
}

export const getSubmissionReleasesAPI = async(submissionId) => {
    const types = await get(`/team/submission/${submissionId}/releases`);
    return types;
};

export const updateReleaseDonesAPI = async(submissionId, value)=> {
    const types = await post(`/team/submission/${submissionId}/currentReleaseDone`, {value: value});
    return types;
};

export const saveCastAndCrewAPI = async(submissionId, value)=> {
    const types = await post(`/team/submission/${submissionId}/castAndCrew`, {value: value});
    return types;
};