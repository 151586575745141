<template>
   
        <v-container fluid>
            <v-row dense>
               
                <v-col>
                    <v-card class="fill-height" title="Terms and Conditions">
                        <template v-slot:append>
                            <v-icon v-if="regData.terms.id" @click="isTermsEdit = !isTermsEdit" icon="mdi-pencil"></v-icon>
                        </template>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-card-title v-if="regData.terms.id && !isTermsEdit" class="text-h5">
                                        {{ regData.terms.caption }}
                                    </v-card-title>
                                    <v-card-subtitle v-if="regData.terms.id && !isTermsEdit">
                                        Uploaded On: {{ regData.terms.created_on }}
                                    </v-card-subtitle>
                                    <div v-if="!regData.terms.id && !isTermsEdit">
                                        Please download the <a href="https://72fest.com/wp-content/uploads/2024/08/72-Film-Fest-2024-TC.pdf">Terms and Conditions</a>
                                        and upload it here.  If you are under 18, you will need to have a parent or guardian sign as well.

                                    </div>
                                    <v-form v-if="!regData.terms.id || isTermsEdit ">
                                        <v-file-input label="Choose a file" v-model="file"></v-file-input>
                                        <v-btn variant="elevated" color="primary" @click="uploadFile" :disabled="!file">Upload</v-btn>
                                        <v-alert v-if="uploadStatus" :type="uploadStatus.type" dismissible>
                                            {{ uploadStatus.message }}
                                        </v-alert>
                                    </v-form>
                                </v-col>
                                <!-- Right Icon Section -->
                                <v-col  v-if="regData.terms.id && !isTermsEdit" cols="auto" class="d-flex flex-column align-center justify-center">
                                    <v-avatar  size="125">
                                        <v-icon :icon="regData.terms.icon" size="80"></v-icon>
                                    </v-avatar>
                                    <a :href="regData.terms.url" download>
                                        <v-btn text class="mt-2">
                                            Download
                                        </v-btn>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="launch">
                    <v-card title="Check-In Info" >
                        <v-card-text>
                            <v-row>
                               <v-col cols="auto" v-if="!isCheckedIn && !team.is_inperson">
                                Please post in the discord channel that you are here so we know you are ready to go.

                               </v-col>
                               <v-col cols="auto" v-if="!isCheckedIn && team.is_inperson">
                                Please come to the sign-in table to let us know you are here.

                               </v-col>
                            
                               <v-col cols="auto" v-if="isCheckedIn">
                                You are all ready to go

                               </v-col>
                                <!-- Right Icon Section -->
                                <v-col cols="auto" class="d-flex flex-column align-center justify-center">
                                    <v-avatar size="125">
                                        
                                        <v-icon icon="mdi-alpha-x-circle" color="red" v-if="!isCheckedIn"
                                            size="80"></v-icon>
                                        <v-icon icon="mdi-check-circle" color="green" v-if="isCheckedIn"
                                            size="80"></v-icon>
                                    </v-avatar>
                                </v-col>
                            </v-row>
                        </v-card-text>
                       
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import {  uploadTerms } from '@/controllers';
import {  formatDateTime} from '@/helpers/dateTools'
import { getMyTeam} from '@/controllers';
import { useAuthStore } from '@/store';
const team = ref({});
const authStore = useAuthStore();
const launch = ref(false);
const regData = ref({
    registered_date: null,
    registered_display_date: null,
    order_number: null,
    sku: null,
    checkedIn: false,
    is_inperson: false,
    terms: {
        caption: null,
        filename: null,
        id: null,
        url: null,
        created_on: null,
        icon: null
    }
})
const file = ref(null);
const uploadStatus = ref(null);
const isTermsEdit = ref(false);
const isCheckedIn = ref(false);


const uploadFile = async () => {
    if (!file.value) {
        uploadStatus.value = {
            type: 'error',
            message: 'No file selected',
        };
        return;
    }

    try {
        
        const results = await uploadTerms(team.value.id, authStore.year,  file.value);
        await updateAssetModel(results);
        uploadStatus.value = {
            type: 'success',
            message: 'Upload successful!',
        };
        setTimeout(() => {
            isTermsEdit.value = false;
        }, 1000);

    } catch (error) {
        uploadStatus.value = {
            type: 'error',
            message: 'Upload failed: ' + error.message,
        };
        console.error(error);
    }
};

function lookupFileIcon(ext) {
    if (ext == 'doc' || ext == 'docx') {
        return 'word'
    }
    return ext;
}

const updateAssetModel = async (asset) => {
    regData.value.terms.caption = asset.caption;
    regData.value.terms.icon = `mdi mdi-file-${lookupFileIcon(asset.ext)}-box`;
    regData.value.terms.filename = asset.filename;
    regData.value.terms.id = asset.id;
    regData.value.terms.url = asset.url;
    regData.value.terms.created_on = formatDateTime(asset.created_on);
}

const fetchTeam = async () => {
  try {
    const teamData = await getMyTeam(authStore.year);
    team.value = teamData;   
    await updateAssetModel(teamData.teamCriteria[0].terms);
  } catch (error) {
    console.error('Error fetching team:', error);
  }
};

onMounted(() => {
    fetchTeam();
    launch.value = authStore.getLaunch();
});
</script>

<!-- TODOS
* Add lockdown based on times
-->