<template>
  <v-responsive class="border rounded">
    <v-app>
      <v-app-bar flat v-if="isUser">

        <template v-slot:prepend>
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </template>
        <v-row class="d-flex align-center" v-if="!isAdmin">
          <v-col cols="auto">
        <v-img 
  :width="300"
  aspect-ratio="16/9"
  cover
  src="/images/72FilmFestFinalLogo_horz.png"
></v-img>
</v-col>
</v-row>
        <v-app-bar-title v-if="isAdmin && !isMobile">
       
          <v-row class="d-flex align-center">
            <v-col cols="auto" class="d-flex justify-start align-center">Year {{ year.year }}</v-col>
            <v-col cols="auto" style="text-align: right;">
              <router-link v-if="yearId" :to="{ name: 'AdminYearEdit', params: { id: yearId } }"><v-icon
                  icon="mdi-mdi mdi-calendar-edit">
                </v-icon>
                <v-tooltip activator="parent" location="end">Edit Year</v-tooltip>
              </router-link>
            </v-col>

            <v-col cols="auto" class="d-flex justify-center align-center" style="flex-grow: 1;">
              <v-btn :color="currentSection === 'info' ? 'black' : 'primary'"
                :class="{ 'border-b-lg': currentSection === 'info', 'text-h6': true }" variant="plain"
                :to="{ name: 'Admin' }">
                INFO
              </v-btn>
            </v-col>
            <v-col cols="auto" class="d-flex justify-center align-center" style="flex-grow: 1;">
              <v-btn :color="currentSection === 'teams' ? 'black' : 'primary'"
                :class="{ 'border-b-lg': currentSection === 'teams', 'text-h6': true }" variant="plain"
                :to="{ name: 'AdminHomeTeams' }">
                TEAMS
              </v-btn>
            </v-col>
            <v-col cols="auto" class="d-flex justify-center align-center" style="flex-grow: 1;">
              <v-btn :color="currentSection === 'criteria' ? 'black' : 'primary'"
                :class="{ 'border-b-lg': currentSection === 'criteria', 'text-h6': true }" variant="plain"
                :to="{ name: 'AdminHomeCriteria' }">
                CRITERIA
              </v-btn>
            </v-col>
            <v-col cols="auto" class="d-flex justify-center align-center" style="flex-grow: 1;">
              <v-btn :color="currentSection === 'contest' ? 'black' : 'primary'"
                :class="{ 'border-b-lg': currentSection === 'contest', 'text-h6': true }" variant="plain"
                :to="{ name: 'AdminHomeContest' }">
                Contest
              </v-btn>
            </v-col>
            <v-col cols="auto" class="d-flex justify-center align-center" style="flex-grow: 1;">
              <v-btn :color="currentSection === 'termsTurnIn' ? 'black' : 'primary'"
                :class="{ 'border-b-lg': currentSection === 'termsTurnIn', 'text-h6': true }" variant="plain"
                :to="{ name: 'AdminTermsTurnIn' }">
                Terms
              </v-btn>
            </v-col>
          </v-row>


        </v-app-bar-title>
        <v-list>
          <v-list-item :prepend-avatar="avatar" :subtitle="email" :title="displayName">
            <template v-slot:append>
              <v-btn icon="mdi-menu-down" size="small" variant="text" @click="profileMenu = !profileMenu"></v-btn>
            </template>
          </v-list-item>
        </v-list>
      </v-app-bar>

      <v-navigation-drawer v-if="isUser" temporary v-model="drawer">
        <v-list :lines="false" density="compact" nav>
          <NavBar v-if="isUser && !isAdmin" />
          <NavBarAdmin v-if="isUser && isAdmin" />
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer v-if="isUser" temporary v-model="profileMenu" location="right">
        <v-divider></v-divider>

        <v-list density="compact" nav>
          <router-link  :to="{ name: 'MyProfile' }" class="nav-item nav-link"><v-list-item prepend-icon="mdi-account"  value="account" title="My Profile"></v-list-item></router-link>
          <v-divider></v-divider>
          <v-list-item prepend-icon="mdi-logout">
            <v-list-item-title v-on:click="authStore.logout()">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>

        <router-view @updateTab="handleUpdateTab" @updatedProfile="handleUpdatedProfile"></router-view>

      </v-main>
      <v-bottom-navigation v-if="isAdmin && isMobile">
       
           <router-link v-if="yearId" :to="{ name: 'AdminYearEdit', params: { id: yearId } }"><v-icon
               icon="mdi-mdi mdi-calendar-edit">
             </v-icon>
             <v-tooltip activator="parent" location="end">Edit Year</v-tooltip>
           </router-link>
      

           <v-btn :color="currentSection === 'info' ? 'black' : 'primary'"
             :class="{ 'border-b-lg': currentSection === 'info', 'text-h6': true }" variant="plain"
             :to="{ name: 'Admin' }">
             <v-icon icon="mdi-information-outline"></v-icon>
           </v-btn>
           <v-btn :color="currentSection === 'teams' ? 'black' : 'primary'"
             :class="{ 'border-b-lg': currentSection === 'teams', 'text-h6': true }" variant="plain"
             :to="{ name: 'AdminHomeTeams' }">
             <v-icon icon="mdi-account-group-outline"></v-icon>
           </v-btn>
           <v-btn :color="currentSection === 'criteria' ? 'black' : 'primary'"
             :class="{ 'border-b-lg': currentSection === 'criteria', 'text-h6': true }" variant="plain"
             :to="{ name: 'AdminHomeCriteria' }">
             <v-icon icon="mdi-scale-balance"></v-icon>
           </v-btn>
           <v-btn :color="currentSection === 'contest' ? 'black' : 'primary'"
             :class="{ 'border-b-lg': currentSection === 'contest', 'text-h6': true }" variant="plain"
             :to="{ name: 'AdminHomeContest' }">
             <v-icon icon="mdi-seal"></v-icon>
           </v-btn>
           <v-btn :color="currentSection === 'termsTurnIn' ? 'black' : 'primary'"
             :class="{ 'border-b-lg': currentSection === 'termsTurnIn', 'text-h6': true }" variant="plain"
             :to="{ name: 'AdminTermsTurnIn' }">
             <v-icon icon="mdi-file-document-multiple-outline"></v-icon>
           </v-btn>


     </v-bottom-navigation>
    </v-app>
  </v-responsive>
</template>
<script setup>
import { RouterView } from 'vue-router';
import NavBar from '@/components/NavBar.vue';
import NavBarAdmin from '@/components/NavBarAdmin.vue';
import { useAuthStore } from '@/store';
import { getYear, getCurrentYear} from '@/controllers';
import { ref, computed, onMounted,watch } from 'vue';
import { useDisplay } from 'vuetify';

const authStore = useAuthStore();
let isAdmin = computed(() => authStore.role === 'Admin');
let isUser = computed(() => authStore.user !== null);
const { smAndDown } = useDisplay();
let isMobile = computed(() => smAndDown.value);
const drawer = ref(null);
const profileMenu = ref(null);
const avatar = ref(null);
const displayName = ref(null);
const email = ref(null);
const yearId = authStore.year;
const year = ref({});
const currentSection = ref(null);

const fetchYear = async () => {
  try {
    const yearData = await getYear(yearId);
    year.value = yearData;
  } catch (error) {
    console.error('Error fetching year:', error);
  }
};

const setProfile = async() => {
  avatar.value =  authStore.avatar? authStore.avatar: "/images/blankProfile.webp";
  displayName.value = authStore.getDisplayName()
  email.value = authStore.email;
}

function handleUpdateTab(tab) {
  currentSection.value = tab;
}
const handleUpdatedProfile = async(value) => {
  if (value) {
    await setProfile()
  }
}

onMounted(async () => {
  if (isUser.value && isAdmin.value){
    await fetchYear();    
  } else if (isUser.value && !isAdmin.value) {
    await getCurrentYear();
  }
  await setProfile();

});

watch(() => authStore.email, async () => {  
  await setProfile();
});


</script>
